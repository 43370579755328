import { NgModule } from '@angular/core';

import { AvaliacaoService } from './avaliacao.service';
import { CadastradorService } from './cadastrador.service';
import { CategoriaService } from './categoria.service';
import { IdentificadorService } from './identificador.service';
import { QuestaoService } from './questao.service';

@NgModule({
    providers: [
        AvaliacaoService,
        CategoriaService,
        QuestaoService,
        IdentificadorService,
        CadastradorService
    ],
})
export class GestorAvaliacaoModule {}
