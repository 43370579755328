<div class="dropzone dropzone-default dropzone-primary" [dropzone]="config" [disabled]="disabled" #dropzoneUpload (error)="onUploadError($event)" (success)="onUploadSuccess($event)">
    <div class="dropzone-msg dz-message">
        <div class="{{class}}" *ngIf="!link">
            <span class="far {{ classIcon }}"></span>
        </div>
        <div class="form__img" *ngIf="link">
            <img id="form__img"
                [src]="link" alt="">
        </div>
    </div>
</div>

