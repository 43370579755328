import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { hubConst } from '@app/core';
import { LocalStorageService } from '@app/guards/storage.service';
import { Swal } from '@app/utils';
import { TranslateService } from '@ngx-translate/core';
import { StatusUploadFlipBookEnum, StatusUploadFlipErroEnum } from '@shared/enums';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { interval, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'gpe-upload-element-flip',
    templateUrl: './upload-element-flip.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => UploadElementFlipComponent),
        },
    ],
})
export class UploadElementFlipComponent implements OnInit, AfterViewInit, ControlValueAccessor {
    @Input() public isInvalid: boolean = false
    @Input() public service: any
    @Input() public height: number
    public form: FormGroup
    public urlIFrameBook: SafeResourceUrl
    private _destroy$ = new Subject()
    private _stopNotify$ = new Subject()
    private _traducao = []
    private readonly _api: any = hubConst.api
    private readonly _token: string = this._localStorageService.storageUsuario.token
    public dzComponentReset: EventEmitter<any> = new EventEmitter()
    public config: DropzoneConfigInterface = {
        clickable: true,
        url: `${this._api.usuario}temp-upload/v1/file`,
        params: {
            limiteMaximo: true,
            pesoMaximo: 1024,
            stringKbMb: 'mb',
            editora_id: this._localStorageService.storageUsuario.country_selected.editoras[0].id
        },
        createImageThumbnails: false,
        maxFiles: 1,
        acceptedFiles: '.zip',
        previewsContainer: false
    }

    constructor(
        private _appComponent: AppComponent,
        private _localStorageService: LocalStorageService,
        private _swal: Swal,
        private _translate: TranslateService,
        private formBuilder: FormBuilder,
        private _cdref: ChangeDetectorRef,
        private sanitizer: DomSanitizer
    ) {
        this.initForm()
    }

    public ngAfterViewInit(): void {
        this._appComponent.country_changed.pipe(takeUntil(this._destroy$)).subscribe(() => {
            this.urlIFrameBook = null
        })
    }

    public ngOnInit() {
        this.initLanguage()
    }

    public ngOnDestroy(): void {
        this._destroy$.next()
        this._destroy$.complete()
        this._stopNotify$.complete()
    }

    public onUploadFlip(event): void {
        if (event.success) {
            this.form.controls.flip_status_renderizacao.setValue(0)
            this.form.patchValue({
                id: undefined,
                hash: event.hash,
                nome_arquivo: event.title,
                extensao: event.extension,
                file: event.file,
                flip_status_renderizacao: 0,
                size: event.size
            })
            this.onSelect(this.form.value)
            this.resetDz()
        }
    }

    private initForm() {
        this.form = this.formBuilder.group({
            id: undefined,
            conteudo_id: undefined,
            hash: undefined,
            nome_arquivo: undefined,
            extensao: undefined,
            file: undefined,
            flip_url: undefined,
            flip_status_renderizacao: undefined,
            size: undefined
            // flip: [undefined, [Validators.required]],
        })

        this.form.valueChanges.subscribe(this.onLoadFlip)
    }

    private onLoadFlip = (): void => {
        if (!!this.form.value.id) {
            if (!!this.form.value.flip_url && this.form.value.flip_status_renderizacao === 1) {
                this.urlIFrameBook = this.sanitizer.bypassSecurityTrustResourceUrl(`${hubConst.web.didaticos}open-content/${this.form.value.conteudo_id}?frame=1&t=${this._token}&ta=1`)
            } else if (!this.form.value.flip_status_renderizacao) {
                this.notifyListener()
            }
        }
    }

    public onDeleteFLip(): void {
        this.confirmAlert(this._traducao['modulos.materiais-didaticos.alerta-delete-flip'],
            {
                callback: () => {
                    this.form.reset()
                    this._cdref.markForCheck()
                }
            })
    }

    public get isUploadFlipInvalid(): boolean {
        return StatusUploadFlipErroEnum().includes(this.form.value.flip_status_renderizacao)
    }

    public get showUploadFlipInput(): boolean {
        return !this.form.value.flip_url && !this.form.value.flip_status_renderizacao
    }

    public get isUploadFlipCompiling(): boolean {
        return this.form.value.flip_status_renderizacao === StatusUploadFlipBookEnum.Renderizando
    }

    public get isUploadFlipComplete(): boolean {
        return !!this.form.value.flip_url && this.form.value.flip_status_renderizacao === StatusUploadFlipBookEnum.Renderizado
    }

    private notifyListener(): void {
        const time = 3 * 60 * 1000
        interval(time).pipe(
            takeUntil(this._stopNotify$),
            switchMap(() => this.service.getStatusRendering(this.form.value.id)),
            map(({ data }) => data),
            filter(({ flip_status_renderizacao }) => !!flip_status_renderizacao),
        ).subscribe(({ flip_status_renderizacao, flip_url }) => {
            this.form.controls.flip_status_renderizacao.setValue(flip_status_renderizacao)
            this.form.controls.flip_url.setValue(flip_url)
            if (flip_status_renderizacao === 1 && !!flip_url) {
                this.urlIFrameBook = this.sanitizer.bypassSecurityTrustResourceUrl(`${hubConst.web.didaticos}open-content/${this.form.value.conteudo_id}?frame=1&t=${this._token}&ta=1`)
            }
            this._stopNotify$.next()
        })
    }

    private initLanguage(): void {
        this._translate
            .get([
                'geral.sucesso',
                'geral.atencao',
                'geral.erro',
                'geral.sim',
                'geral.nao',
                'modulos.materiais-didaticos.alerta-tipo-livro',
                'modulos.materiais-didaticos.alerta-delete-flip'
            ])
            .subscribe((text: any) => {
                this._traducao = text
            })
    }

    private confirmAlert(msg: string, callbackSuccess, callbackCancel?): void {
        return this._swal.confirmAlertCustom(this._traducao['geral.atencao'], msg, 'warning', this._traducao['geral.sim'], this._traducao['geral.nao'], callbackSuccess, callbackCancel)
    }

    public onNewtry() {
        this.form.reset({conteudo_id: this.form.value.conteudo_id})
    }

    public registerOnChange(fn: (v: any) => void): void {
        this.onChange = fn
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn
    }

    public writeValue(data) {
        if (!!data) {
            this.form.patchValue(data)
        } else {
            this.reset()
        }
    }

    public onSelect(object: any = undefined) {
        this.onTouched()
        if (!!object) {
            this.onChange(object)
        } else {
            this.onChange(undefined)
        }
    }

    private reset() {
        this.form.reset()
        this.onSelect()
    }

    private resetDz() {
        this.dzComponentReset.emit(true)
    }

    private onChange = (_: any) => { }

    private onTouched = () => { }

}
