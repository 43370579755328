import { Injectable } from '@angular/core';
import { hubConst } from '@app/core';
import { Divisao } from '@app/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, Resp } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class DivisaoService {

    constructor(private apiService: ApiService) { }

    private _baseUrl = hubConst.api.usuario
    length: number;


    public index():Observable<Resp<Divisao[]>>{
        return this.apiService.get<Divisao[]>(`${this._baseUrl}v1/hub-educacional/divisoes`)
            .pipe(map(res => res as any))
    }

    public get(divisao_id: number):Observable<Resp<Divisao>>{
        return this.apiService.get(`${this._baseUrl}v1/hub-educacional/divisoes/${divisao_id}`)
    }

    public store(value: any = {}):Observable<Resp<any>> {
        return this.apiService.post(`${this._baseUrl}v1/hub-educacional/divisoes`, value)
    }

    public update(id: number, value: any):Observable<Resp<any>> {
        return this.apiService.put(`${this._baseUrl}v1/hub-educacional/divisoes/${id}`, value)
    }

    public destroy(id: number):Observable<Resp<any>> {
        return this.apiService.delete(`${this._baseUrl}v1/hub-educacional/divisoes/${id}`)
    }
}
