import { Injectable } from '@angular/core';
import { hubConst } from '@app/core';
import { ApiService, Resp } from '@services/api.service';
import { Category } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {
    private _baseUrl = hubConst.api.categories

    constructor(private apiService: ApiService) { }
    
    index(data: any): Observable<Resp<Category[]>> {
        return this.apiService.get<Category[]>(`${this._baseUrl}v1/category`, data)
    }

    getTree(categoryId: number): Observable<Resp<any>> {
        return this.apiService.get<any>(`${this._baseUrl}v1/categories/${categoryId}/tree`)
    }
}
