import { getHours, isFriday, isSaturday } from "date-fns";
import { CountryEnum } from "enums/country.enum";

export class Sabado {

    verificaSabado(date: Date, pais_id = CountryEnum.Brasil) {
       const inicioSabado = isFriday(date) && getHours(date) >= 18
       const fimSabado = isSaturday(date) && getHours(date) >= 18

       return inicioSabado || fimSabado
    }
}
