import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { PermissionsService } from '@services/permissions.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthAdventistSchoolGuard implements CanLoad {

    constructor(
        private permissionsService: PermissionsService,
        private router: Router
    ) { }

    public canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> | Observable<boolean> {
        return this.permissionsService.isAdventistSchool$.pipe(
            switchMap(resp => !!resp ? of(true) : this.router.navigate(['/']))
        )
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | Observable<boolean> {
        return this.permissionsService.isAdventistSchool$.pipe(
            switchMap(resp => !!resp ? of(true) : this.router.navigate(['/']))
        )
    }
}
