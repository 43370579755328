import { Injectable } from '@angular/core'
import CryptoJS from 'crypto-js'

@Injectable({
    providedIn: 'root'
})
export class CryptService {

    secretKey = "WOU8PPtLz7ZuuDBAHHy4kZHJU5RgyFYX";
    constructor() { }

    encrypt(value: string) {
        let encJson = CryptoJS.AES.encrypt(JSON.stringify(value), this.secretKey.trim()).toString()
        let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
        return encData
    }

    decrypt(textToDecrypt: string) {
        let decData = CryptoJS.enc.Base64.parse(textToDecrypt).toString(CryptoJS.enc.Utf8)
        let bytes = CryptoJS.AES.decrypt(decData, this.secretKey.trim()).toString(CryptoJS.enc.Utf8)
        return JSON.parse(bytes)
    }
}
