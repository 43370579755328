import { Injectable } from '@angular/core';
import { hubConst } from '@app/core';
import { ApiService, Resp } from '@services/api.service';
import { Product } from '@shared/models/didactic-material/product.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProductsService {
    private _baseUrl = hubConst.api.vouchers
    constructor(private api: ApiService) { }

    index(publishingId: number, defaultGradeId?: number, defaultGrades?: number[]): Observable<Resp<Product[]>> {
        return this.api.get<any[]>(`${this._baseUrl}v1/product`, {publishing_id: publishingId, default_grade_id: defaultGradeId, default_grades: defaultGrades})
    }
}
