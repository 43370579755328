import { Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[loading]'
})
export class LoadingDirective implements OnDestroy {
  @Input() public delayLoading = 500
  private _loading = new Subject();

  @Input()
  public set loading(isLoading: boolean) {
    this._loading.next(isLoading)
  }

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {
    this.loadListeners()
  }

  public ngOnDestroy(): void {
    this._loading.complete()
  }

  private loadListeners() {
    this._loading.pipe(switchMap(this.applyDelay)).subscribe(isLoading => {
      const height = this.elementRef.nativeElement.offsetHeight
      const width = this.elementRef.nativeElement.offsetWidth
      if (isLoading) {
        this.renderer.addClass(this.elementRef.nativeElement, 'load__loading')
        this.renderer.setAttribute(this.elementRef.nativeElement, 'style', `width: ${width}px; height: ${height}px;`)
      } else {
        this.renderer.removeClass(this.elementRef.nativeElement, 'load__loading')
        this.renderer.removeAttribute(this.elementRef.nativeElement, 'style')
      }
    })
  }

  private applyDelay = (loading: boolean): Observable<boolean> => {
    return loading ? of(true).pipe(delay(this.delayLoading)) : of(false)
  }

}
