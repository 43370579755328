import { Injectable } from '@angular/core';
import { Category } from '@shared/models';
import { Sumario } from '@shared/models/didactic-material';
import { BehaviorSubject, Observable } from 'rxjs';

export enum ModalSelectCategoriesOrigin {
    Question,
    Summary,
    Content,
    Evaluation,
    FilterQuestion
}

export enum ModalSelectCategoriesOperations {
    Append,
    Remove
}

export enum Matrixtype {
    Category = 1,
    DidacticMaterial = 2
}
interface ModalSelectCategoriesOptions {
    stage_id?: number
    grade_id?: number
    component_id?: number
    matrix_id?: number
}

export interface ModalSelectCategoriesState {
    origin: ModalSelectCategoriesOrigin
    origin_id?: number
    params?: any
    options?: ModalSelectCategoriesOptions
    items?: {
        categories: Category[]
        summaries: Sumario[]
    }
}

export interface ModalSelectCategoriesStateData extends ModalSelectCategoriesState {
    open: boolean
}

export interface ModalSelectCategoriesData {
    origin: ModalSelectCategoriesOrigin
    origin_id: number
    matrix_type: Matrixtype
    item: Category | Sumario
    operation: ModalSelectCategoriesOperations
}

@Injectable({
    providedIn: 'root'
})
export class ModalSelectCategoriesActionsService {
    private _state$ = new BehaviorSubject<ModalSelectCategoriesStateData>(undefined)
    private _data$ = new BehaviorSubject<ModalSelectCategoriesData>(undefined)
    private _contexts$ = new BehaviorSubject<any>(undefined)
    public destroy() {
        this._contexts$.complete()
    }

	public get state$(): Observable<ModalSelectCategoriesStateData> {
		return this._state$.asObservable()
	}

    public get data$(): Observable<ModalSelectCategoriesData> {
        return this._data$.asObservable()
    }

    public get contexts$(): Observable<any> {
        return this._contexts$.asObservable()
    }

    public onOpen(origin: ModalSelectCategoriesOrigin, originId: number, categories: Category[], summaries?: Sumario[], options?: ModalSelectCategoriesOptions) {
        const items = { categories, summaries }
        this._state$.next({open: true, origin, origin_id: originId, items, options})
    }

    public setData(data: ModalSelectCategoriesData) {
        this._data$.next(data)
    }

    public onClose(origin: ModalSelectCategoriesOrigin) {
        this._state$.next({open: false, origin})
    }

    public getContexts(data: any) {
        this._contexts$.next(data)
    }
}
