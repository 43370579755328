import { Injectable } from '@angular/core';
import { hubConst } from '@app/core';
import { Observable } from 'rxjs';

import { ApiService, Resp } from '../api.service';
import { CrudService } from '../crud.service';

@Injectable({
  providedIn: 'root'
})
export class ColecaoService extends CrudService<any> {

    constructor(protected readonly api: ApiService) {
        super(api, `${hubConst.api.avaliacoes_api}colecao`);
    }

    public deleteColecao(colecaoId): Observable<Resp<any>> {
        return this.api.delete(`${hubConst.api.avaliacoes_api}deletacolecoes/${colecaoId}`)
    }

    public deleteAno(params: any = {}): Observable<Resp<any>> {
        return this.api.delete(`${hubConst.api.avaliacoes_api}deletacolecoes`, params)
    }
}
