<ng-template #modalCategories let-modal>
    <div class="modal-header">
        <h5 class="modal-title">
            {{ 'classificacao.classificacoes' | translate }}
            <gpe-classifications [class]="'svg-icon-info'"></gpe-classifications>
        </h5>
        <button type="button" class="close" (click)="onClose()">
            <i aria-hidden="true" class="ki ki-close"></i>
        </button>
    </div>
    <div class="modal-body" style="height: 60vh;">
        <form [formGroup]="form">
            <div class="row">
                <div class="col-4">
                    <div class="form-group">
                        <gpe-ng-select-stage formControlName="stage_id"></gpe-ng-select-stage>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <gpe-ng-select-grade formControlName="grade_id" [stageId]="form.value.stage_id"></gpe-ng-select-grade>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <gpe-ng-select-component formControlName="component_id" [stageId]="form.value.stage_id" [gradeId]="form.value.grade_id"></gpe-ng-select-component>
                    </div>
                </div>
                <div *ngIf="matrix?.type == matrixType.Category" class="col-12">
                    <div class="form-group">
                        <div class="input-group">
                            <input type="text" [formControl]="codeCtrl" class="form-control" [placeholder]="'classificacao.buscar-por-codigo' | translate">
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button" (click)="onSearchByCode()" translate>geral.buscar</button>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </form>
        <ng-container *ngIf="!!matrix; else selectMatrix">
            <ul *ngIf="!!matrix" class="breadcrumb breadcrumb-item breadcrumb-transparent font-weight-bold p-0 mb-5">
                <li class="breadcrumb-item" (click)="onResetNavigationList()">
                    <i class="fas fa-home"></i>
                </li>
                <li class="breadcrumb-item text-muted">
                    <a href="javascript:;" (click)="onResetNavigationMatrixList()" class="text-muted text-hover-info">
                        {{ matrix.name }}
                    </a>
                </li>
                <li *ngIf="bookCtrl.value as book" class="breadcrumb-item text-muted">
                    <a href="javascript:;" (click)="onResetNavigationCategoryList()" class="text-muted text-hover-info">
                        <ng-container *ngIf="!!book.contextos?.length">
                            <span *ngIf="!!book.contextos[0]?.nivel">{{ book.contextos[0].nivel.descricao }}</span>
                            <span *ngIf="!!book.contextos[0]?.padrao_serie"> - {{ book.contextos[0].padrao_serie.descricao }}</span>
                            <span *ngIf="!!book.contextos[0]?.padrao_disciplina"> - {{ book.contextos[0].padrao_disciplina.descricao }}</span>
                            <span> - </span>
                        </ng-container>
                        {{ book.titulo }}
                    </a>
                </li>
                <ng-container *ngIf="breadcrumb$ | async as breadcrumb">
                    <li *ngFor="let item of breadcrumb; let last = last" class="breadcrumb-item text-muted">
                        <a href="javascript:;" (click)="!last ? onToggleCategory(item, true) : undefined" class="text-muted text-hover-info">
                            <ng-container *ngIf="matrix.type == matrixType.Category; else summary">{{ item.name }}</ng-container>
                            <ng-template #summary>{{ item.titulo }}</ng-template>
                        </a>
                    </li>
                </ng-container>
            </ul>
            <ng-container *ngIf="itemData$ | async as itemData; else selectBook">
                <ng-container *ngIf="!!itemData.items.length; else emptyList">
                    <div *ngFor="let item of itemData.items" class="form-group mb-3">
                        <div *ngIf="itemData.type == matrixType.Category; else didacticMaterial" class="input-group">
                            <button type="button" (click)="onToggleCategory(item)" [disabled]="!item.childs.length" class="btn btn-block btn-outline-{{ item.matrix.color_class }} form-control h-auto">
                                {{ item.name }}
                                <span *ngIf="item.code">({{ item.code }})</span>
                                <span *ngIf="item.description"> - {{ item.description }}</span>
                            </button>
                            <ng-container >
                                <div *ngIf="!!item.selectable" class="input-group-append">
                                    <button type="button" (click)="onSelect(item)" [disabled]="categoryAlreadyAdded(item)" class="border-1 border-{{ item.matrix.color_class }} btn btn-light-{{ item.matrix.color_class }} btn-icon h-100">
                                        <i class="fas fa-plus"></i>
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                        <ng-template #didacticMaterial>
                            <div class="input-group">
                                <button type="button" (click)="onToggleCategory(item)" [disabled]="!item.childs.length" class="btn btn-block btn-outline-dark form-control h-auto">
                                    {{ item.titulo }}
                                </button>
                                <div class="input-group-append">
                                    <button type="button" [disabled]="categoryAlreadyAdded(item)" (click)="onSelect(item)" class="border-1 border-dark btn btn-light-dark btn-icon h-100">
                                        <i class="fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </ng-container>
            </ng-container>
            <ng-template #selectBook>
                <gpe-card-items-book *ngIf="matrix.type == matrixType.DidacticMaterial" [formControl]="bookCtrl" [stageId]="form.value.stage_id" [gradeId]="form.value.grade_id" [componentId]="form.value.component_id"></gpe-card-items-book>
            </ng-template>
        </ng-container>
        <app-loading-block [data]="loadingData$ | async"></app-loading-block>
        <ng-template #selectMatrix>
            <gpe-card-items-matrix *ngIf="!(loadingData$ | async)?.display" 
                [formControl]="matrixCtrl" 
                [stageId]="form.value.stage_id"
                [gradeId]="form.value.grade_id"
                [componentId]="form.value.component_id"
                [exceptType]="exceptMatrixType"
                [matrixId]="this.state.options?.matrix_id"></gpe-card-items-matrix>
        </ng-template>
        <ng-template #emptyList>
            <div *ngIf="!(loadingData$ | async)?.display" class="jumbotron mt-10 text-center p-15" translate>
                classificacao.nenhuma-classificacao-encontrada
            </div>
        </ng-template>
    </div>
    <div class="modal-footer">
        <div class="d-flex gap-2 flex-wrap align-items-center w-100">
            <ng-container *ngIf="!!itemsMerged.length; else emptyCategoriesAdded">
                <ng-container *ngFor="let item of itemsMerged">
                    <gpe-chip-item-category [item]="item" (removeEvent)="onRemove($event)"></gpe-chip-item-category>
                </ng-container>
            </ng-container>
            <ng-template #emptyCategoriesAdded>
                <span class="text-muted" translate>classificacao.nenhuma-classificacao-adicionada</span>
            </ng-template>
        </div>
    </div>
</ng-template>
