import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SvgModule } from '@app/svg/svg.module';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { CKEditorModule } from 'ngx-ckeditor';
import { DROPZONE_CONFIG, DropzoneModule } from 'ngx-dropzone-wrapper';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ChipItemCategoryComponent } from './components/categories/chip-item-category/chip-item-category.component';
import { CardItemsBookComponent } from './components/categories/modal-select-categories/components/card-items-book/card-items-book.component';
import { CardItemsMatrixComponent } from './components/categories/modal-select-categories/components/card-items-matrix/card-items-matrix.component';
import { ModalSelectCategoriesComponent } from './components/categories/modal-select-categories/modal-select-categories.component';
import { NgSelectComponentComponent } from './components/categories/ng-select-component/ng-select-component.component';
import { NgSelectGradeComponent } from './components/categories/ng-select-grade/ng-select-grade.component';
import { NgSelectMatrixComponent } from './components/categories/ng-select-matrix/ng-select-matrix.component';
import { NgSelectStageComponent } from './components/categories/ng-select-stage/ng-select-stage.component';
import { HeaderComponent } from './components/header/header.component';
import { UploadElementAudioComponent } from './components/upload-element-audio/upload-element-audio.component';
import { UploadElementFlipComponent } from './components/upload-element-flip/upload-element-flip.component';
import { UploadElementImagemComponent } from './components/upload-element-imagem/upload-element-imagem.component';
import { UploadElementVideoComponent } from './components/upload-element-video/upload-element-video.component';
import { UploadElementComponent } from './components/upload-element/upload-element.component';
import { LoadingDirective } from './directives/loading.directive';
import { EditInlineModule } from './edit-inline/edit-inline.module';
import { EditorConteudoComponent } from './editor-conteudo/editor-conteudo.component';
import { LoadingBlockComponent } from './loading-block/loading-block.component';
import { PaginacaoModule } from './paginacao/paginacao.module';
import { FilterPipe } from './pipes/filter.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SelectEntidadeComponent } from './select-entidade/select-entidade.component';
import { SelectLivroComponent } from './select-livro/select-livro.component';
import { SelectTipoLivroComponent } from './select-tipo-livro/select-tipo-livro.component';
import { LivroService } from './service';
import { SidenavModule } from './sidenav/sidenav.module';
import { UploadFileComponentV2 } from './upload-file-v2/upload-file-v2.component';
import { DEFAULT_DROPZONE_CONFIG } from './upload-file/upload-file.module';

@NgModule({
    declarations: [
        SelectEntidadeComponent,
        SelectTipoLivroComponent,
        SelectLivroComponent,
        LoadingBlockComponent,
        EditorConteudoComponent,
        SafePipe,
        FilterPipe,
        LoadingDirective,
        UploadElementAudioComponent,
        UploadElementFlipComponent,
        UploadElementVideoComponent,
        UploadElementImagemComponent,
        UploadElementComponent,
        UploadFileComponentV2,
        ModalSelectCategoriesComponent,
        NgSelectStageComponent,
        NgSelectGradeComponent,
        NgSelectComponentComponent,
        NgSelectMatrixComponent,
        CardItemsBookComponent,
        CardItemsMatrixComponent,
        ChipItemCategoryComponent,
        HeaderComponent,
    ],
    imports: [
        CommonModule,
        SvgModule,
        TranslateModule,
        FormsModule,
        NgbModule,
        NgSelectModule,
        ReactiveFormsModule,
        CKEditorModule,
        PaginacaoModule,
        SidenavModule,
        DropzoneModule,
        DragDropModule,
        NgbPopoverModule,
    ],
    providers: [
        LivroService,
        {
            provide: DROPZONE_CONFIG,
            useValue: DEFAULT_DROPZONE_CONFIG,
        },
    ],
    exports: [
        TranslateModule,
        SelectEntidadeComponent,
        SelectTipoLivroComponent,
        SelectLivroComponent,
        LoadingBlockComponent,
        EditInlineModule,
        EditorConteudoComponent,
        FormsModule,
        ReactiveFormsModule,
        PaginacaoModule,
        NgbModule,
        SafePipe,
        FilterPipe,
        SvgModule,
        ReactiveFormsModule,
        LoadingDirective,
        UploadElementAudioComponent,
        UploadElementFlipComponent,
        UploadElementVideoComponent,
        UploadElementImagemComponent,
        UploadElementComponent,
        UploadFileComponentV2,
        NgSelectModule,
        ModalSelectCategoriesComponent,
        NgSelectStageComponent,
        NgSelectGradeComponent,
        NgSelectComponentComponent,
        NgSelectMatrixComponent,
        ChipItemCategoryComponent,
        HeaderComponent,
        DragDropModule,
        ImageCropperModule,
    ],
})
export class SharedModule {}
