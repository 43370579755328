import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavComponent } from './sidenav.component';
import { SidenavDialogCloseDirective } from './sidenav-dialog-close.directive';
import { SidenavService } from './sidenav.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SidenavComponent, SidenavDialogCloseDirective],
  imports: [CommonModule, TranslateModule],
  providers: [SidenavService],
  exports: [SidenavComponent, SidenavDialogCloseDirective]
})
export class SidenavModule { }
