<div class="row">
    <div class="col">
        <div class="card card-custom card-stretch gutter-b">
            <div class="card-header border-0">
                <div class="d-flex align-items-center flex-wrap mr-2">
                    <ng-content select="[header-icon]"></ng-content>
                    <ng-content select="[header-title]"></ng-content>
                    <div class="d-flex align-items-center gap-2">
                        <div class="pipe">&nbsp;</div>
                        <a class="m-nav__link m-nav__link--icon pl-3" href="">
                            <i class="m-nav__link-icon la la-home icon-lg"></i>
                        </a>
                        <ng-container *ngFor="let item of header$ | async; let i = index; let last = last">
                            <span class="text-muted">-</span>
                            <span class="text-dark">
                                <a *ngIf="!last" [href]="[item.url]" [queryParams]="item.queryParams" class="text-muted">
                                    <span class="m-nav__link-text">
                                        {{ item.texto | translate}}
                                    </span>
                                </a>
                                <span *ngIf="last" class="m-nav__link-text">
                                    {{ item.texto | translate }}
                                </span>
                            </span>
                        </ng-container>
                    </div>
                </div>
                <div class="card-toolbar">
                    <ul class="nav nav-pills nav-pills-sm nav-dark-75">
                        <ng-content select="[header-bar]"></ng-content>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
