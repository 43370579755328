import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { PermissionsService } from '@services/permissions.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthCoordenadorAvaliacaoGuard implements CanLoad {

    constructor(
        private permissionsService: PermissionsService,
        private router: Router
    ) { }

    public canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> | Observable<boolean> {
        return this.permissionsService.isCoordenadorAvaliacao$.pipe(
            switchMap(resp => !!resp ? of(true) : this.router.navigate(['/']))
        )
    }
}
