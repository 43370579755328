import { ComponentPortal, ComponentType, DomPortalOutlet } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { ApplicationRef, ComponentFactoryResolver, Inject, Injectable, Injector, Type } from '@angular/core';
import { DEFAULT_CONFIG, SIDENAV_CONFIG, SideNavContext, SideNavRef, SidenavServiceData } from '../sidenav/sidenav-ref';
import { SidenavComponent } from '../sidenav/sidenav.component';

@Injectable()
export class SidenavService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private resolve: ComponentFactoryResolver,
    private reference: ApplicationRef,
  ) { }

  public open<T, D = any, R = any>(reference: ComponentType<T>, config?: SidenavServiceData<D>): SideNavRef<R, T> {
    const context = new SideNavContext<R, T>();
    const host = this.hostFactory(this.injector(context, reference, config));
    const sideRef = (context as { instance: any, host: DomPortalOutlet });
    sideRef.host = host
    sideRef.instance = host.attachComponentPortal(this.componentFactory<T, D, R>()).instance
    return new SideNavRef(context)
  }

  private injector<T, D, R>(context: SideNavContext<R, T>, reference: ComponentType<T>, config?: SidenavServiceData<D>): Injector {
    return Injector.create({
      providers: [{
        provide: SIDENAV_CONFIG, useValue: { reference, config: { ...DEFAULT_CONFIG, ...(config || {}) } }
      },
      { provide: SideNavContext, useValue: context }],
    })
  }

  private componentFactory<T, D, R>(): ComponentPortal<SidenavComponent<T, D, R>> {
    const component: Type<SidenavComponent<T, D, R>> = SidenavComponent
    return new ComponentPortal(component);
  }

  private hostFactory(injector: Injector): DomPortalOutlet {
    return new DomPortalOutlet(this.document.body, this.resolve, this.reference, injector);
  }
}
