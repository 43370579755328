import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

const DEFAULT_MS = 5000;
@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private toastr: ToastrService) { }

    public show(message: string, title?: string, timeOut: number = DEFAULT_MS) {
        this.toastr.show(message, title, { timeOut });
    }

    public success(message: string, title?: string, timeOut: number = DEFAULT_MS) {
        this.toastr.success(message, title, { timeOut });
    }

    public warning(message: string, title?: string, timeOut: number = DEFAULT_MS) {
        this.toastr.warning(message, title, { timeOut });
    }

    public error(message: string, title?: string, timeOut: number = DEFAULT_MS) {
        this.toastr.error(message, title, { timeOut });
    }

    public info(message: string, title?: string, timeOut: number = DEFAULT_MS) {
        this.toastr.info(message, title, { timeOut });
    }
}
