import { Injectable } from '@angular/core';
import { hubConst } from '@app/core';
import { environment } from '@env/environment';

import { ApiService } from '../api.service';
import { CrudService } from '../crud.service';

@Injectable({
    providedIn: 'root',
})
export class AvaliacaoService extends CrudService<any> {

    constructor(protected readonly api: ApiService) {
        super(api, `${hubConst.api.avaliacoes_api}avaliacao`);
    }
}
