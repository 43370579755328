import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { hubConst } from '@app/core';
import { NotificationService } from '@app/core/service';
import { LocalStorageService } from '@app/guards/storage.service';
import { Swal } from '@app/utils';
import { TranslateService } from '@ngx-translate/core';
import { StatusUploadFlipBookEnum, StatusUploadFlipErroEnum } from '@shared/enums';
import { LivroService } from '@shared/service';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

interface model_flip {
    ea_teacher: any,
    ea_student: any,
    ex_teacher: any,
    ex_student: any
}

export enum FlipEnum {
    ea_teacher = 'ea_teacher',
    ea_student = 'ea_student',
    ex_teacher = 'ex_teacher',
    ex_student = 'ex_student'
}

export const int_model_flip: model_flip = {
    ea_teacher: null,
    ea_student: null,
    ex_teacher: null,
    ex_student: null
}

enum Aba {
    ea = 1,
    Externo = 2
}

@Component({
    selector: 'gpe-flips-livro-digital',
    templateUrl: './flips-livro-digital.component.html',
    styleUrls: ['./flips-livro-digital.component.scss']
})
export class FlipsLivroDigitalComponent implements OnInit, OnDestroy {
    @Input() formSubmitAttempt: boolean = false
    @Input() url_iframe = int_model_flip
    @Input() onlyTeacher = false
    @Output() flip = new EventEmitter()

    private readonly _api: any = hubConst.api
    private readonly _storage = this._localStorageService.storageUsuario
    private readonly _pathTempFile: string = hubConst.cpb_edu_path_temp
    private readonly _tempUploadFileUrl: string = `${this._api.didaticos}temp-upload/v1/file`

    public form: FormGroup
    public FlipEnum = FlipEnum
    public Aba = Aba
    public onlyExternal = false
    public abaSelecionada = Aba.ea

    public books$

    private _traducao = []

    private _destroy$ = new Subject()
    private _stopNotify$: Subject<model_flip> = new Subject()

    public config: DropzoneConfigInterface = {
        clickable: true,
        url: this._tempUploadFileUrl,
        params: {
            savePhoto: true,
            largura: 500,
            altura: 500,
            limiteMaximo: true,
            pesoMaximo: 300,
            stringKbMb: 'kb'
        },
        createImageThumbnails: false,
        maxFiles: 1,
        maxFilesize: 0.25,
        acceptedFiles: '.jpg,.jpeg,.png',
        previewsContainer: false
    }
    public configFlip: DropzoneConfigInterface = {
        clickable: true,
        url: this._tempUploadFileUrl,
        params: {
            limiteMaximo: true,
            pesoMaximo: 1024,
            stringKbMb: 'mb',
            editora_id: this._storage.country_selected.editoras[0].id
        },
        createImageThumbnails: false,
        maxFiles: 1,
        acceptedFiles: '.zip',
        previewsContainer: false
    }

    constructor(
        private _formBuilder: FormBuilder,
        private _localStorageService: LocalStorageService,
        private _translate: TranslateService,
        private _swal: Swal,
        private _notificationService: NotificationService,
        private _bookService: LivroService,
        private _route: ActivatedRoute
    ) {
        this.initLanguage()
        this.initForm()
    }

    @Input('onlyExternal') public set setOnlyExternal(value) {
        this.onlyExternal = value
        if (this.onlyExternal) {
            this.onSelectAba(Aba.Externo)
        }
    }

    public ngOnInit(): void {
        this.books$ = this._bookService.books$
            .pipe(
                filter(value => !!value),
                tap(books => {
                    for (const [key, value] of Object.entries(books)) {
                        if (!!value) {
                            const id = this._route.snapshot.params?.id
                            if (!this.form.value.id && id) {
                                this.form.get('id').setValue(id, { emitEvent: false })
                            }
                            if (!this.form.value?.['file_' + key] && !!value['nome_capa']) {
                                this.form.get(['file_' + key])?.setValue(value['nome_capa'], { emitEvent: false })
                                this.form.controls?.['file_flip_' + key].addValidators([Validators.required])
                                this.form.controls?.['file_flip_' + key].updateValueAndValidity({ emitEvent: false })
                            }
                            if (!this.form.value?.['file_flip_' + key] && !!value['flip_url']) {
                                this.form.get(['file_flip_' + key])?.setValue(value['flip_url'], { emitEvent: false })
                                this.form.controls?.['file_' + key].addValidators([Validators.required])
                                this.form.controls?.['file_' + key].updateValueAndValidity({ emitEvent: false })
                            }
                        }
                    }
                })
            )
    }

    public ngOnDestroy(): void {
        this._destroy$.next()
        this._destroy$.complete()
        this._stopNotify$.complete()
    }

    public onUploadCapa(event, name: FlipEnum): void {
        if (event.success) {
            this._bookService.books$.next({ ...this._bookService.books$.value, [name]: { ...this._bookService.books$.value?.[name], nome_capa: this._pathTempFile + event.file } })
            this.form.get(['hash_' + name]).setValue(event.hash, { emitEvent: false })
            this.form.get(['file_' + name]).setValue(event.file)
        }
    }

    public onUploadFlip(event, name: FlipEnum): void {
        if (event.success) {
            this._bookService.books$.next({ ...this._bookService.books$.value, [name]: { ...this._bookService.books$.value?.[name], flip_url: null, flip_status_renderizacao: StatusUploadFlipBookEnum.Renderizando, title: event.title } })
            this.form.get(['hash_flip_' + name]).setValue(event.hash, { emitEvent: false })
            this.form.get(['file_flip_' + name]).setValue(event.file)
        }
    }

    public isInvalid(field: string): boolean {
        return this.form.controls[field].invalid && (this.form.controls[field].touched || this.formSubmitAttempt)
    }

    public isInvalidGeneralButtonEA(): boolean {
        return this.isInvalid('file_' + FlipEnum.ea_teacher) || this.isInvalid('file_' + FlipEnum.ea_student) || this.isInvalid('file_flip_' + FlipEnum.ea_teacher) || this.isInvalid('file_flip_' + FlipEnum.ea_student)
    }

    public isInvalidGeneralButtonExt(): boolean {
        return this.isInvalid('file_' + FlipEnum.ex_teacher) || this.isInvalid('file_' + FlipEnum.ex_student) || this.isInvalid('file_flip_' + FlipEnum.ex_teacher) || this.isInvalid('file_flip_' + FlipEnum.ex_student)
    }

    public isUploadFlipInvalid(name: FlipEnum): boolean {
        if (!this.form.value.id) {
            return false;
        }

        return StatusUploadFlipErroEnum().includes(Number(this._bookService.books$.value?.[name]?.flip_status_renderizacao))
    }

    public showUploadFlipInput(name: FlipEnum): boolean {
        const book = this._bookService.books$.value?.[name]
        if (!this.form.value.id && !book) {
            return true;
        }
        return !book || (!book?.flip_url && book?.flip_status_renderizacao != StatusUploadFlipBookEnum.Renderizando)
    }

    public isUploadFlipCompiling(name: FlipEnum): boolean {
        return this._bookService.books$.value?.[name]?.flip_status_renderizacao === StatusUploadFlipBookEnum.Renderizando
    }

    public isUploadFlipComplete(name: FlipEnum): boolean {
        if (!this.form.value.id) {
            return false;
        }
        const book = this._bookService.books$.value?.[name]

        return !!book?.flip_url && book.flip_status_renderizacao === StatusUploadFlipBookEnum.Renderizado
    }

    public onDeleteFlip(name: FlipEnum): void {
        this.confirmAlert(this._traducao['modulos.materiais-didaticos.alerta-delete-flip'],
            {
                callback: () => {
                    const data = this._bookService.books$.value
                    this._bookService.books$.next({ ...data, [name]: { ...data?.[name], flip_url: null } })
                    this.url_iframe[name] = undefined
                    this.form.get(['file_flip_' + name]).setValue(null, { emitEvent: false })
                    this.form.get(['hash_flip_' + name]).setValue(null)
                    this.showSuccess(this._traducao['modulos.materiais-didaticos.flip-deletado'])
                }
            })
    }

    public onDeleteImage(name: FlipEnum): void {
        this.confirmAlert(this._traducao['modulos.materiais-didaticos.alerta-delete-flip'],
            {
                callback: () => {
                    const data = this._bookService.books$.value
                    this._bookService.books$.next({ ...data, [name]: { ...data?.[name], nome_capa: null } })
                    this.form.get(['file_' + name]).setValue(null, { emitEvent: false })
                    this.form.get(['hash_' + name]).setValue(null)
                    this.showSuccess(this._traducao['modulos.materiais-didaticos.flip-deletado'])
                }
            })
    }

    public onDeleteFlipTemp(name: FlipEnum): void {
        this.confirmAlert(this._traducao['modulos.materiais-didaticos.alerta-delete-flip'],
            {
                callback: () => {
                    this._bookService.books$.next({ ...this._bookService.books$.value, [name]: { ...this._bookService.books$.value?.[name], flip_url: null, flip_status_renderizacao: StatusUploadFlipBookEnum.Renderizado, title: null } })
                    this.form.controls?.['file_flip_' + name].setValue(null, { emitEvent: false })
                    this.form.controls?.['hash_flip_' + name].setValue(null)
                    this.showSuccess(this._traducao['modulos.materiais-didaticos.flip-deletado'])
                }
            })
    }

    public onOpenBookBlank(url: string): void {
        this._localStorageService.getTemporaryToken().subscribe(({ token }) => window.open(`${url}&t=${token}`, '_blank'));
    }

    private showSuccess(msg: string): void {
        this._notificationService.success(msg)
    }

    private confirmAlert(msg: string, callbackSuccess, callbackCancel?): void {
        return this._swal.confirmAlertCustom(this._traducao['geral.atencao'], msg, 'warning', this._traducao['geral.sim'], this._traducao['geral.nao'], callbackSuccess, callbackCancel)
    }

    private initLanguage(): void {
        this._translate
            .get([
                'geral.atencao',
                'geral.sim',
                'geral.nao',
                'modulos.materiais-didaticos.alerta-delete-flip',
                'modulos.materiais-didaticos.flip-deletado'
            ]).subscribe(text => this._traducao = text)
    }

    private initForm(): void {
        this.form = this._formBuilder.group({
            id: undefined,
            file_ea_teacher: undefined,
            file_ea_student: undefined,
            file_ex_teacher: undefined,
            file_ex_student: undefined,

            file_flip_ea_teacher: undefined,
            file_flip_ea_student: undefined,
            file_flip_ex_teacher: undefined,
            file_flip_ex_student: undefined,

            hash_ea_teacher: undefined,
            hash_ea_student: undefined,
            hash_ex_teacher: undefined,
            hash_ex_student: undefined,

            hash_flip_ea_teacher: undefined,
            hash_flip_ea_student: undefined,
            hash_flip_ex_teacher: undefined,
            hash_flip_ex_student: undefined
        })

        this.form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(value => this.flip.emit(value))
        this.validDataFlip()
    }

    private validDataFlip(): void {
        this.validRequiredData(FlipEnum.ex_student)
        this.validRequiredData(FlipEnum.ex_teacher)
        this.validRequiredData(FlipEnum.ea_teacher)
        this.validRequiredData(FlipEnum.ea_student)
    }

    private validRequiredData(name: FlipEnum): void {
        this.form.controls?.['file_flip_' + name].valueChanges.pipe(takeUntil(this._destroy$)).subscribe(value => {
            if (!!value) {
                this.form.controls?.['file_' + name].addValidators([Validators.required])
            } else if (!this.form.value?.['file_' + name]) {
                this.form.controls?.['file_' + name].removeValidators([Validators.required])
            }
            this.form.controls?.['file_' + name].updateValueAndValidity({ emitEvent: false })
        })
        this.form.controls?.['file_' + name].valueChanges.pipe(takeUntil(this._destroy$)).subscribe(value => {
            if (!!value) {
                this.form.controls?.['file_flip_' + name].addValidators([Validators.required])
            } else if (!this.form.value?.['file_flip_' + name]) {
                this.form.controls?.['file_flip_' + name].removeValidators([Validators.required])
            }
            this.form.controls?.['file_flip_' + name].updateValueAndValidity({ emitEvent: false })
        })
    }

    public onSelectAba(aba) {
        this.abaSelecionada = aba
    }
}
