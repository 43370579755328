<div class="card card-custom card-stretch gutter-b ribbon ribbon-clip ribbon-right">
    <div class="card-header">
        <h3 class="card-title font-weight-bolder text-dark" translate>
            modulos.materiais-didaticos.livro-digital</h3>
        <div class="card-toolbar">
            <ul class="nav nav-pills nav-pills-sm nav-dark-75">
                <li class="nav-item mr-2" [ngClass]="{'border rounded border-danger': isInvalidGeneralButtonEA()}">
                    <a class="btn nav-link py-2 px-4 active" data-toggle="tab" href="#tabEA">
                        {{'modulos.materiais-didaticos.livros-digitais.educacao-adventista' | translate}}
                    </a>
                </li>
                <li class="nav-item" [ngClass]="{'border rounded border-danger': isInvalidGeneralButtonExt()}">
                    <a class="btn nav-link py-2 px-4" data-toggle="tab" href="#tabExt">
                        {{'modulos.materiais-didaticos.livros-digitais.escolas-externas' | translate}}
                    </a>
                </li>
            </ul>
        </div>
    </div>


    <div class="card-body" *ngIf="books$ | async as books">
        <div class="tab-content">
            <div class="tab-pane fade active show" id="tabEA" role="tabpanel" aria-labelledby="tabEA">
                <div class="accordion accordion-toggle-arrow" id="accordionEA">
                    <div class="card"
                        [ngClass]="{'border rounded border-danger': isInvalid('file_' + FlipEnum.ea_teacher) || isInvalid('file_flip_' + FlipEnum.ea_teacher)}">
                        <div class="card-header">
                            <div class="card-title" data-toggle="collapse" data-target="#collapse_ea_teacher"> {{
                                'geral.professor' | translate}}
                                <i *ngIf="!!books?.[FlipEnum.ea_teacher]?.nome_capa && !!books?.[FlipEnum.ea_teacher]?.flip_url"
                                    class="ml-2 far fa-check-circle text-success"></i>
                            </div>
                        </div>
                        <div id="collapse_ea_teacher" class="collapse show" data-parent="#accordionEA">
                            <div class="card-body">

                                <div class="mt-2 row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>{{'dropzone.imagem' | translate}}</label>
                                            <button *ngIf="!!books?.[FlipEnum.ea_teacher]?.nome_capa"
                                                (click)="onDeleteImage(FlipEnum.ea_teacher)" type="button"
                                                class="btn btn-icon btn-light-danger btn-hover-danger btn-sm ml-2 mb-2"
                                                triggers="mouseenter:mouseleave"
                                                [ngbPopover]="'geral.excluir' | translate">
                                                <span class="svg-icon svg-icon-md svg-icon-danger">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                        height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none"
                                                            fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path
                                                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                                fill="#000000" fill-rule="nonzero" />
                                                            <path
                                                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                                fill="#000000" opacity="0.3" />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </button>
                                            <gpe-upload-file-v2
                                                (onStatusChange)="onUploadCapa($event, FlipEnum.ea_teacher)"
                                                [config]="config" [link]="books?.[FlipEnum.ea_teacher]?.nome_capa"
                                                class="dz--wide wrap-dropzone w-100"
                                                [isInvalid]="isInvalid('file_' + FlipEnum.ea_teacher)"
                                                [titulo]="'dropzone.imagem' | translate">
                                            </gpe-upload-file-v2>
                                        </div>
                                    </div>

                                    <div class="col-8">
                                        <div class="alert alert-custom alert-notice alert-light-warning fade show"
                                            role="alert" *ngIf="isUploadFlipInvalid(FlipEnum.ea_teacher)">
                                            <div class="alert-icon">
                                                <i class="flaticon-warning"></i>
                                            </div>
                                            <div class="alert-text" translate>
                                                modulos.materiais-didaticos.msg-erro-upload-flip
                                            </div>
                                        </div>

                                        <ng-container *ngIf="isUploadFlipCompiling(FlipEnum.ea_teacher)">
                                            <table class="custom table table-striped m-table table-hover"
                                                *ngIf="!!books?.[FlipEnum.ea_teacher]?.title; else rederizando_ea">
                                                <thead class="m-datatable__head">
                                                    <tr>
                                                        <th class="text-center" width="20%" translate>geral.icone
                                                        </th>
                                                        <th class="text-left" width="60%" translate>geral.titulo
                                                        </th>
                                                        <th class="text-center" width="20%" translate>geral.acoes
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center text-nowrap">
                                                            <img class="m--margin-right-5 m--margin-left-5" alt="_image"
                                                                width="40" src="assets/images/files/zip.svg" />
                                                        </td>
                                                        <td class="text-left tdEllipsis align-middle">
                                                            <span placement="top"
                                                                [ngbPopover]="books?.[FlipEnum.ea_teacher]?.title"
                                                                triggers="mouseenter:mouseleave">
                                                                {{ books?.[FlipEnum.ea_teacher]?.title }} </span>
                                                        </td>
                                                        <td class="text-center text-nowrap align-middle">
                                                            <button type="button"
                                                                class="btn btn-icon btn-light btn-hover-danger btn-sm ml-3"
                                                                placement="top"
                                                                [ngbPopover]="'geral.excluir' | translate"
                                                                triggers="mouseenter:mouseleave"
                                                                (click)="onDeleteFlipTemp(FlipEnum.ea_teacher)">
                                                                <span class="svg-icon svg-icon-md svg-icon-danger">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px" height="24px" viewBox="0 0 24 24"
                                                                        version="1.1">
                                                                        <g stroke="none" stroke-width="1" fill="none"
                                                                            fill-rule="evenodd">
                                                                            <rect x="0" y="0" width="24" height="24">
                                                                            </rect>
                                                                            <path
                                                                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                                                fill="#000000" fill-rule="nonzero">
                                                                            </path>
                                                                            <path
                                                                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                                                fill="#000000" opacity="0.3"></path>
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <ng-template #rederizando_ea>
                                                <div class="alert alert-primary mb-5 p-5" role="alert">
                                                    <h4 class="alert-heading" translate>
                                                        modulos.materiais-didaticos.processando
                                                    </h4>
                                                    <p translate>
                                                        modulos.materiais-didaticos.msg-renderizando-upload-flip
                                                    </p>
                                                </div>
                                            </ng-template>
                                        </ng-container>

                                        <div [hidden]="!showUploadFlipInput(FlipEnum.ea_teacher)">
                                            <gpe-upload-file-v2 height="48"
                                                (onStatusChange)="onUploadFlip($event, FlipEnum.ea_teacher)"
                                                [config]="configFlip" class="wrap-dropzone dz-60h mt-8"
                                                [isInvalid]="isInvalid('file_flip_' + FlipEnum.ea_teacher)"
                                                titulo="Flip">
                                            </gpe-upload-file-v2>
                                        </div>
                                        <div *ngIf="isUploadFlipComplete(FlipEnum.ea_teacher)">
                                            <button (click)="onDeleteFlip(FlipEnum.ea_teacher)" type="button"
                                                class="btn btn-icon btn-light-danger btn-hover-danger btn-sm mb-2 float-right"
                                                style="margin-top: -13px;" triggers="mouseenter:mouseleave"
                                                [ngbPopover]="'geral.excluir' | translate">
                                                <span class="svg-icon svg-icon-md svg-icon-danger">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                        height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none"
                                                            fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path
                                                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                                fill="#000000" fill-rule="nonzero" />
                                                            <path
                                                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                                fill="#000000" opacity="0.3" />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </button>
                                            <button (click)="onOpenBookBlank(url_iframe?.[FlipEnum.ea_teacher])" type="button"
                                                class="btn btn-icon btn-light-info btn-hover-info btn-sm mb-2 mr-1 float-right"
                                                style="margin-top: -13px;" triggers="mouseenter:mouseleave"
                                                [ngbPopover]="'geral.visualizar-flip' | translate">
                                                <i class="fas fa-expand-alt"></i>
                                            </button>
                                            <iframe [src]="url_iframe?.[FlipEnum.ea_teacher] | safe : 'resourceUrl'" frameborder="0"
                                                style="width: 100%; height: 40vh;"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card"
                        [ngClass]="{'border rounded border-danger': isInvalid('file_' + FlipEnum.ea_student) || isInvalid('file_flip_' + FlipEnum.ea_student)}">
                        <div class="card-header">
                            <div class="card-title collapsed" data-toggle="collapse" data-target="#collapse_ea_student">
                                {{ 'geral.aluno' | translate}}
                                <i *ngIf="!!books?.[FlipEnum.ea_student]?.nome_capa && !!books?.[FlipEnum.ea_student]?.flip_url"
                                    class="ml-2 far fa-check-circle text-success"></i>
                            </div>
                        </div>
                        <div id="collapse_ea_student" class="collapse" data-parent="#accordionEA">
                            <div class="card-body">

                                <div class="mt-2 row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>{{'dropzone.imagem' | translate}}</label>
                                            <button *ngIf="!!books?.[FlipEnum.ea_student]?.nome_capa"
                                                (click)="onDeleteImage(FlipEnum.ea_student)" type="button"
                                                class="btn btn-icon btn-light-danger btn-hover-danger btn-sm ml-2 mb-2"
                                                triggers="mouseenter:mouseleave"
                                                [ngbPopover]="'geral.excluir' | translate">
                                                <span class="svg-icon svg-icon-md svg-icon-danger">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                        height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none"
                                                            fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path
                                                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                                fill="#000000" fill-rule="nonzero" />
                                                            <path
                                                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                                fill="#000000" opacity="0.3" />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </button>
                                            <gpe-upload-file-v2
                                                (onStatusChange)="onUploadCapa($event, FlipEnum.ea_student)"
                                                [config]="config" [link]="books?.[FlipEnum.ea_student]?.nome_capa"
                                                class="dz--wide wrap-dropzone w-100"
                                                [isInvalid]="isInvalid('file_' + FlipEnum.ea_student)"
                                                [titulo]="'dropzone.imagem' | translate">
                                            </gpe-upload-file-v2>
                                        </div>
                                    </div>

                                    <div class="col-8">
                                        <div class="alert alert-custom alert-notice alert-light-warning fade show"
                                            role="alert" *ngIf="isUploadFlipInvalid(FlipEnum.ea_student)">
                                            <div class="alert-icon">
                                                <i class="flaticon-warning"></i>
                                            </div>
                                            <div class="alert-text" translate>
                                                modulos.materiais-didaticos.msg-erro-upload-flip
                                            </div>
                                        </div>

                                        <ng-container *ngIf="isUploadFlipCompiling(FlipEnum.ea_student)">
                                            <table class="custom table table-striped m-table table-hover"
                                                *ngIf="!!books?.[FlipEnum.ea_student]?.title; else rederizando_ea">
                                                <thead class="m-datatable__head">
                                                    <tr>
                                                        <th class="text-center" width="20%" translate>geral.icone
                                                        </th>
                                                        <th class="text-left" width="60%" translate>geral.titulo
                                                        </th>
                                                        <th class="text-center" width="20%" translate>geral.acoes
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center text-nowrap">
                                                            <img class="m--margin-right-5 m--margin-left-5" alt="_image"
                                                                width="40" src="assets/images/files/zip.svg" />
                                                        </td>
                                                        <td class="text-left tdEllipsis align-middle">
                                                            <span placement="top"
                                                                [ngbPopover]="books?.[FlipEnum.ea_student]?.title"
                                                                triggers="mouseenter:mouseleave">
                                                                {{ books?.[FlipEnum.ea_student]?.title }} </span>
                                                        </td>
                                                        <td class="text-center text-nowrap align-middle">
                                                            <button type="button"
                                                                class="btn btn-icon btn-light btn-hover-danger btn-sm ml-3"
                                                                placement="top"
                                                                [ngbPopover]="'geral.excluir' | translate"
                                                                triggers="mouseenter:mouseleave"
                                                                (click)="onDeleteFlipTemp(FlipEnum.ea_student)">
                                                                <span class="svg-icon svg-icon-md svg-icon-danger">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px" height="24px" viewBox="0 0 24 24"
                                                                        version="1.1">
                                                                        <g stroke="none" stroke-width="1" fill="none"
                                                                            fill-rule="evenodd">
                                                                            <rect x="0" y="0" width="24" height="24">
                                                                            </rect>
                                                                            <path
                                                                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                                                fill="#000000" fill-rule="nonzero">
                                                                            </path>
                                                                            <path
                                                                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                                                fill="#000000" opacity="0.3"></path>
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <ng-template #rederizando_ea>
                                                <div class="alert alert-primary mb-5 p-5" role="alert">
                                                    <h4 class="alert-heading" translate>
                                                        modulos.materiais-didaticos.processando
                                                    </h4>
                                                    <p translate>
                                                        modulos.materiais-didaticos.msg-renderizando-upload-flip
                                                    </p>
                                                </div>
                                            </ng-template>
                                        </ng-container>

                                        <div [hidden]="!showUploadFlipInput(FlipEnum.ea_student)">
                                            <gpe-upload-file-v2 height="48"
                                                (onStatusChange)="onUploadFlip($event, FlipEnum.ea_student)"
                                                [config]="configFlip" class="wrap-dropzone dz-60h mt-8"
                                                [isInvalid]="isInvalid('file_flip_' + FlipEnum.ea_student)"
                                                titulo="Flip">
                                            </gpe-upload-file-v2>
                                        </div>
                                        <div *ngIf="isUploadFlipComplete(FlipEnum.ea_student)">
                                            <button (click)="onDeleteFlip(FlipEnum.ea_student)" type="button"
                                                class="btn btn-icon btn-light-danger btn-hover-danger btn-sm mb-2 float-right"
                                                style="margin-top: -13px;" triggers="mouseenter:mouseleave"
                                                [ngbPopover]="'geral.excluir' | translate">
                                                <span class="svg-icon svg-icon-md svg-icon-danger">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                        height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none"
                                                            fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path
                                                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                                fill="#000000" fill-rule="nonzero" />
                                                            <path
                                                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                                fill="#000000" opacity="0.3" />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </button>
                                            <button (click)="onOpenBookBlank(url_iframe?.[FlipEnum.ea_student])" type="button"
                                                class="btn btn-icon btn-light-info btn-hover-info btn-sm mb-2 mr-1 float-right"
                                                style="margin-top: -13px;" triggers="mouseenter:mouseleave"
                                                [ngbPopover]="'geral.visualizar-flip' | translate">
                                                <i class="fas fa-expand-alt"></i>
                                            </button>
                                            <iframe [src]="url_iframe?.[FlipEnum.ea_student] | safe : 'resourceUrl'" frameborder="0"
                                                style="width: 100%; height: 40vh;"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="tabExt" role="tabpanel" aria-labelledby="tabExt">
                <div class="accordion accordion-toggle-arrow" id="accordionExt">
                    <div class="card"
                        [ngClass]="{'border rounded border-danger': isInvalid('file_' + FlipEnum.ex_teacher) || isInvalid('file_flip_' + FlipEnum.ex_teacher)}">
                        <div class="card-header">
                            <div class="card-title" data-toggle="collapse" data-target="#collapse_ex_teacher"> {{
                                'geral.professor' | translate}}
                                <i *ngIf="!!books?.[FlipEnum.ex_teacher]?.nome_capa && !!books?.[FlipEnum.ex_teacher]?.flip_url"
                                    class="ml-2 far fa-check-circle text-success"></i>
                            </div>
                        </div>
                        <div id="collapse_ex_teacher" class="collapse show" data-parent="#accordionExt">
                            <div class="card-body">

                                <div class="mt-2 row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>{{'dropzone.imagem' | translate}}</label>
                                            <button *ngIf="!!books?.[FlipEnum.ex_teacher]?.nome_capa"
                                                (click)="onDeleteImage(FlipEnum.ex_teacher)" type="button"
                                                class="btn btn-icon btn-light-danger btn-hover-danger btn-sm ml-2 mb-2"
                                                triggers="mouseenter:mouseleave"
                                                [ngbPopover]="'geral.excluir' | translate">
                                                <span class="svg-icon svg-icon-md svg-icon-danger">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                        height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none"
                                                            fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path
                                                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                                fill="#000000" fill-rule="nonzero" />
                                                            <path
                                                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                                fill="#000000" opacity="0.3" />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </button>
                                            <gpe-upload-file-v2
                                                (onStatusChange)="onUploadCapa($event, FlipEnum.ex_teacher)"
                                                [config]="config" [link]="books?.[FlipEnum.ex_teacher]?.nome_capa"
                                                class="dz--wide wrap-dropzone w-100"
                                                [isInvalid]="isInvalid('file_' + FlipEnum.ex_teacher)"
                                                [titulo]="'dropzone.imagem' | translate">
                                            </gpe-upload-file-v2>
                                        </div>
                                    </div>

                                    <div class="col-8">
                                        <div class="alert alert-custom alert-notice alert-light-warning fade show"
                                            role="alert" *ngIf="isUploadFlipInvalid(FlipEnum.ex_teacher)">
                                            <div class="alert-icon">
                                                <i class="flaticon-warning"></i>
                                            </div>
                                            <div class="alert-text" translate>
                                                modulos.materiais-didaticos.msg-erro-upload-flip
                                            </div>
                                        </div>

                                        <ng-container *ngIf="isUploadFlipCompiling(FlipEnum.ex_teacher)">
                                            <table class="custom table table-striped m-table table-hover"
                                                *ngIf="!!books?.[FlipEnum.ex_teacher]?.title; else rederizando_ea">
                                                <thead class="m-datatable__head">
                                                    <tr>
                                                        <th class="text-center" width="20%" translate>geral.icone
                                                        </th>
                                                        <th class="text-left" width="60%" translate>geral.titulo
                                                        </th>
                                                        <th class="text-center" width="20%" translate>geral.acoes
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center text-nowrap">
                                                            <img class="m--margin-right-5 m--margin-left-5" alt="_image"
                                                                width="40" src="assets/images/files/zip.svg" />
                                                        </td>
                                                        <td class="text-left tdEllipsis align-middle">
                                                            <span placement="top"
                                                                [ngbPopover]="books?.[FlipEnum.ex_teacher]?.title"
                                                                triggers="mouseenter:mouseleave">
                                                                {{ books?.[FlipEnum.ex_teacher]?.title }} </span>
                                                        </td>
                                                        <td class="text-center text-nowrap align-middle">
                                                            <button type="button"
                                                                class="btn btn-icon btn-light btn-hover-danger btn-sm ml-3"
                                                                placement="top"
                                                                [ngbPopover]="'geral.excluir' | translate"
                                                                triggers="mouseenter:mouseleave"
                                                                (click)="onDeleteFlipTemp(FlipEnum.ex_teacher)">
                                                                <span class="svg-icon svg-icon-md svg-icon-danger">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px" height="24px" viewBox="0 0 24 24"
                                                                        version="1.1">
                                                                        <g stroke="none" stroke-width="1" fill="none"
                                                                            fill-rule="evenodd">
                                                                            <rect x="0" y="0" width="24" height="24">
                                                                            </rect>
                                                                            <path
                                                                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                                                fill="#000000" fill-rule="nonzero">
                                                                            </path>
                                                                            <path
                                                                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                                                fill="#000000" opacity="0.3"></path>
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <ng-template #rederizando_ea>
                                                <div class="alert alert-primary mb-5 p-5" role="alert">
                                                    <h4 class="alert-heading" translate>
                                                        modulos.materiais-didaticos.processando
                                                    </h4>
                                                    <p translate>
                                                        modulos.materiais-didaticos.msg-renderizando-upload-flip
                                                    </p>
                                                </div>
                                            </ng-template>
                                        </ng-container>

                                        <div [hidden]="!showUploadFlipInput(FlipEnum.ex_teacher)">
                                            <gpe-upload-file-v2 height="48"
                                                (onStatusChange)="onUploadFlip($event, FlipEnum.ex_teacher)"
                                                [config]="configFlip" class="wrap-dropzone dz-60h mt-8"
                                                [isInvalid]="isInvalid('file_flip_' + FlipEnum.ex_teacher)"
                                                titulo="Flip">
                                            </gpe-upload-file-v2>
                                        </div>
                                        <div *ngIf="isUploadFlipComplete(FlipEnum.ex_teacher)">
                                            <button (click)="onDeleteFlip(FlipEnum.ex_teacher)" type="button"
                                                class="btn btn-icon btn-light-danger btn-hover-danger btn-sm mb-2 float-right"
                                                style="margin-top: -13px;" triggers="mouseenter:mouseleave"
                                                [ngbPopover]="'geral.excluir' | translate">
                                                <span class="svg-icon svg-icon-md svg-icon-danger">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                        height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none"
                                                            fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path
                                                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                                fill="#000000" fill-rule="nonzero" />
                                                            <path
                                                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                                fill="#000000" opacity="0.3" />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </button>
                                            <button (click)="onOpenBookBlank(url_iframe?.[FlipEnum.ex_teacher])" type="button"
                                                class="btn btn-icon btn-light-info btn-hover-info btn-sm mb-2 mr-1 float-right"
                                                style="margin-top: -13px;" triggers="mouseenter:mouseleave"
                                                [ngbPopover]="'geral.visualizar-flip' | translate">
                                                <i class="fas fa-expand-alt"></i>
                                            </button>
                                            <iframe [src]="url_iframe?.[FlipEnum.ex_teacher] | safe : 'resourceUrl'" frameborder="0"
                                                style="width: 100%; height: 40vh;"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card"
                        [ngClass]="{'border rounded border-danger': isInvalid('file_' + FlipEnum.ex_student) || isInvalid('file_flip_' + FlipEnum.ex_student)}">
                        <div class="card-header">
                            <div class="card-title collapsed" data-toggle="collapse" data-target="#collapse_ex_student">
                                {{ 'geral.aluno' | translate}}
                                <i *ngIf="!!books?.[FlipEnum.ex_student]?.nome_capa && !!books?.[FlipEnum.ex_student]?.flip_url"
                                    class="ml-2 far fa-check-circle text-success"></i>
                            </div>
                        </div>
                        <div id="collapse_ex_student" class="collapse" data-parent="#accordionExt">
                            <div class="card-body">

                                <div class="mt-2 row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>{{'dropzone.imagem' | translate}}</label>
                                            <button *ngIf="!!books?.[FlipEnum.ex_student]?.nome_capa"
                                                (click)="onDeleteImage(FlipEnum.ex_student)" type="button"
                                                class="btn btn-icon btn-light-danger btn-hover-danger btn-sm ml-2 mb-2"
                                                triggers="mouseenter:mouseleave"
                                                [ngbPopover]="'geral.excluir' | translate">
                                                <span class="svg-icon svg-icon-md svg-icon-danger">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                        height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none"
                                                            fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path
                                                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                                fill="#000000" fill-rule="nonzero" />
                                                            <path
                                                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                                fill="#000000" opacity="0.3" />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </button>
                                            <gpe-upload-file-v2
                                                (onStatusChange)="onUploadCapa($event, FlipEnum.ex_student)"
                                                [config]="config" [link]="books?.[FlipEnum.ex_student]?.nome_capa"
                                                class="dz--wide wrap-dropzone w-100"
                                                [isInvalid]="isInvalid('file_' + FlipEnum.ex_student)"
                                                [titulo]="'dropzone.imagem' | translate">
                                            </gpe-upload-file-v2>
                                        </div>
                                    </div>

                                    <div class="col-8">
                                        <div class="alert alert-custom alert-notice alert-light-warning fade show"
                                            role="alert" *ngIf="isUploadFlipInvalid(FlipEnum.ex_student)">
                                            <div class="alert-icon">
                                                <i class="flaticon-warning"></i>
                                            </div>
                                            <div class="alert-text" translate>
                                                modulos.materiais-didaticos.msg-erro-upload-flip
                                            </div>
                                        </div>

                                        <ng-container *ngIf="isUploadFlipCompiling(FlipEnum.ex_student)">
                                            <table class="custom table table-striped m-table table-hover"
                                                *ngIf="!!books?.[FlipEnum.ex_student]?.title; else rederizando_ea">
                                                <thead class="m-datatable__head">
                                                    <tr>
                                                        <th class="text-center" width="20%" translate>geral.icone
                                                        </th>
                                                        <th class="text-left" width="60%" translate>geral.titulo
                                                        </th>
                                                        <th class="text-center" width="20%" translate>geral.acoes
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center text-nowrap">
                                                            <img class="m--margin-right-5 m--margin-left-5" alt="_image"
                                                                width="40" src="assets/images/files/zip.svg" />
                                                        </td>
                                                        <td class="text-left tdEllipsis align-middle">
                                                            <span placement="top"
                                                                [ngbPopover]="books?.[FlipEnum.ex_student]?.title"
                                                                triggers="mouseenter:mouseleave">
                                                                {{ books?.[FlipEnum.ex_student]?.title }} </span>
                                                        </td>
                                                        <td class="text-center text-nowrap align-middle">
                                                            <button type="button"
                                                                class="btn btn-icon btn-light btn-hover-danger btn-sm ml-3"
                                                                placement="top"
                                                                [ngbPopover]="'geral.excluir' | translate"
                                                                triggers="mouseenter:mouseleave"
                                                                (click)="onDeleteFlipTemp(FlipEnum.ex_student)">
                                                                <span class="svg-icon svg-icon-md svg-icon-danger">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px" height="24px" viewBox="0 0 24 24"
                                                                        version="1.1">
                                                                        <g stroke="none" stroke-width="1" fill="none"
                                                                            fill-rule="evenodd">
                                                                            <rect x="0" y="0" width="24" height="24">
                                                                            </rect>
                                                                            <path
                                                                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                                                fill="#000000" fill-rule="nonzero">
                                                                            </path>
                                                                            <path
                                                                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                                                fill="#000000" opacity="0.3"></path>
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <ng-template #rederizando_ea>
                                                <div class="alert alert-primary mb-5 p-5" role="alert">
                                                    <h4 class="alert-heading" translate>
                                                        modulos.materiais-didaticos.processando
                                                    </h4>
                                                    <p translate>
                                                        modulos.materiais-didaticos.msg-renderizando-upload-flip
                                                    </p>
                                                </div>
                                            </ng-template>
                                        </ng-container>

                                        <div [hidden]="!showUploadFlipInput(FlipEnum.ex_student)">
                                            <gpe-upload-file-v2 height="48"
                                                (onStatusChange)="onUploadFlip($event, FlipEnum.ex_student)"
                                                [config]="configFlip" class="wrap-dropzone dz-60h mt-8"
                                                [isInvalid]="isInvalid('file_flip_' + FlipEnum.ex_student)"
                                                titulo="Flip">
                                            </gpe-upload-file-v2>
                                        </div>
                                        <div *ngIf="isUploadFlipComplete(FlipEnum.ex_student)">
                                            <button (click)="onDeleteFlip(FlipEnum.ex_student)" type="button"
                                                class="btn btn-icon btn-light-danger btn-hover-danger btn-sm mb-2 float-right"
                                                style="margin-top: -13px;" triggers="mouseenter:mouseleave"
                                                [ngbPopover]="'geral.excluir' | translate">
                                                <span class="svg-icon svg-icon-md svg-icon-danger">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                        height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none"
                                                            fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path
                                                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                                fill="#000000" fill-rule="nonzero" />
                                                            <path
                                                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                                fill="#000000" opacity="0.3" />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </button>
                                            <button (click)="onOpenBookBlank(url_iframe?.[FlipEnum.ex_student])" type="button"
                                                class="btn btn-icon btn-light-info btn-hover-info btn-sm mb-2 mr-1 float-right"
                                                style="margin-top: -13px;" triggers="mouseenter:mouseleave"
                                                [ngbPopover]="'geral.visualizar-flip' | translate">
                                                <i class="fas fa-expand-alt"></i>
                                            </button>
                                            <iframe [src]="url_iframe?.[FlipEnum.ex_student] | safe : 'resourceUrl'" frameborder="0"
                                                style="width: 100%; height: 40vh;"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
