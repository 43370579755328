import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocalStorageService } from '@app/guards/storage.service';
import { Matrix } from '@shared/models';
import { MatrixService } from '@shared/service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'gpe-card-items-matrix',
    templateUrl: './card-items-matrix.component.html',
    providers: [
        MatrixService,
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => CardItemsMatrixComponent),
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardItemsMatrixComponent implements OnInit, ControlValueAccessor {
    public data$ = new BehaviorSubject<Matrix[]>([])
    private _stageId$ = new BehaviorSubject<number>(undefined)
    private _gradeId$ = new BehaviorSubject<number>(undefined)
    private _componentId$ = new BehaviorSubject<number>(undefined)
    private _matrixId$ = new BehaviorSubject<number>(undefined)
    private _exceptType$ = new BehaviorSubject<number>(undefined)

    constructor(
        private _matrixService: MatrixService,
        private _localStorageService: LocalStorageService
    ) {
        this.loadData()
    }

    ngOnInit(): void {
    }

    @Input('stageId') public set setStageId(stageId: number) {
        this._stageId$.next(stageId)
    }

    @Input('gradeId') public set setGradeId(value: number) {
        this._gradeId$.next(value)
    }
    @Input('componentId') public set setComponentId(value: number) {
        this._componentId$.next(value)
    }

    @Input('exceptType') public set setDidacticMaterial(value: number) {
        this._exceptType$.next(value)
    }

    @Input('matrixId') public set setMatrixId(value: number) {
        this._matrixId$.next(value)
    }

    private loadData() {
        const publishingId = this._localStorageService.storageUsuario?.country_selected.editoras[0].id
        combineLatest([this._stageId$, this._gradeId$, this._componentId$, this._exceptType$]).pipe(
                switchMap(([stageId, gradeId, componentId, exceptType]) => this._matrixService.index(publishingId, stageId, gradeId, componentId, exceptType)),
                map(res => res.ret === 1 ? res.data : []),
                tap(value => !value.length && this.onSelect())
        ).subscribe(value => {
            this.data$.next(value)
            if (!!this._matrixId$.value) {
                this.onSelect(value.find(m => m.id === this._matrixId$.value))
            }
        })
    }

    public writeValue(value: number) {}

    public registerOnChange(fn: (v: any) => void): void {
        this.onChange = fn
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn
    }

    public onSelect(matrix?: Matrix) {
        this.onTouched()
        this.onChange(matrix)
    }

    private onChange = (_: any) => { }
    private onTouched = () => { }
}
