<div *ngIf="isCategory" [ngClass]="{'chip': canRemove}" class="label label-light-{{ item.matrix.color_class }} label-inline h-30px align-baseline ml-1 cursor-pointer" #popover="ngbPopover" triggers="manual" (click)="onOpenPopover(popover)" [ngbPopover]="popTree">
    <button *ngIf="canRemove" type="button" (click)="onRemove()" class="btn btn-xs btn-icon btn-light-{{ item.matrix.color_class }} chip__acao">
        <span class="svg-icon svg-icon-md">
            <gpe-trash></gpe-trash>
        </span>
    </button>
    <span class="chip__label">
        {{ item.name }}
        <ng-container *ngIf="item.code">({{ item.code }})</ng-container>
        <ng-container *ngIf="categoryDescription as description">
            {{ description }}
        </ng-container>
    </span>
</div>

<div *ngIf="isSummary" [ngClass]="{'chip': canRemove}" class="label label-light-dark label-inline h-30px align-baseline ml-1 cursor-pointer" #popover="ngbPopover" triggers="manual" (click)="onOpenPopover(popover)" [ngbPopover]="popTree" [popoverTitle]="tree.tag">
    <button *ngIf="canRemove" type="button" (click)="onRemove()" class="btn btn-xs btn-icon btn-light-dark chip__acao">
        <span class="svg-icon svg-icon-md">
            <gpe-trash></gpe-trash>
        </span>
    </button>
    <span class="chip__label">
        <ng-container *ngIf="!!item.livro?.contextos?.length">
            <span *ngIf="!!item.livro.contextos[0].nivel">{{ item.livro.contextos[0].nivel.descricao }}</span>
            <span *ngIf="!!item.livro.contextos[0].padrao_serie"> - {{ item.livro.contextos[0].padrao_serie.descricao }}</span>
            <span *ngIf="!!item.livro.contextos[0].padrao_disciplina"> - {{ item.livro.contextos[0].padrao_disciplina.descricao }}</span>
            <span> - </span>
        </ng-container>
        {{ item.titulo }}
    </span>
</div>

<ng-template #popTree>
    <div *ngIf="loading$ | async; else treeDiv" class="mt-0 text-center p-15" style="height: 100px; width: 200px;">
        <span class="m-loader--success m-loader--right m-loader"></span>
    </div>
    <ng-template #treeDiv>
        <span class="font-weight-bolder font-size-lg">{{ tree.tag || tree.items[0]?.name }}</span>
        <hr />
        <div *ngIf="tree.items" class="d-flex gap-2 flex-wrap">
            <ng-container *ngFor="let item of tree.items; let last = last">
                <span class="d-table label label-inline" [ngClass]="onGetColorClass(last)">
                    {{ item.name }}
                </span>
                <div *ngIf="last && item.description" class="text-justify">
                    {{ item.description }}
                </div>
            </ng-container>
        </div>
    </ng-template>
</ng-template>
