<ng-container *ngIf="data$ | async as data">
	<div class="boxDocViewer" *ngIf="data.aberto">
		<div class="nomeArquivo">
			{{ data.params.nome }}
		</div>
		<button type="button" class="btn btn-info btn-sm" (click)="onClose()">
			{{ 'geral.fechar' | translate }}
		</button>
		<ngx-doc-viewer [url]="data.params.url" [viewer]="data.params.type || 'url'"></ngx-doc-viewer>
	</div>
</ng-container>
