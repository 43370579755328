export enum StatusUploadFlipBookEnum {
    Renderizando = 0,
    Renderizado = 1,
    ArquivoCorrompido = 2,
    ArquivoInvalido = 3,
    Erro = 4,
    ErroFila = 5
}

export function StatusUploadFlipErroEnum(): number[] {
    return [StatusUploadFlipBookEnum.ArquivoCorrompido, StatusUploadFlipBookEnum.ArquivoInvalido, StatusUploadFlipBookEnum.Erro, StatusUploadFlipBookEnum.ErroFila]
}
