const url = 'testecpb.com.br'
const domain = 'testecpb.com.br'
const protocolo = 'https://'
const login = `${protocolo}login.testecpb.com.br`

export const environment = {
    production: false,
    title: '[D] ',
    env: 'dev',
    s3_path_temp: `${protocolo}snv.${domain}/temp-upload-files/`,
    cpb_edu_path_temp: `${protocolo}s.cpbedu.me/temp-upload-files/`,
    url,
    domain,
    protocolo,
    login,
    api: {
        usuario_api: `${protocolo}api-usuario.testecpb.com.br/api/v1/`,
        usuario: `${protocolo}api-usuario.testecpb.com.br/api/`,
        voucher: `${protocolo}api-vouchers.testecpb.com.br/api/`,
        didaticos: `${protocolo}api-didaticos.testecpb.com.br/api/`,
        cron: `${protocolo}cron.testecpb.com.br/api/v1/`,
        permissao_api: `${protocolo}api-permissao.testecpb.com.br/api/v2/`,
        conteudos_api: `${protocolo}api-conteudos.testecpb.com.br/api/`,
        aulas_api: `${protocolo}api-aulas.testecpb.com.br/api/v1/`,
        vimeo_api: `${protocolo}api-vimeo.testecpb.com.br/api/v1/`,
        categories: `${protocolo}api-categories.testecpb.com.br/api/`,
        portal: `${protocolo}api-portal.testecpb.com.br/api/v1/`,
        bilingual_api: `${protocolo}api-bilingual.testecpb.com.br/api/v1/`,
        school_site_api: `${protocolo}api-school.testecpb.com.br/api/v1/`,
        path_s3: `https://s3-sa-east-1.amazonaws.com/s.testecpb.com.br/escola/`,
        path_s3_e_class: `https://s3-sa-east-1.amazonaws.com/s.testecpb.com.br/e_class/`,
        path_s3_snv_e_class: `https://snv.testecpb.com.br/e_class/`,
        path_s3_logotipo: `https://snv.testecpb.com.br/escola/logotipo/`,
    },
    web: {
        didaticos: `${protocolo}api-didaticos.testecpb.com.br/`,
        cron: `${protocolo}cron.testecpb.com.br/`,
        permissao: `${protocolo}api-permissao.testecpb.com.br/`,
        school: `${protocolo}api-school.testecpb.com.br/`
    },
    zoom: {
        client_id: 'ZrOhIawUR96sgqujFKSGww',
        redirect_uri: 'https://login.testecpb.com.br/zoom'
    }
};
