import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocalStorageService } from '@app/guards/storage.service';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { GradeService } from './grade.service';

@Component({
    selector: 'gpe-ng-select-grade',
    templateUrl: './ng-select-grade.component.html',
    providers: [
        GradeService,
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => NgSelectGradeComponent),
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgSelectGradeComponent implements OnInit, ControlValueAccessor {
    public form: FormGroup
    public data$ = new BehaviorSubject<any>([])
    private _stageId$ = new BehaviorSubject<number>(undefined)

    constructor(
        private _formBuilder: FormBuilder,
        private _gradeService: GradeService,
        private _localStorageService: LocalStorageService
    ) {
        this.initForm()
        this.loadData()
    }

    @Input('stageId') public set setStageId(stageId: number) {
        this._stageId$.next(stageId)
    }

    ngOnInit(): void {
    }

    private loadData() {
        const publishingId = this._localStorageService.storageUsuario?.country_selected.editoras[0].id
        this._stageId$.pipe(
                tap(() => this.reset()),
                switchMap(stageId => this._gradeService.index(publishingId, stageId)),
                map(res => res.ret === 1 ? res.data : []),
        ).subscribe(value => {
            this.data$.next(value)
            if (value.length == 1) {
                this.form.patchValue({id: value[0].id})
                this.onChange(this.form.value.id)
            }
        })
    }

    private initForm() {
        this.form = this._formBuilder.group({
            id: undefined
        })
    }

    private reset() {
        this.form.reset()
        this.onChange(undefined)
    }

    public writeValue(value: number) {
        this.form.patchValue({ id: value })
    }

    public registerOnChange(fn: (v: any) => void): void {
        this.onChange = fn
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn
    }

    public onSelect(value: any) {
        this.onTouched()
        this.onChange(value?.id)
    }

    private onChange = (_: any) => { }
    private onTouched = () => { }

    public groupByFn = (grade: any) => grade.stage.name;

    public groupValueFn = (_: string, grades: any[]) => ({ name: grades[0].stage.name, total: grades.length });
}
