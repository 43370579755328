import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface LoadingParams {
    text: string
}

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    private _visible$ = new BehaviorSubject<boolean>(false)
    private _params$ = new BehaviorSubject<LoadingParams>(undefined)

    public get visible$(): Observable<any> {
        return this._visible$.asObservable()
    }

    public get params$(): Observable<any> {
        return this._params$.asObservable()
    }

    public onToggle(state: boolean) {
        this._visible$.next(state)
    }

    public onToggleWithMessage(params: LoadingParams) {
        this._params$.next(params)
        this.onToggle(true)
    }
}
