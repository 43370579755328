import { Injectable } from '@angular/core';
import { viewerType } from 'ngx-doc-viewer';
import { BehaviorSubject, Observable } from 'rxjs';

export interface DocViewerParams {
	nome: string
	url: string
	type?: viewerType
	language?: string
}

@Injectable({
    providedIn: 'root'
})
export class DocViewerService {
    private _aberto$ = new BehaviorSubject<boolean>(false)
	private _params$ = new BehaviorSubject<DocViewerParams>(undefined)

	public get aberto$(): Observable<any> {
		return this._aberto$.asObservable()
	}

	public get params$(): Observable<any> {
		return this._params$.asObservable()
	}

	public onToggle(state: boolean) {
		this._aberto$.next(state)
	}

	public onOpen(params: DocViewerParams) {
		this._params$.next(params)
		this.onToggle(true)
	}
}
