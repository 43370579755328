import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class I18nService {

    constructor(private translate: TranslateService) { }

    public change(lang: string): Observable<any> {
        return this.translate.use(lang)
    }

    public get langChange$(): Observable<LangChangeEvent> {
        return this.translate.onLangChange.asObservable()
    }

    public get currentLang(): string {
        return this.translate.currentLang
    }
}
