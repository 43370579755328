import { AfterViewInit, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { AppComponent } from '@app/app.component';
import { LocalStorageService } from '@app/guards/storage.service';
import { LoadingService } from '@services/loading.service';
import { UsuarioService } from '@services/usuario.service';
import { entityType } from 'enums/entity.type.enum';
import { Observable, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';


@Component({
    selector: 'app-select-entidade',
    templateUrl: './select-entidade.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SelectEntidadeComponent),
        },
    ],
})
export class SelectEntidadeComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
    @Input() class: string = ""
    @Input() label: boolean = true
    @Input() all: boolean = false
    @Input() withoutExternal: boolean = false
    @Input() clearable: boolean = false
    public form: FormGroup
    public unidades$: Observable<any[]>
    private _destroy$ = new Subject()
    private _year: number = new Date().getFullYear()

    constructor(
        private _formBuilder: FormBuilder,
        private _loadingService: LoadingService,
        private _usuarioService: UsuarioService,
        private _storage: LocalStorageService,
        private appComponent: AppComponent,
    ) {
        this.initForm()
    }

    public onSelect(data) {
        if ([entityType.campo, entityType.uniao].includes(data.tipo)) {
            data = { ...data, ano_letivo: this._year }
        }
        this.onTouched()
        this.onChange(data)
    }

    public writeValue(value: number) {
        this.form.get('entidade_id').setValue(value)
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn
    }

    public ngAfterViewInit() {
        this.unidades$ = this.appComponent.country_changed.pipe(
            startWith([]),
            takeUntil(this._destroy$),
            tap(() => this._loadingService.onToggle(true)),
            switchMap(() => this._usuarioService.getEntities(this._storage.storageUsuario.country_selected.id, this.all, this.withoutExternal)),
            map(resp => {
                this._loadingService.onToggle(false)
                if (resp.data.length == 1) {
                    this.form.get('entidade_id').setValue(resp.data[0].id)
                }
                return resp.data
            })
        )
    }

    public ngOnDestroy() {
        this._destroy$.next()
        this._destroy$.complete()
    }

    private initForm(): void {
        this.form = this._formBuilder.group({
            entidade_id: [undefined, [Validators.required]]
        })
    }

    private onChange = (_: any) => { }

    private onTouched = () => { }
}
