import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { hubConst } from '@app/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SerieService {
    api: any = hubConst.api;

    constructor(private http: HttpClient) { }

    getSeries(entity_id): Observable<any> {
        return this.http.get<any>(`${this.api.usuario_api}sgi/serie?entidade_id=${entity_id}`).pipe(map(response => response));
    }

    getSeriesPadraoEntidade(arFilter) {
        let filter = ''
        if (arFilter != null) {
            filter = '?filter=' + JSON.stringify(arFilter)
        }

        return this.http.get<any>(`${this.api.usuario_api}hub-educacional/series-modelo${filter}`).pipe(map(response => response))

    }

    getSeriesEntidade(arFilter:any){
        let filter = ''
        if (arFilter != null) {
            filter = '?filter=' + JSON.stringify(arFilter)
        }

        return this.http.get<any>(`${this.api.usuario_api}hub-educacional/series${filter}`).pipe(map(response => response))
    }

    saveSeries(data:any){
        return this.http.post(`${this.api.usuario_api}hub-educacional/series`, data)
        .pipe(map(response => response))
    }
}
