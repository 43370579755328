export enum LoginURL {
    //educacaoadventista = 'https://login.eadv.org.br/',
    educacaoadventista = 'https://login.educacaoadventista.org.br/',
    cpbedu = 'https://login.cpbedu.me/',
    acessClass = 'https://login.acesclass.org/',
    sistemainterativo = 'https://login.sistemainterativo.com.br/',
    eaportal = 'https://login.eaportal.org/',
    testecpb = 'https://login.testecpb.com.br/',
    localhost = 'http://localhost/',
}
