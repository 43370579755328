import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadFileComponent } from './upload-file.component';
import { DropzoneConfigInterface, DropzoneModule, DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { hubConst } from '@app/core';

const api: any = hubConst.api

export const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
    url: api.usuario_api,
    acceptedFiles: 'image/*',
    createImageThumbnails: true
}

@NgModule({
    declarations: [
        UploadFileComponent
    ],
    imports: [
        CommonModule,
        DropzoneModule
    ],
    exports: [
        UploadFileComponent
    ],
    providers: [{
        provide: DROPZONE_CONFIG,
        useValue: DEFAULT_DROPZONE_CONFIG
    }]
})
export class UploadFileModule { }
