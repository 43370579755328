import { Observable } from 'rxjs';

import { ApiService, Resp } from './api.service';

export class CrudService<T> {
  constructor(protected api: ApiService, protected url: string) {}

  public index(req?: any): Observable<Resp<T[]>> {
    return this.api.get<T[]>(this.url, req);
  }

  public show(id: number|string): Observable<Resp<T>> {
    return this.api.get<T>(`${this.url}/${id}`);
  }

  public showBySlug(slug: string): Observable<Resp<T>> {
    return this.api.get<T>(`${this.url}/${slug}`);
  }

  public store(body: any): Observable<any> {
    return this.api.post(this.url, body);
  }

  public update(id:number|string, body: any): Observable<any> {
    return this.api.put(`${this.url}/${id}`, body);
  }

  public destroy(id: number|string): Observable<any> {
    return this.api.delete(`${this.url}/${id}`);
  }
}
