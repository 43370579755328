
import { environment } from '@env/environment'

export const hubConst = {
    ...environment,
    vimeo_url: 'https://player.vimeo.com/video/',
    cronaws: 'https://cronaws.educacaoadventista.org.br/',
    api: {
        ...environment.api,
        local: `${environment.protocolo}${environment.url}/`,
        login: environment.login,
        avaliacoes_api: `${environment.protocolo}api-avaliacoes.${environment.domain}/api/v1/hub/`,
        vouchers: `${environment.protocolo}api-vouchers.${environment.domain}/api/`,
        logout: `${environment.login}/logout`,
        login_api: `${environment.login}/api/v1/`
    },
    web: {
        ...environment.web
    }
};
