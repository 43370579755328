import { ComponentType, DomPortalOutlet } from "@angular/cdk/portal";
import { InjectionToken } from "@angular/core";
import { Observable, Subject } from "rxjs";

export const SIDENAV_CONFIG = new InjectionToken<any>('sidenavConfig');
export const SIDENAV_DATA = new InjectionToken<any>('SidenavData');

export interface SidenavServiceData<D> {
    side?: 'left' | 'right'
    title?: string,
    titleTranslated?: string,
    data?: D
}

export const DEFAULT_CONFIG: SidenavServiceData<any> = {
    side: 'left'
}

export abstract class SidenavLoader<T, D> {
    config?: SidenavServiceData<D>
    reference: ComponentType<T>
}

export class SideNavContext<R = any, T = unknown> {
    readonly afterClosed$: Observable<R>
    readonly host: DomPortalOutlet;
    readonly instance: T
    private readonly _closed$ = new Subject<R>();

    constructor() {
        this.afterClosed$ = this._closed$.asObservable();
    }

    close(result?: R): void {
        this._closed$.next(result);
        this._closed$.complete();
    }
}

export class SideNavRef<R = any, T = unknown> {
    readonly afterClosed$: Observable<R>
    private readonly context: SideNavContext<R, T>;

    get instance(): T {
        return this.context.instance
    }

    constructor(sideNavRef: SideNavContext<R, T>) {
        this.context = sideNavRef
        this.afterClosed$ = sideNavRef.afterClosed$
    }

    close(result?: R): void {
        this.context.close(result)
    }
}