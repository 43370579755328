import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { hubConst } from '@app/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { CrudService } from '../crud.service';

@Injectable({
  providedIn: 'root'
})
export class QuestaoService extends CrudService<any> {

    constructor(
        protected readonly api: ApiService,
        private http: HttpClient
    ) {
        super(api, `${hubConst.api.avaliacoes_api}questao`);
    }

    public checkVideo(resolucaoId: number): Observable<any> {
        return this.http.get(`${hubConst.api.avaliacoes_api}resolucao/${resolucaoId}/video`)
    }

    public questaoAplicada(uuid: string, params: any = {}): Observable<any> {
        return this.api.get(`${hubConst.api.avaliacoes_api}questao-aplicada/${uuid}`, params)
    }

    public destroyFromAvaliation(questaoUuid: string, avaliacaoUuid: string): Observable<any> {
        let params = `avaliacao_uuid=` + avaliacaoUuid
        return this.api.patch(`${hubConst.api.avaliacoes_api}questao/${questaoUuid}?${params}`)
    }

}
