import { Directive, Input, Optional } from '@angular/core';
import { SideNavRef } from './sidenav-ref';

@Directive({
  selector: '[appSidenavClose]',
  host: {
    '(click)': 'onClose()',
  },
})
export class SidenavDialogCloseDirective {

  @Input('appSidenavClose') public result: any;

  constructor(@Optional() private sideNavRef: SideNavRef) { }

  public onClose() {
    this.sideNavRef.close(this.result)
  }
}
