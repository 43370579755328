import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Resp } from '@services/api.service';
import { LoadingBlockData } from '@shared/loading-block/loading-block.component';
import { Category, Matrix } from '@shared/models';
import { Livro, Sumario } from '@shared/models/didactic-material';
import { Matrixtype, ModalSelectCategoriesActionsService, ModalSelectCategoriesOperations, ModalSelectCategoriesOrigin, ModalSelectCategoriesStateData, SumarioService } from '@shared/service';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, map, share, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CategoryService } from './category.service';

function startWithTap<T>(callback: () => void) {
    return (source: Observable<T>) =>
      of({}).pipe(tap(callback), switchMap((o) => source));
}

interface ItemData {
    type: Matrixtype
    items: Category[] | Sumario[]
}

@Component({
    selector: 'gpe-modal-select-categories',
    templateUrl: './modal-select-categories.component.html'
})
export class ModalSelectCategoriesComponent implements OnDestroy {
    public readonly matrixType = Matrixtype
    public form: FormGroup
    public state: ModalSelectCategoriesStateData
    public breadcrumb$ = new BehaviorSubject<Category[] | Sumario[]>([])
    public itemData$ = new BehaviorSubject<ItemData>(undefined)
    public matrixCtrl = new FormControl()
    public bookCtrl = new FormControl()
    public codeCtrl = new FormControl()
    private _parentId$ = new BehaviorSubject<number>(undefined)
    private _code$ = new BehaviorSubject<string>(undefined)
    private _modalRef: NgbModalRef
    private _destroy$ = new Subject()
    @ViewChild('modalCategories', {static: true}) private _modal: ElementRef

    private _loadingData$ = new BehaviorSubject<LoadingBlockData>(undefined)
    public loadingData$: Observable<LoadingBlockData>

    private readonly QUERY = {
		[Matrixtype.Category]: (data: any): Observable<Resp<Category[]>> => this._categoryService.index(data),
		[Matrixtype.DidacticMaterial]: (data: any): Observable<Resp<Sumario[]>> => this._summaryService.indexByStep(data.book_id, data.parent_id)
	}

    constructor(
        private _modalService: NgbModal,
        private _modalSelectCategoriesActionsService: ModalSelectCategoriesActionsService,
        private _formBuilder: FormBuilder,
        private _categoryService: CategoryService,
        private _summaryService: SumarioService
    ) {
        this.loadingData$ = this._loadingData$.pipe(share())

        this.initForm()
        this.loadListeners()

        this._modalSelectCategoriesActionsService.state$.pipe(
            takeUntil(this._destroy$),
            filter(value => !!value && value.open),
            tap(value => this.state = value),
            tap(() => this.reset()),
            tap(({options}) => !!options && this.form.patchValue(options)),
            tap(() =>
                this._modalSelectCategoriesActionsService.contexts$.pipe(
                    filter(value => !!value && !!value[0]),
                ).subscribe(resp => {
                    const component = resp[0].grade?.components?.find(c => {
                        return c.default_subject_id == resp[0].padrao_disciplina_id
                    })

                    this.form.patchValue({
                        stage_id: !!resp[0].nivel_id ? resp[0].nivel_id : undefined,
                        grade_id: !!resp[0].grade?.id ? resp[0].grade?.id: undefined,
                        component_id: !!component?.id ? component.id : undefined,
                    }
                    , { emitEvent: false }
                    )
                })
            )
        ).subscribe(this.openModal)
    }

    public get itemsMerged(): Array<Category | Sumario> {
        return [...this.state.items.categories, ...this.state.items.summaries]
    }

    public get exceptMatrixType() {
        return [ModalSelectCategoriesOrigin.Summary, ModalSelectCategoriesOrigin.Content].includes(this.state.origin) ? Matrixtype.DidacticMaterial : undefined
    }

    public get matrix(): Matrix {
        return this.matrixCtrl.value
    }

    ngOnDestroy(): void {
        this._destroy$.next()
        this._destroy$.complete()
        this.breadcrumb$.complete()
        this.itemData$.complete()
        this._parentId$.complete()
        this._code$.complete()
        this._loadingData$.complete()
    }

    private initForm() {
        this.form = this._formBuilder.group({
            stage_id: undefined,
            grade_id: undefined,
            component_id: undefined
        })
    }

    private loadListeners() {
        // when clean search by code, set categoryId to null to reset the list
        this.codeCtrl.valueChanges.subscribe(value => !value && this._code$.next(undefined))

        // changes on filters, reset the list
        const stageId$ = this.form.controls.stage_id.valueChanges.pipe(tap(() => this.form.controls.grade_id.setValue(undefined)), tap(() => this.onResetNavigationMatrixList()))
        const gradeId$ = this.form.controls.grade_id.valueChanges.pipe(tap(() => this.form.controls.component_id.setValue(undefined)), tap(() => this.onResetNavigationMatrixList()))
        const componentId$ = this.form.controls.component_id.valueChanges.pipe(startWith(undefined), tap(() => this.onResetNavigationMatrixList()))
        const matrixId$ = this.matrixCtrl.valueChanges.pipe(startWith(undefined), tap(this.clearParentId), map((matrix: Matrix) => matrix?.id))
        const bookId$ = this.bookCtrl.valueChanges.pipe(startWith(undefined), tap(this.clearParentId), map((book: Livro) => book?.id))

        combineLatest([matrixId$, stageId$, gradeId$, componentId$, this._parentId$, this._code$, bookId$]).pipe(
            debounceTime(200),
            takeUntil(this._destroy$),
            distinctUntilChanged(),
            map(([matrix_id, stage_id, grade_id, component_id, parent_id, code, book_id]) => ({matrix_id, stage_id, grade_id, component_id, parent_id, code, book_id})),
            tap(() => this.itemData$.next(undefined)),
            filter(({matrix_id, code, book_id}) => (!!matrix_id && this.matrix.type == Matrixtype.Category) || (!!matrix_id && this.matrix.type == Matrixtype.DidacticMaterial && !!book_id) || !!code),
            startWithTap(() => this._loadingData$.next({ display: true })),
            switchMap(data => this.QUERY[this.matrix.type](data).pipe(finalize(() => this._loadingData$.next({ display: false })))),
            map((res: Resp<Sumario[] | Category[]>) => res?.ret == 1 ? res.data : []),
            map(items => ModalSelectCategoriesOrigin.FilterQuestion == this.state.origin ? items.map(i => ({...i, selectable: true})) : items)
        ).subscribe((items: Category[] | Sumario[])  => this.itemData$.next({type: this.matrix.type, items}))
    }

    private clearParentId = () => this._parentId$.next(undefined)

    public onSearchByCode() {
        this.itemData$.next(undefined)
        this.breadcrumb$.next([])
        this._code$.next(this.codeCtrl.value)
    }

    private openModal = () => {
        this._loadingData$.next({display: false})
        this._modalRef = this._modalService.open(this._modal, {
            centered: true,
            size: 'xl',
            backdrop: 'static',
            keyboard: false,
            scrollable: true
        })
    }

    private onClose() {
        this._modalSelectCategoriesActionsService.onClose(this.state.origin)
        this._modalRef.close()
    }

    private reset() {
        this.matrixCtrl.setValue(undefined)
        this.bookCtrl.setValue(undefined)
        this.form.reset()
        this.breadcrumb$.next([])
    }

    public onToggleCategory(item: any, breadcrumbClick: boolean = false) {
        this._parentId$.next(item.id)
        if (breadcrumbClick) {
            this.breadcrumb$.next(this.breadcrumb$.value.slice(0, this.breadcrumb$.value.indexOf(item)+1))
        } else {
            this.breadcrumb$.next([...this.breadcrumb$.value, item])
        }
    }

    public onResetNavigationList() {
        this.matrixCtrl.setValue(undefined)
        this.onResetNavigationMatrixList()
    }

    public onResetNavigationMatrixList() {
        this.codeCtrl.setValue(undefined)
        this.bookCtrl.setValue(undefined)
        this.onResetNavigationCategoryList()
    }

    public onResetNavigationCategoryList() {
        this._parentId$.next(undefined)
        this.breadcrumb$.next([])
    }

    public onSelect(item: Category | Sumario) {
        if (this.matrix.type == Matrixtype.Category) {
            this.state.items.categories = [...this.state.items.categories, item as Category]
        } else {
            this.state.items.summaries = [...this.state.items.summaries, item as Sumario]
        }
        this._modalSelectCategoriesActionsService.setData({
            origin: this.state.origin,
            origin_id: this.state.origin_id,
            matrix_type: this.matrix.type,
            item,
            operation: ModalSelectCategoriesOperations.Append
        })
    }

    public onRemove(item: Category | Sumario) {
        const field: string = (<Category>item).matrix?.type == Matrixtype.Category ? 'categories' : 'summaries'
        this.state.items[field] = this.state.items[field].filter(c => c.id !== item.id)

        this._modalSelectCategoriesActionsService.setData({
            origin: this.state.origin,
            origin_id: this.state.origin_id,
            matrix_type: this.matrix?.type || (<Category>item).matrix?.type || Matrixtype.DidacticMaterial,
            item,
            operation: ModalSelectCategoriesOperations.Remove
        })
    }

    public categoryAlreadyAdded(item: Category | Sumario): boolean {
        const field: string = this.matrix.type == Matrixtype.Category ? 'categories' : 'summaries'
        return !!this.state.items[field].filter(c => c.id == item.id).length
    }
}
