import { PortalModule } from '@angular/cdk/portal';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SvgModule } from '@app/svg/svg.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ServiceModule } from '@services/service.module';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { ToastrModule } from 'ngx-toastr';

import { DocViewerComponent } from './components/doc-viewer/doc-viewer.component';
import { LoadingComponent } from './loading/loading.component';
import { I18nService } from './service/i18n.service';
import { NotificationService } from './service/notification.service';

@NgModule({
    declarations: [LoadingComponent, DocViewerComponent,],
    imports: [
        CommonModule,
        SvgModule,
        NgxDocViewerModule,
        BrowserAnimationsModule,
        ServiceModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-center',
            preventDuplicates: false
        }),
        TranslateModule.forRoot({
            useDefaultLang: true,
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
                deps: [HttpClient],
            },
        }),
        PortalModule,
    ],
    providers: [
        NotificationService,
        I18nService,
        DatePipe,
        {
            provide: LOCALE_ID,
            useFactory: (translate: TranslateService) => translate.currentLang || 'pt-BR',
            deps: [TranslateService],
        },
    ],
    exports: [ToastrModule, TranslateModule, LoadingComponent, DocViewerComponent]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import CoreModule in the AppModule only.')
        }
    }
}
