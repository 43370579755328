import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { hubConst } from '@app/core';
import { Editora, PaisStorage } from '@app/models';
import { head } from 'lodash';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CryptService } from './crypt.service';

@Injectable()
export class LocalStorageService {
    private _storage$ = new BehaviorSubject<any>(undefined)
    api: any = hubConst.api
    dependente_padrao

    constructor(private http: HttpClient, @Inject(LOCAL_STORAGE) private storage: StorageService, private cryptService: CryptService) { }

    public get storage$(): Observable<any>{
        return this._storage$.asObservable().pipe(map(storage => !!storage?.uuid ? storage : this.storageUsuario))
    }

    async setStorage(key: string, val: any) {
        val = this.cryptService.encrypt(val)
        return await this.storage.set(key, val)
    }

    getStorage(key: string) {
        try {
            const value = this.storage.get(key);
            return !!value && this.cryptService.decrypt(value)
        } catch (e) {
            // Tratamento para deslogar quem esta  sem storage criptografada
            this.removeStorage(key)
            window.location.href = this.api.login + '?l=' + btoa(this.api.local)
        }
    }

    public get currentCountry(): PaisStorage | null {
        return this.storageUsuario?.country_selected
    }

    public get currentPublisher(): Editora | null {
        return head(this.currentCountry?.editoras || [])
    }

    public get storageUsuario() {
        return this.getStorage('usuario')
    }

    public setStorageUsuario(data) {
        this._storage$.next(data);
        this.setStorage('usuario', data)
    }

    removeStorage(key: string): void {
        return this.storage.remove(key)
    }

    async getRefreshToken(token: any): Promise<any> {
        return await this.http.post(`${this.api.login_api}atualiza-token`, {}, {}).toPromise()
    }

    getUpdateToken(token: any, data: any): Promise<any> {
        return this.http.post(`${this.api.login_api}logar-como`, data, {}).toPromise()
    }

    getTemporaryToken(): Observable<any> {
        return this.http.post(`${this.api.login_api}generate-temporary-token`, {}, {}).pipe(take(1));
    }

    async getDadosUsuario(token: any) {
        return await this.http.get(`${this.api.usuario_api}hub-educacional/dados-usuario`, {}).toPromise()
    }
}
