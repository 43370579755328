<div class="boxLoading" *ngIf="loading">
    <div class="sk-circle">
        <div class="sk-circle1 sk-child"></div>
        <div class="sk-circle2 sk-child"></div>
        <div class="sk-circle3 sk-child"></div>
        <div class="sk-circle4 sk-child"></div>
        <div class="sk-circle5 sk-child"></div>
        <div class="sk-circle6 sk-child"></div>
        <div class="sk-circle7 sk-child"></div>
        <div class="sk-circle8 sk-child"></div>
        <div class="sk-circle9 sk-child"></div>
        <div class="sk-circle10 sk-child"></div>
        <div class="sk-circle11 sk-child"></div>
        <div class="sk-circle12 sk-child"></div>
    </div>
    <p>{{ loadingText }}</p>
</div>

<gpe-loading></gpe-loading>

<div
    id="kt_header_mobile"
    class="header-mobile align-items-center header-mobile-fixed"
>
    <a [routerLink]="['/']">
        <img
            src="assets/svg/logo-hub.svg"
            alt="HUB Educacional"
            style="width: 30px"
        />
    </a>
    <div class="d-flex align-items-center">
        <button class="btn p-0 burger-icon ml-4" id="kt_header_mobile_toggle">
            <span></span>
        </button>
        <button
            class="btn btn-hover-text-primary p-0 ml-2"
            id="kt_header_mobile_topbar_toggle"
        >
            <span class="svg-icon svg-icon-xl">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                >
                    <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                    >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                            d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                            opacity="0.3"
                        />
                        <path
                            d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                        />
                    </g>
                </svg>
            </span>
        </button>
    </div>
</div>

<div class="d-flex flex-column flex-root">
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <div id="kt_header" class="header header-fixed">
                <div
                    class="container-fluid d-flex align-items-stretch justify-content-between"
                >
                    <div
                        class="header-menu-wrapper header-menu-wrapper-left"
                        id="kt_header_menu_wrapper"
                    >
                        <a [routerLink]="['/']" class="brand-logo row">
                            <img
                                src="assets/svg/logo-hub.svg"
                                alt="HUB Educacional"
                                style="width: 25px"
                                class="mr-3"
                            />
                            <span class="d-none d-xxl-block"
                                >HUB Educacional</span
                            >
                        </a>
                        <div
                            id="kt_header_menu"
                            class="header-menu header-menu-mobile header-menu-layout-default"
                        >
                            <ul class="menu-nav">
                                <li
                                    *ngIf="isTecnicoAdministrativo$ | async"
                                    [ngClass]="validPageActivated('entidades')"
                                    class="menu-item menu-item-submenu menu-item-rel"
                                    data-menu-toggle="hover"
                                    aria-haspopup="true"
                                >
                                    <a
                                        href="javascript:;"
                                        class="menu-link menu-toggle"
                                    >
                                        <span class="svg-icon menu-icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                width="24px"
                                                height="24px"
                                                viewBox="0 0 24 24"
                                                version="1.1"
                                            >
                                                <g
                                                    stroke="none"
                                                    stroke-width="1"
                                                    fill="none"
                                                    fill-rule="evenodd"
                                                >
                                                    <rect
                                                        x="0"
                                                        y="0"
                                                        width="24"
                                                        height="24"
                                                    />
                                                    <path
                                                        d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z"
                                                        fill="#000000"
                                                    />
                                                    <rect
                                                        fill="#FFFFFF"
                                                        x="13"
                                                        y="8"
                                                        width="3"
                                                        height="3"
                                                        rx="1"
                                                    />
                                                    <path
                                                        d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z"
                                                        fill="#000000"
                                                        opacity="0.3"
                                                    />
                                                </g>
                                            </svg>
                                        </span>
                                        <span class="menu-text">{{
                                            "menu.entidades" | translate
                                        }}</span>
                                        <i class="menu-arrow"></i>
                                    </a>
                                    <div
                                        class="menu-submenu menu-submenu-classic menu-submenu-left"
                                    >
                                        <ul class="menu-subnav">
                                            <li
                                                class="menu-item"
                                                aria-haspopup="true"
                                            >
                                                <a
                                                    [routerLink]="[
                                                        '/entidades'
                                                    ]"
                                                    class="menu-link"
                                                >
                                                    <span
                                                        class="svg-icon menu-icon"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <polygon
                                                                    points="0 0 24 0 24 24 0 24"
                                                                />
                                                                <path
                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                    fill="#000000"
                                                                    fill-rule="nonzero"
                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="menu-text">{{
                                                        "menu.unidades"
                                                            | translate
                                                    }}</span>
                                                </a>
                                            </li>
                                            <li
                                                *ngIf="
                                                    isUserCampo || isUserUniao
                                                "
                                                class="menu-item"
                                                aria-haspopup="true"
                                            >
                                                <a
                                                    [routerLink]="[
                                                        '/entidades/campos'
                                                    ]"
                                                    class="menu-link"
                                                >
                                                    <span
                                                        class="svg-icon menu-icon"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <polygon
                                                                    points="0 0 24 0 24 24 0 24"
                                                                />
                                                                <path
                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                    fill="#000000"
                                                                    fill-rule="nonzero"
                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="menu-text">{{
                                                        "menu.campos"
                                                            | translate
                                                    }}</span>
                                                </a>
                                            </li>
                                            <li
                                                *ngIf="isUserUniao"
                                                class="menu-item"
                                                aria-haspopup="true"
                                            >
                                                <a
                                                    [routerLink]="[
                                                        '/entidades/unioes'
                                                    ]"
                                                    class="menu-link"
                                                >
                                                    <span
                                                        class="svg-icon menu-icon"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <polygon
                                                                    points="0 0 24 0 24 24 0 24"
                                                                />
                                                                <path
                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                    fill="#000000"
                                                                    fill-rule="nonzero"
                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="menu-text">{{
                                                        "menu.unioes"
                                                            | translate
                                                    }}</span>
                                                </a>
                                            </li>
                                            <li
                                                *ngIf="
                                                    isAtendimentoPortal$ | async
                                                "
                                                class="menu-item"
                                                aria-haspopup="true"
                                            >
                                                <a
                                                    [routerLink]="[
                                                        '/entidades/editoras'
                                                    ]"
                                                    class="menu-link"
                                                >
                                                    <span
                                                        class="svg-icon menu-icon"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <polygon
                                                                    points="0 0 24 0 24 24 0 24"
                                                                />
                                                                <path
                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                    fill="#000000"
                                                                    fill-rule="nonzero"
                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="menu-text">{{
                                                        "menu.editoras"
                                                            | translate
                                                    }}</span>
                                                </a>
                                            </li>
                                            <li
                                                *ngIf="
                                                    isAtendimentoPortal$ | async
                                                "
                                                class="menu-item"
                                                aria-haspopup="true"
                                            >
                                                <a
                                                    [routerLink]="[
                                                        '/entidades/divisoes'
                                                    ]"
                                                    class="menu-link"
                                                >
                                                    <span
                                                        class="svg-icon menu-icon"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <polygon
                                                                    points="0 0 24 0 24 24 0 24"
                                                                />
                                                                <path
                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                    fill="#000000"
                                                                    fill-rule="nonzero"
                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="menu-text">{{
                                                        "menu.divisoes"
                                                            | translate
                                                    }}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li
                                    *ngIf="
                                        isTecnicoAdmRepresentanteComercial$
                                            | async
                                    "
                                    [ngClass]="validPageActivated('usuarios')"
                                    class="menu-item menu-item-submenu menu-item-rel"
                                    data-menu-toggle="hover"
                                    aria-haspopup="true"
                                >
                                    <a
                                        [routerLink]="['/usuarios']"
                                        class="menu-link menu-toggle"
                                    >
                                        <span class="svg-icon menu-icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                width="24px"
                                                height="24px"
                                                viewBox="0 0 24 24"
                                                version="1.1"
                                            >
                                                <g
                                                    stroke="none"
                                                    stroke-width="1"
                                                    fill="none"
                                                    fill-rule="evenodd"
                                                >
                                                    <polygon
                                                        points="0 0 24 0 24 24 0 24"
                                                    />
                                                    <path
                                                        d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                                                        fill="#000000"
                                                        fill-rule="nonzero"
                                                        opacity="0.3"
                                                    />
                                                    <path
                                                        d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                                                        fill="#000000"
                                                        fill-rule="nonzero"
                                                    />
                                                </g>
                                            </svg>
                                        </span>
                                        <span class="menu-text">{{
                                            "menu.usuarios" | translate
                                        }}</span>
                                        <i class="menu-arrow"></i>
                                    </a>
                                    <div
                                        class="menu-submenu menu-submenu-classic menu-submenu-left"
                                    >
                                        <ul class="menu-subnav">
                                            <li
                                                class="menu-item"
                                                aria-haspopup="true"
                                            >
                                                <a
                                                    [routerLink]="[
                                                        '/usuarios/lista/tipo/administrativo'
                                                    ]"
                                                    class="menu-link"
                                                >
                                                    <span
                                                        class="svg-icon menu-icon"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <polygon
                                                                    points="0 0 24 0 24 24 0 24"
                                                                />
                                                                <path
                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                    fill="#000000"
                                                                    fill-rule="nonzero"
                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="menu-text">{{
                                                        "modulos.dashboard.administrativos"
                                                            | translate
                                                    }}</span>
                                                </a>
                                            </li>
                                            <li
                                                class="menu-item"
                                                aria-haspopup="true"
                                            >
                                                <a
                                                    [routerLink]="[
                                                        '/usuarios/lista/tipo/aluno'
                                                    ]"
                                                    class="menu-link"
                                                >
                                                    <span
                                                        class="svg-icon menu-icon"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <polygon
                                                                    points="0 0 24 0 24 24 0 24"
                                                                />
                                                                <path
                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                    fill="#000000"
                                                                    fill-rule="nonzero"
                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="menu-text">{{
                                                        "modulos.dashboard.alunos"
                                                            | translate
                                                    }}</span>
                                                </a>
                                            </li>
                                            <li
                                                class="menu-item"
                                                aria-haspopup="true"
                                            >
                                                <a
                                                    [routerLink]="[
                                                        '/usuarios/lista/tipo/apoio'
                                                    ]"
                                                    class="menu-link"
                                                >
                                                    <span
                                                        class="svg-icon menu-icon"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <polygon
                                                                    points="0 0 24 0 24 24 0 24"
                                                                />
                                                                <path
                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                    fill="#000000"
                                                                    fill-rule="nonzero"
                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="menu-text">{{
                                                        "modulos.dashboard.apoio-escolar"
                                                            | translate
                                                    }}</span>
                                                </a>
                                            </li>
                                            <li
                                                class="menu-item"
                                                aria-haspopup="true"
                                            >
                                                <a
                                                    [routerLink]="[
                                                        '/usuarios/lista/tipo/professor'
                                                    ]"
                                                    class="menu-link"
                                                >
                                                    <span
                                                        class="svg-icon menu-icon"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <polygon
                                                                    points="0 0 24 0 24 24 0 24"
                                                                />
                                                                <path
                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                    fill="#000000"
                                                                    fill-rule="nonzero"
                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="menu-text">{{
                                                        "modulos.dashboard.professores"
                                                            | translate
                                                    }}</span>
                                                </a>
                                            </li>
                                            <li
                                                class="menu-item"
                                                aria-haspopup="true"
                                            >
                                                <a
                                                    [routerLink]="[
                                                        '/usuarios/lista/tipo/responsavel'
                                                    ]"
                                                    class="menu-link"
                                                >
                                                    <span
                                                        class="svg-icon menu-icon"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <polygon
                                                                    points="0 0 24 0 24 24 0 24"
                                                                />
                                                                <path
                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                    fill="#000000"
                                                                    fill-rule="nonzero"
                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="menu-text">{{
                                                        "modulos.dashboard.responsaveis"
                                                            | translate
                                                    }}</span>
                                                </a>
                                            </li>
                                            <li
                                                class="menu-item"
                                                aria-haspopup="true"
                                            >
                                                <a
                                                    [routerLink]="[
                                                        '/usuarios/lista/tipo/sem-funcao'
                                                    ]"
                                                    class="menu-link"
                                                >
                                                    <span
                                                        class="svg-icon menu-icon"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <polygon
                                                                    points="0 0 24 0 24 24 0 24"
                                                                />
                                                                <path
                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                    fill="#000000"
                                                                    fill-rule="nonzero"
                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="menu-text">{{
                                                        "geral.sem-funcoes"
                                                            | translate
                                                    }}</span>
                                                </a>
                                            </li>
                                            <li
                                                class="menu-item"
                                                aria-haspopup="true"
                                            >
                                                <a
                                                    [routerLink]="[
                                                        '/usuarios/lista/tipo/tecnico'
                                                    ]"
                                                    class="menu-link"
                                                >
                                                    <span
                                                        class="svg-icon menu-icon"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <polygon
                                                                    points="0 0 24 0 24 24 0 24"
                                                                />
                                                                <path
                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                    fill="#000000"
                                                                    fill-rule="nonzero"
                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="menu-text">{{
                                                        "modulos.dashboard.tecnico-informatica"
                                                            | translate
                                                    }}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li
                                    *ngIf="isTecnicoAdmOrAdvisor$ | async"
                                    [ngClass]="validPageActivated('bilingue')"
                                    class="menu-item menu-item-submenu menu-item-rel"
                                    data-menu-toggle="hover"
                                    aria-haspopup="true"
                                >
                                    <a
                                        href="javascript:;"
                                        class="menu-link menu-toggle"
                                    >
                                        <span
                                            class="svg-icon menu-icon svg-icon-2x mr-1"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                width="24px"
                                                height="24px"
                                                viewBox="0 0 24 24"
                                                version="1.1"
                                            >
                                                <g
                                                    stroke="none"
                                                    stroke-width="1"
                                                    fill="none"
                                                    fill-rule="evenodd"
                                                >
                                                    <rect
                                                        x="0"
                                                        y="0"
                                                        width="24"
                                                        height="24"
                                                    />
                                                    <path
                                                        d="M13,18.9450712 L13,20 L14,20 C15.1045695,20 16,20.8954305 16,22 L8,22 C8,20.8954305 8.8954305,20 10,20 L11,20 L11,18.9448245 C9.02872877,18.7261967 7.20827378,17.866394 5.79372555,16.5182701 L4.73856106,17.6741866 C4.36621808,18.0820826 3.73370941,18.110904 3.32581341,17.7385611 C2.9179174,17.3662181 2.88909597,16.7337094 3.26143894,16.3258134 L5.04940685,14.367122 C5.46150313,13.9156769 6.17860937,13.9363085 6.56406875,14.4106998 C7.88623094,16.037907 9.86320756,17 12,17 C15.8659932,17 19,13.8659932 19,10 C19,7.73468744 17.9175842,5.65198725 16.1214335,4.34123851 C15.6753081,4.01567657 15.5775721,3.39010038 15.903134,2.94397499 C16.228696,2.49784959 16.8542722,2.4001136 17.3003976,2.72567554 C19.6071362,4.40902808 21,7.08906798 21,10 C21,14.6325537 17.4999505,18.4476269 13,18.9450712 Z"
                                                        fill="#000000"
                                                        fill-rule="nonzero"
                                                    />
                                                    <circle
                                                        fill="#000000"
                                                        opacity="0.3"
                                                        cx="12"
                                                        cy="10"
                                                        r="6"
                                                    />
                                                </g>
                                            </svg>
                                        </span>
                                        <span class="menu-text">{{
                                            "menu.bilingue" | translate
                                        }}</span>
                                        <i class="menu-arrow"></i>
                                    </a>
                                    <div
                                        class="menu-submenu menu-submenu-classic menu-submenu-left"
                                    >
                                        <ul class="menu-subnav">
                                            <li
                                                class="menu-item"
                                                aria-haspopup="true"
                                            >
                                                <a
                                                    [routerLink]="[
                                                        '/bilingue/relatorio-observacoes'
                                                    ]"
                                                    class="menu-link"
                                                >
                                                    <span
                                                        class="svg-icon menu-icon"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <polygon
                                                                    points="0 0 24 0 24 24 0 24"
                                                                />
                                                                <path
                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                    fill="#000000"
                                                                    fill-rule="nonzero"
                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="menu-text">
                                                        {{
                                                            "modulos.bilingue.relatorio-observacoes"
                                                                | translate
                                                        }}
                                                    </span>
                                                </a>
                                            </li>
                                            <li
                                                class="menu-item"
                                                aria-haspopup="true"
                                            >
                                                <a
                                                    [routerLink]="[
                                                        '/bilingue/relatorio-atendimento'
                                                    ]"
                                                    class="menu-link"
                                                >
                                                    <span
                                                        class="svg-icon menu-icon"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <polygon
                                                                    points="0 0 24 0 24 24 0 24"
                                                                />
                                                                <path
                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                    fill="#000000"
                                                                    fill-rule="nonzero"
                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="menu-text">
                                                        {{
                                                            "modulos.bilingue.relatorio-atendimento"
                                                                | translate
                                                        }}
                                                    </span>
                                                </a>
                                            </li>
                                            <li
                                                *ngIf="
                                                    isRepresentanteBilingue$
                                                        | async
                                                "
                                                class="menu-item"
                                                aria-haspopup="true"
                                            >
                                                <a
                                                    [routerLink]="[
                                                        '/bilingue/contatos-gestores'
                                                    ]"
                                                    class="menu-link"
                                                >
                                                    <span
                                                        class="svg-icon menu-icon"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <polygon
                                                                    points="0 0 24 0 24 24 0 24"
                                                                />
                                                                <path
                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                    fill="#000000"
                                                                    fill-rule="nonzero"
                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="menu-text">
                                                        {{
                                                            "modulos.bilingue.contatos-gestores"
                                                                | translate
                                                        }}
                                                    </span>
                                                </a>
                                            </li>
                                            <li
                                                *ngIf="
                                                    isRepresentanteBilingue$
                                                        | async
                                                "
                                                class="menu-item"
                                                aria-haspopup="true"
                                            >
                                                <a
                                                    [routerLink]="[
                                                        '/bilingue/contatos-professores'
                                                    ]"
                                                    class="menu-link"
                                                >
                                                    <span
                                                        class="svg-icon menu-icon"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <polygon
                                                                    points="0 0 24 0 24 24 0 24"
                                                                />
                                                                <path
                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                    fill="#000000"
                                                                    fill-rule="nonzero"
                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="menu-text">
                                                        {{
                                                            "modulos.bilingue.contatos-professores"
                                                                | translate
                                                        }}
                                                    </span>
                                                </a>
                                            </li>
                                            <li
                                                *ngIf="
                                                    isRepresentanteBilingue$
                                                        | async
                                                "
                                                class="menu-item"
                                                aria-haspopup="true"
                                            >
                                                <a
                                                    [routerLink]="[
                                                        '/bilingue/sky-english'
                                                    ]"
                                                    class="menu-link"
                                                >
                                                    <span
                                                        class="svg-icon menu-icon"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <polygon
                                                                    points="0 0 24 0 24 24 0 24"
                                                                />
                                                                <path
                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                    fill="#000000"
                                                                    fill-rule="nonzero"
                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span
                                                        class="menu-text"
                                                        translate
                                                    >
                                                        modulos.bilingue.sky-english.titulo
                                                    </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li
                                    *ngIf="
                                        isTecnicoAdmRepresentanteComercial$
                                            | async
                                    "
                                    [ngClass]="
                                        validPageActivated(
                                            'sincronizacao',
                                            'vouchers'
                                        )
                                    "
                                    class="menu-item menu-item-submenu menu-item-rel"
                                    data-menu-toggle="hover"
                                    aria-haspopup="true"
                                >
                                    <a
                                        href="javascript:;"
                                        class="menu-link menu-toggle"
                                    >
                                        <span class="svg-icon menu-icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                width="24px"
                                                height="24px"
                                                viewBox="0 0 24 24"
                                                version="1.1"
                                            >
                                                <g
                                                    stroke="none"
                                                    stroke-width="1"
                                                    fill="none"
                                                    fill-rule="evenodd"
                                                >
                                                    <rect
                                                        x="0"
                                                        y="0"
                                                        width="24"
                                                        height="24"
                                                    />
                                                    <path
                                                        d="M4.875,20.75 C4.63541667,20.75 4.39583333,20.6541667 4.20416667,20.4625 L2.2875,18.5458333 C1.90416667,18.1625 1.90416667,17.5875 2.2875,17.2041667 C2.67083333,16.8208333 3.29375,16.8208333 3.62916667,17.2041667 L4.875,18.45 L8.0375,15.2875 C8.42083333,14.9041667 8.99583333,14.9041667 9.37916667,15.2875 C9.7625,15.6708333 9.7625,16.2458333 9.37916667,16.6291667 L5.54583333,20.4625 C5.35416667,20.6541667 5.11458333,20.75 4.875,20.75 Z"
                                                        fill="#000000"
                                                        fill-rule="nonzero"
                                                        opacity="0.3"
                                                    />
                                                    <path
                                                        d="M2,11.8650466 L2,6 C2,4.34314575 3.34314575,3 5,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L12.9835977,18 C12.7263047,14.0909841 9.47412135,11 5.5,11 C4.23590829,11 3.04485894,11.3127315 2,11.8650466 Z M6,7 C5.44771525,7 5,7.44771525 5,8 C5,8.55228475 5.44771525,9 6,9 L15,9 C15.5522847,9 16,8.55228475 16,8 C16,7.44771525 15.5522847,7 15,7 L6,7 Z"
                                                        fill="#000000"
                                                    />
                                                </g>
                                            </svg>
                                        </span>
                                        <span class="menu-text">{{
                                            "geral.gestao" | translate
                                        }}</span>
                                        <i class="menu-arrow"></i>
                                    </a>
                                    <div
                                        class="menu-submenu menu-submenu-classic menu-submenu-left"
                                    >
                                        <ul class="menu-subnav">
                                            <li
                                                *ngIf="
                                                    (isTecnicoAdministrativo$
                                                        | async) &&
                                                    (canSync$ | async)
                                                "
                                                class="menu-item"
                                                aria-haspopup="true"
                                            >
                                                <a
                                                    [routerLink]="[
                                                        '/gestao/sincronizacao'
                                                    ]"
                                                    class="menu-link"
                                                >
                                                    <span
                                                        class="svg-icon menu-icon"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <polygon
                                                                    points="0 0 24 0 24 24 0 24"
                                                                />
                                                                <path
                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                    fill="#000000"
                                                                    fill-rule="nonzero"
                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="menu-text">
                                                        {{
                                                            "menu.sincronizacao"
                                                                | translate
                                                        }}
                                                    </span>
                                                </a>
                                            </li>
                                            <li
                                                class="menu-item"
                                                aria-haspopup="true"
                                            >
                                                <a
                                                    [routerLink]="[
                                                        '/gestao/vouchers'
                                                    ]"
                                                    class="menu-link"
                                                >
                                                    <span
                                                        class="svg-icon menu-icon"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <polygon
                                                                    points="0 0 24 0 24 24 0 24"
                                                                />
                                                                <path
                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                    fill="#000000"
                                                                    fill-rule="nonzero"
                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="menu-text">
                                                        {{
                                                            "modulos.produtos.vouchers"
                                                                | translate
                                                        }}
                                                    </span>
                                                </a>
                                            </li>
                                            <li
                                                *ngIf="
                                                    isAtendimentoPortal$ | async
                                                "
                                                class="menu-item"
                                                aria-haspopup="true"
                                            >
                                                <a
                                                    [routerLink]="[
                                                        '/gestao/relatorio-site'
                                                    ]"
                                                    class="menu-link"
                                                >
                                                    <span
                                                        class="svg-icon menu-icon"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <polygon
                                                                    points="0 0 24 0 24 24 0 24"
                                                                />
                                                                <path
                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                    fill="#000000"
                                                                    fill-rule="nonzero"
                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="menu-text">
                                                        {{
                                                            "modulos.relatorio-site-escola.titulo"
                                                                | translate
                                                        }}
                                                    </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                                <!-- Recursos -->
                                <li
                                    *ngIf="
                                        (isAtendimentoPortal$ | async) ||
                                        (isCoordenadorConteudo$ | async) ||
                                        (isCoordenadorAvaliacaoEditorAvaliacao$
                                            | async) ||
                                        (isRepresentanteCPB$ | async)
                                    "
                                    [ngClass]="
                                        validPageActivated(
                                            'materiais-didaticos, gestor-avaliacoes, gestao-tecnica'
                                        )
                                    "
                                    class="menu-item menu-item-submenu menu-item-open-dropdown"
                                    data-menu-toggle="hover"
                                    aria-haspopup="true"
                                >
                                    <a
                                        href="javascript:;"
                                        class="menu-link menu-toggle"
                                    >
                                        <span class="svg-icon menu-icon"
                                            ><svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                width="24px"
                                                height="24px"
                                                viewBox="0 0 24 24"
                                                version="1.1"
                                            >
                                                <g
                                                    stroke="none"
                                                    stroke-width="1"
                                                    fill="none"
                                                    fill-rule="evenodd"
                                                >
                                                    <rect
                                                        x="0"
                                                        y="0"
                                                        width="24"
                                                        height="24"
                                                    />
                                                    <rect
                                                        fill="#000000"
                                                        x="4"
                                                        y="4"
                                                        width="7"
                                                        height="7"
                                                        rx="1.5"
                                                    />
                                                    <path
                                                        d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z"
                                                        fill="#000000"
                                                        opacity="0.3"
                                                    />
                                                </g>
                                            </svg>
                                        </span>
                                        <span class="menu-text">{{
                                            "geral.recursos" | translate
                                        }}</span>
                                        <i class="menu-arrow"></i>
                                    </a>
                                    <div
                                        class="menu-submenu menu-submenu-fixed menu-submenu-left"
                                        style="width: 1000px"
                                        data-hor-direction="menu-submenu-left"
                                    >
                                        <div class="menu-subnav">
                                            <ul class="menu-content">
                                                <li
                                                    *ngIf="
                                                        (isCoordenadorConteudo$
                                                            | async) ||
                                                        (isRepresentanteCPB$
                                                            | async)
                                                    "
                                                    class="menu-item"
                                                >
                                                    <h3
                                                        class="menu-heading menu-toggle"
                                                    >
                                                        <i
                                                            class="menu-bullet menu-bullet-dot"
                                                        >
                                                            <span></span>
                                                        </i>
                                                        <span
                                                            class="menu-text"
                                                            >{{
                                                                "menu.materiais-didaticos"
                                                                    | translate
                                                            }}</span
                                                        >
                                                        <i
                                                            class="menu-arrow"
                                                        ></i>
                                                    </h3>
                                                    <ul class="menu-inner">
                                                        <li
                                                            *ngIf="
                                                                isCoordenadorConteudo$
                                                                    | async
                                                            "
                                                            class="menu-item"
                                                            aria-haspopup="true"
                                                        >
                                                            <a
                                                                href="javascript:;"
                                                                class="menu-link"
                                                                [routerLink]="[
                                                                    '/materiais-didaticos/livros-digitais'
                                                                ]"
                                                            >
                                                                <span
                                                                    class="svg-icon menu-icon"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            />
                                                                            <path
                                                                                d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    class="menu-text"
                                                                    >{{
                                                                        "modulos.materiais-didaticos.livros-digitais.titulo"
                                                                            | translate
                                                                    }}</span
                                                                >
                                                            </a>
                                                        </li>
                                                        <li
                                                            class="menu-item"
                                                            aria-haspopup="true"
                                                        >
                                                            <a
                                                                href="javascript:;"
                                                                class="menu-link"
                                                                [routerLink]="[
                                                                    '/materiais-didaticos/livros-digitais/liberacao'
                                                                ]"
                                                            >
                                                                <span
                                                                    class="svg-icon menu-icon"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            />
                                                                            <path
                                                                                d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    class="menu-text"
                                                                    >{{
                                                                        "modulos.materiais-didaticos.gestao-liberacao"
                                                                            | translate
                                                                    }}</span
                                                                >
                                                            </a>
                                                        </li>
                                                        <li
                                                            class="menu-item"
                                                            aria-haspopup="true"
                                                        >
                                                            <a
                                                                href="javascript:;"
                                                                class="menu-link"
                                                                [routerLink]="[
                                                                    '/materiais-didaticos/livros-digitais/liberacao-por-livro'
                                                                ]"
                                                            >
                                                                <span
                                                                    class="svg-icon menu-icon"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            />
                                                                            <path
                                                                                d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    class="menu-text"
                                                                    >{{
                                                                        "modulos.materiais-didaticos.gestao-liberacao-livro"
                                                                            | translate
                                                                    }}</span
                                                                >
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li
                                                    class="menu-item"
                                                    *ngIf="
                                                        isCoordenadorAvaliacaoEditorAvaliacao$
                                                            | async
                                                    "
                                                >
                                                    <h3
                                                        class="menu-heading menu-toggle"
                                                    >
                                                        <i
                                                            class="menu-bullet menu-bullet-dot"
                                                        >
                                                            <span></span>
                                                        </i>
                                                        <span class="menu-text">
                                                            {{
                                                                "menu.gestor-avaliacoes"
                                                                    | translate
                                                            }}
                                                        </span>
                                                        <i
                                                            class="menu-arrow"
                                                        ></i>
                                                    </h3>
                                                    <ul class="menu-inner">
                                                        <li
                                                            class="menu-item"
                                                            aria-haspopup="true"
                                                        >
                                                            <a
                                                                href="javascript:;"
                                                                class="menu-link"
                                                                [routerLink]="[
                                                                    '/gestor-avaliacoes/questao'
                                                                ]"
                                                            >
                                                                <span
                                                                    class="svg-icon menu-icon"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            />
                                                                            <path
                                                                                d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    class="menu-text"
                                                                >
                                                                    {{
                                                                        "modulos.gestor-avaliacoes.questao.plural"
                                                                            | translate
                                                                    }}
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li
                                                            class="menu-item"
                                                            aria-haspopup="true"
                                                            *ngIf="
                                                                isCoordenadorAvaliacaoEditorAvaliacao$
                                                                    | async
                                                            "
                                                        >
                                                            <a
                                                                href="javascript:;"
                                                                class="menu-link"
                                                                [routerLink]="[
                                                                    '/gestor-avaliacoes/avaliacoes'
                                                                ]"
                                                            >
                                                                <span
                                                                    class="svg-icon menu-icon"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            />
                                                                            <path
                                                                                d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    class="menu-text"
                                                                >
                                                                    {{
                                                                        "modulos.gestor-avaliacoes.avaliacoes.plural"
                                                                            | translate
                                                                    }}
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li
                                                            *ngIf="
                                                                isAtendimentoPortal$
                                                                    | async
                                                            "
                                                            class="menu-item"
                                                            aria-haspopup="true"
                                                        >
                                                            <a
                                                                href="javascript:;"
                                                                class="menu-link"
                                                                [routerLink]="[
                                                                    '/gestor-avaliacoes/painel'
                                                                ]"
                                                            >
                                                                <span
                                                                    class="svg-icon menu-icon"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            />
                                                                            <path
                                                                                d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    class="menu-text"
                                                                >
                                                                    {{
                                                                        "modulos.gestor-avaliacoes.painel"
                                                                            | translate
                                                                    }}
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li
                                                    class="menu-item"
                                                    *ngIf="
                                                        isAtendimentoPortal$
                                                            | async
                                                    "
                                                >
                                                    <h3
                                                        class="menu-heading menu-toggle"
                                                    >
                                                        <span></span>
                                                        <span class="menu-text">
                                                            {{
                                                                "geral.gestao-tecnica"
                                                                    | translate
                                                            }}
                                                        </span>
                                                        <i
                                                            class="menu-arrow"
                                                        ></i>
                                                    </h3>
                                                    <ul class="menu-inner">
                                                        <li
                                                            class="menu-item"
                                                            aria-haspopup="true"
                                                        >
                                                            <a
                                                                href="javascript:;"
                                                                class="menu-link"
                                                                [routerLink]="[
                                                                    '/gestao-tecnica'
                                                                ]"
                                                            >
                                                                <span
                                                                    class="svg-icon menu-icon"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            />
                                                                            <path
                                                                                d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    class="menu-text"
                                                                    >{{
                                                                        "geral.desmembrar-unificar-cadastros"
                                                                            | translate
                                                                    }}</span
                                                                >
                                                            </a>
                                                        </li>
                                                        <li
                                                            class="menu-item"
                                                            aria-haspopup="true"
                                                        >
                                                            <a
                                                                href="javascript:;"
                                                                class="menu-link"
                                                                [routerLink]="[
                                                                    '/gestao-tecnica'
                                                                ]"
                                                            >
                                                                <span
                                                                    class="svg-icon menu-icon"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            />
                                                                            <path
                                                                                d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    class="menu-text"
                                                                    >{{
                                                                        "geral.encerrar-sessao-app"
                                                                            | translate
                                                                    }}</span
                                                                >
                                                            </a>
                                                        </li>
                                                        <!-- <li class="menu-item" aria-haspopup="true">
                                                            <a href="javascript:;" class="menu-link"
                                                                [routerLink]="['/gestao-tecnica/eclass-banners']">
                                                                <span class="svg-icon menu-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px" height="24px" viewBox="0 0 24 24"
                                                                        version="1.1">
                                                                        <g stroke="none" stroke-width="1" fill="none"
                                                                            fill-rule="evenodd">
                                                                            <polygon points="0 0 24 0 24 24 0 24" />
                                                                            <path
                                                                                d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                                fill="#000000" fill-rule="nonzero"
                                                                                transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) " />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                <span class="menu-text">{{'modulos.banners.eclass-banners' |
                                                                    translate}}</span>
                                                            </a>
                                                        </li> -->
                                                        <li
                                                            class="menu-item"
                                                            aria-haspopup="true"
                                                        >
                                                            <a
                                                                href="javascript:;"
                                                                class="menu-link"
                                                                [routerLink]="[
                                                                    '/gestao-tecnica/banners'
                                                                ]"
                                                            >
                                                                <span
                                                                    class="svg-icon menu-icon"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            />
                                                                            <path
                                                                                d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    class="menu-text"
                                                                    >{{
                                                                        "modulos.banners.Banners"
                                                                            | translate
                                                                    }}</span
                                                                >
                                                            </a>
                                                        </li>
                                                        <li
                                                            class="menu-item"
                                                            aria-haspopup="true"
                                                        >
                                                            <a
                                                                href="javascript:;"
                                                                class="menu-link"
                                                                [routerLink]="[
                                                                    '/gestao-tecnica/school-site-banners'
                                                                ]"
                                                            >
                                                                <span
                                                                    class="svg-icon menu-icon"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            />
                                                                            <path
                                                                                d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    class="menu-text"
                                                                    >{{
                                                                        "modulos.banners.school-site-banners"
                                                                            | translate
                                                                    }}</span
                                                                >
                                                            </a>
                                                        </li>
                                                        <li
                                                            class="menu-item"
                                                            aria-haspopup="true"
                                                        >
                                                            <a
                                                                href="javascript:;"
                                                                class="menu-link"
                                                                [routerLink]="[
                                                                    '/gestao-tecnica/url-emergencia'
                                                                ]"
                                                            >
                                                                <span
                                                                    class="svg-icon menu-icon"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            />
                                                                            <path
                                                                                d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    class="menu-text"
                                                                >
                                                                    {{
                                                                        "modulos.url-emergencia.urls-de-emergencia"
                                                                            | translate
                                                                    }}
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li
                                                            class="menu-item"
                                                            aria-haspopup="true"
                                                        >
                                                            <a
                                                                href="javascript:;"
                                                                class="menu-link"
                                                                [routerLink]="[
                                                                    '/gestao-tecnica/relatorio-por-uniao'
                                                                ]"
                                                            >
                                                                <span
                                                                    class="svg-icon menu-icon"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            />
                                                                            <path
                                                                                d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    class="menu-text"
                                                                >
                                                                    {{
                                                                        "modulos.relatorio-usuarios-ativos.relatorio-por-usuarios-ativos"
                                                                            | translate
                                                                    }}
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li
                                                            class="menu-item"
                                                            aria-haspopup="true"
                                                        >
                                                            <a
                                                                href="javascript:;"
                                                                class="menu-link"
                                                                [routerLink]="[
                                                                    '/gestao-tecnica/relatorio-alunos'
                                                                ]"
                                                            >
                                                                <span
                                                                    class="svg-icon menu-icon"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            />
                                                                            <path
                                                                                d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    class="menu-text"
                                                                >
                                                                    {{
                                                                        "modulos.relatorio-alunos.titulo"
                                                                            | translate
                                                                    }}
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>

                                                <!-- SGI -->
                                                <ng-container
                                                    *ngIf="!isHispanos"
                                                >
                                                    <li
                                                        class="menu-item"
                                                        *ngIf="
                                                            isAtendimentoPortal$
                                                                | async
                                                        "
                                                    >
                                                        <h3
                                                            class="menu-heading menu-toggle"
                                                        >
                                                            <span></span>
                                                            <span
                                                                class="menu-text"
                                                            >
                                                                {{
                                                                    "modulos.permissionamento.permissionamento"
                                                                        | translate
                                                                }}
                                                            </span>
                                                            <i
                                                                class="menu-arrow"
                                                            ></i>
                                                        </h3>
                                                        <ul class="menu-inner">
                                                            <li
                                                                class="menu-item"
                                                                aria-haspopup="true"
                                                            >
                                                                <a
                                                                    href="javascript:;"
                                                                    class="menu-link"
                                                                    [routerLink]="[
                                                                        '/permissionamento/permissoes'
                                                                    ]"
                                                                >
                                                                    <span
                                                                        class="svg-icon menu-icon"
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                            width="24px"
                                                                            height="24px"
                                                                            viewBox="0 0 24 24"
                                                                            version="1.1"
                                                                        >
                                                                            <g
                                                                                stroke="none"
                                                                                stroke-width="1"
                                                                                fill="none"
                                                                                fill-rule="evenodd"
                                                                            >
                                                                                <polygon
                                                                                    points="0 0 24 0 24 24 0 24"
                                                                                />
                                                                                <path
                                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                                    fill="#000000"
                                                                                    fill-rule="nonzero"
                                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                    <span
                                                                        class="menu-text"
                                                                    >
                                                                        {{
                                                                            "modulos.permissionamento.permissoes"
                                                                                | translate
                                                                        }}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                            <li
                                                                class="menu-item"
                                                                aria-haspopup="true"
                                                            >
                                                                <a
                                                                    href="javascript:;"
                                                                    class="menu-link"
                                                                    [routerLink]="[
                                                                        '/permissionamento/regras'
                                                                    ]"
                                                                >
                                                                    <span
                                                                        class="svg-icon menu-icon"
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                            width="24px"
                                                                            height="24px"
                                                                            viewBox="0 0 24 24"
                                                                            version="1.1"
                                                                        >
                                                                            <g
                                                                                stroke="none"
                                                                                stroke-width="1"
                                                                                fill="none"
                                                                                fill-rule="evenodd"
                                                                            >
                                                                                <polygon
                                                                                    points="0 0 24 0 24 24 0 24"
                                                                                />
                                                                                <path
                                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                                    fill="#000000"
                                                                                    fill-rule="nonzero"
                                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                    <span
                                                                        class="menu-text"
                                                                    >
                                                                        {{
                                                                            "modulos.permissionamento.regras"
                                                                                | translate
                                                                        }}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                            <li
                                                                class="menu-item"
                                                                aria-haspopup="true"
                                                            >
                                                                <a
                                                                    href="javascript:;"
                                                                    class="menu-link"
                                                                    [routerLink]="[
                                                                        '/permissionamento/atribuicoes'
                                                                    ]"
                                                                >
                                                                    <span
                                                                        class="svg-icon menu-icon"
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                            width="24px"
                                                                            height="24px"
                                                                            viewBox="0 0 24 24"
                                                                            version="1.1"
                                                                        >
                                                                            <g
                                                                                stroke="none"
                                                                                stroke-width="1"
                                                                                fill="none"
                                                                                fill-rule="evenodd"
                                                                            >
                                                                                <polygon
                                                                                    points="0 0 24 0 24 24 0 24"
                                                                                />
                                                                                <path
                                                                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                                    fill="#000000"
                                                                                    fill-rule="nonzero"
                                                                                    transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                    <span
                                                                        class="menu-text"
                                                                    >
                                                                        {{
                                                                            "modulos.permissionamento.atribuicoes"
                                                                                | translate
                                                                        }}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="topbar">
                        <div
                            class="dropdown"
                            *ngIf="!isHispanos"
                            ngbPopover="{{ 'geral.chamado' | translate }}"
                            placement="bottom"
                            triggers="mouseenter:mouseleave"
                        >
                            <div class="topbar-item">
                                <a
                                    class="btn btn-clean btn-icon btn-dropdown mr-1"
                                    href="https://api.whatsapp.com/send?phone=5515981001868&text=Ol%C3%A1,%20estou%20vindo%20do%20HUB%20e%20gostaria%20de%20tirar%20uma%20d%C3%BAvida:"
                                    style="
                                        width: auto;
                                        padding: 0 12px;
                                        height: 45px;
                                    "
                                    target="_blank"
                                >
                                    <img
                                        src="../assets/svg/whatsapp-symbol-logo-svgrepo-com.svg"
                                        class="h-auto w-25px rounded-sm"
                                    />
                                </a>
                            </div>
                        </div>
                        <div
                            class="dropdown"
                            *ngIf="isHispanos"
                            ngbPopover="{{ 'geral.chamado' | translate }}"
                            placement="bottom"
                            triggers="mouseenter:mouseleave"
                        >
                            <div class="topbar-item">
                                <a
                                    class="btn btn-clean btn-icon btn-dropdown mr-1"
                                    href="https://t.me/soporteEA"
                                    style="
                                        width: auto;
                                        padding: 0 12px;
                                        height: 45px;
                                    "
                                    target="_blank"
                                >
                                    <img
                                        src="../assets/svg/telegram.svg"
                                        class="h-auto w-25px rounded-sm"
                                    />
                                </a>
                            </div>
                        </div>
                        <div class="dropdown">
                            <div
                                class="topbar-item"
                                data-toggle="dropdown"
                                data-offset="10px,0px"
                            >
                                <div
                                    class="btn btn-clean btn-icon btn-dropdown mr-1"
                                    style="
                                        width: auto;
                                        padding: 0 12px;
                                        height: 45px;
                                    "
                                >
                                    <span
                                        class="navi-text"
                                        style="color: #fff; font-size: 12px"
                                        >{{
                                            storage?.country_selected
                                                ?.description
                                        }}</span
                                    >
                                    <img
                                        *ngIf="
                                            storage?.country_selected?.initials
                                        "
                                        class="h-auto w-25px rounded-sm ml-2"
                                        src="assets/svg/bandeira-{{
                                            storage.country_selected.initials
                                        }}.svg"
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div
                                class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right"
                            >
                                <ul class="navi navi-hover py-4">
                                    <li
                                        *ngFor="let country of countries"
                                        class="navi-item"
                                    >
                                        <a
                                            *ngIf="country.descricao != 'Otros'"
                                            href="javascript:;"
                                            (click)="
                                                onChangeCountry(country.sigla)
                                            "
                                            class="navi-link"
                                        >
                                            <span class="symbol symbol-20 mr-3">
                                                <img
                                                    src="assets/svg/bandeira-{{
                                                        country.sigla
                                                    }}.svg"
                                                    alt=""
                                                />
                                            </span>
                                            <span class="navi-text">{{
                                                country.descricao
                                            }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="dropdown">
                            <div
                                class="topbar-item"
                                data-toggle="dropdown"
                                data-offset="10px,0px"
                            >
                                <div
                                    class="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2"
                                >
                                    <span
                                        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
                                    >
                                        {{ nameUser }}
                                    </span>
                                    <span
                                        class="symbol symbol-lg-35 symbol-25 symbol-light-success"
                                    >
                                        <span
                                            class="symbol-label font-size-h5 font-weight-bold"
                                            [style]="
                                                user.photo != null
                                                    ? 'background-color:#1e1e2e'
                                                    : ''
                                            "
                                        >
                                            <ng-container
                                                *ngIf="
                                                    user.photo != null;
                                                    else withoutPhoto
                                                "
                                            >
                                                <img
                                                    [src]="user.photo"
                                                    [alt]="user.name"
                                                    class="rounded w-100 h-100"
                                                />
                                            </ng-container>
                                            <ng-template #withoutPhoto>
                                                {{ user.initial }}
                                            </ng-template>
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div
                                class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg"
                            >
                                <div class="d-flex m-5">
                                    <div class="symbol symbol-100 mr-5">
                                        <span
                                            _ngcontent-fav-c22=""
                                            class="symbol-label font-weight-bold"
                                            style="
                                                background-color: #c8f5f3;
                                                font-size: 50px;
                                                color: #1dc4bc;
                                            "
                                        >
                                            <ng-container
                                                *ngIf="
                                                    user.photo != null;
                                                    else withoutPhoto
                                                "
                                            >
                                                <img
                                                    [src]="user.photo"
                                                    [alt]="user.name"
                                                    class="rounded w-100 h-100"
                                                />
                                            </ng-container>
                                            <ng-template #withoutPhoto>
                                                {{ user.initial }}
                                            </ng-template>
                                        </span>
                                    </div>
                                    <div class="d-flex flex-column w-100">
                                        <a
                                            [routerLink]="
                                                '/usuarios/' + user.uuid
                                            "
                                            class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
                                        >
                                            {{
                                                setName(user.name, user.surname)
                                            }}
                                        </a>
                                        <div class="text-muted mt-1">
                                            {{ user.function }}
                                        </div>
                                        <div class="navi mt-2">
                                            <a
                                                href="javascript:;"
                                                class="navi-item"
                                            >
                                                <span
                                                    class="navi-link p-0 pb-2"
                                                >
                                                    <span
                                                        class="navi-text text-muted text-hover-primary"
                                                    >
                                                        {{ user.email }}&#64;{{
                                                            getDomain()
                                                        }}
                                                    </span>
                                                </span>
                                            </a>
                                            <a
                                                *ngIf="!storage?.access_token"
                                                (click)="sair('logout')"
                                                class="btn btn-sm btn-outline-danger btn-block font-weight-bolder py-2 px-5 mt-5"
                                            >
                                                {{ "geral.sair" | translate }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dropdown">
                            <div
                                *ngIf="
                                    isAtendimentoPortal$ | async;
                                    else sairSeguro
                                "
                                class="topbar-item ml-2"
                            >
                                <div
                                    *ngIf="storage?.access_token"
                                    class="btn-group"
                                >
                                    <button
                                        type="button"
                                        class="btn btn-light-danger"
                                        (click)="sair('logout')"
                                    >
                                        {{ "geral.sair" | translate }}
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-light-danger dropdown-toggle dropdown-toggle-split"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <span class="sr-only"
                                            >Toggle Dropdown</span
                                        >
                                    </button>
                                    <div class="dropdown-menu">
                                        <button
                                            class="dropdown-item"
                                            (click)="onLogout()"
                                        >
                                            {{
                                                "geral.sair-seguro" | translate
                                            }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <ng-template #sairSeguro>
                                <div class="topbar-item ml-2">
                                    <button
                                        *ngIf="storage?.access_token"
                                        (click)="onLogout()"
                                        type="button"
                                        class="btn btn-md btn-light-danger ml-2"
                                    >
                                        {{ "geral.sair-seguro" | translate }}
                                    </button>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="content d-flex flex-column flex-column-fluid"
                [ngClass]="bgImg"
                id="kt_content"
            >
                <div class="d-flex flex-column-fluid">
                    <div class="container-fluid">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>

            <div
                class="footer bg-white py-4 d-flex flex-lg-column"
                id="kt_footer"
            >
                <div
                    class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between"
                >
                    <div class="text-dark">
                        <span class="text-muted font-weight-bold mr-2">
                            2020 - {{ anoAtual | date : "yyyy" }} ©
                            {{ "geral.portal-educacional" | translate }}.
                            {{ "geral.direitos-reservados" | translate }}
                        </span>
                    </div>
                    <div class="nav nav-dark">
                        <span class="text-muted font-weight-bold mr-2">
                            {{ "geral.versao" | translate }} {{ version }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-doc-viewer></app-doc-viewer>
