import { isArray, isBoolean, isNil, isNumber, isObject, isString } from 'lodash';
import { reduce } from "./commons";

function serialize(value: any): string {
    if (isNumber(value)) {
        return String(value);
    } else if (isBoolean(value)) {
        return String(value);
    } else if (isArray(value)) {
        const param = value.join(',');
        return !!param ? param : undefined;
    } else if (value && isString(value)) {
        return value;
    } else if (isObject(value)) {
        return JSON.stringify(value)
    }
}

function sanitize(value: Object = {}): Object {
    return reduce(Object.entries(value)
        .filter(([key, value]) => !isNil(value))
        .map(([key, value]) => ({ [key]: value })));
}


function deserialize(value: any): any {
    if (`${value}`.match(/^(?!0\d)\d*(\.\d+)?$/)) {
        return Number(value);
    }
    return value;
}

function deserializeParams(value: Object = {}): Object {
    return reduce(Object.entries(value)
        .map(([key, value]) => ({ [key]: HttpParamsUtils.deserialize(value) })));
}

export const HttpParamsUtils = {
    serialize,
    sanitize,
    deserialize,
    deserializeParams
}
