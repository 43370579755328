import { NgModule, ModuleWithProviders } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms';

import { PaginacaoComponent } from './paginacao.component'

@NgModule({
    declarations: [
        PaginacaoComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule
    ],
    exports: [
        PaginacaoComponent,
        CommonModule,
    ]
})
export class PaginacaoModule {
    static forRoot(): ModuleWithProviders<PaginacaoModule> {
        return {
            ngModule: PaginacaoModule
        }
    }
}
