<div [hidden]="form.value.nome_arquivo" class="portlet-element">
    <gpe-upload-file-v2 (onStatusChange)="onUpload($event)" [config]="dzConfig" [componentReset]="dzComponentReset"
        [onChangeConfig]="dzChangeConfig" required [titulo]="'tipos-arquivo.video' | translate" [height]="height"
        [ngClass]="{ 'has-error': !form.controls.nome_arquivo.valid && form.controls.nome_arquivo.touched }"
        [isInvalid]="isInvalid">
    </gpe-upload-file-v2>
</div>

<div class="table-responsive" *ngIf="form.value.nome_arquivo">
    <table class="custom table table-striped m-table table-hover">
        <thead class="m-datatable__head">
            <tr>
                <th class="text-center" width="20%" translate>geral.icone</th>
                <th class="text-left" width="60%" translate>geral.titulo</th>
                <th class="text-center" width="20%" translate>geral.acoes</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="text-center text-nowrap">
                    <img *ngIf="form.value.thumbnail; else icon" (click)="onShowVideo()"
                        class="m--margin-right-5 m--margin-left-5" alt="" width="150" [src]="form.value.thumbnail" />
                    <ng-template #icon>
                        <img class="m--margin-right-5 m--margin-left-5" alt="_image" width="40"
                            src="assets/images/files/{{ form.value.extensao }}.svg" />
                    </ng-template>
                </td>
                <td class="text-left tdEllipsis align-middle">
                    <span placement="top" [ngbPopover]="form.value.nome_arquivo" triggers="mouseenter:mouseleave"> {{
                        form.value.nome_arquivo }} </span>
                </td>
                <td class="text-center text-nowrap align-middle">
                    <button type="button" class="btn btn-icon btn-icon-warning btn-light btn-hover-warning btn-sm ml-3"
                        placement="top" [ngbPopover]="'geral.visualizar' | translate" triggers="mouseenter:mouseleave"
                        (click)="onShowVideo()" *ngIf="form.value.id">
                        <i class="fa fa-eye"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-light btn-hover-danger btn-sm ml-3"
                        placement="top" [ngbPopover]="'geral.excluir' | translate" triggers="mouseenter:mouseleave"
                        (click)="onDelete()">
                        <span class="svg-icon svg-icon-md svg-icon-danger">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"></rect><path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fill-rule="nonzero"></path><path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"></path>
                                </g>
                            </svg>
                        </span>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
