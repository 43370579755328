import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpParamsUtils } from '@app/utils';
import { isNil } from 'lodash';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';


export interface Resp<T> {
	id?:number,
	ret: number
	data: T
	msg?: string
}

@Injectable({
    providedIn: 'root',
})
export class ApiService {

    constructor(public readonly http: HttpClient) { }

    public get<T>(url: string, req?: any | HttpParams): Observable<Resp<T>> {
        const params = this.resolveParams(req);
        return this.http.get<Resp<T>>(`${url}`, { params }).pipe(take(1));
    }

    public put<T>(
        path: string,
        body: any = {},
        req?: any | HttpParams
    ): Observable<Resp<T>> {
        const params = this.resolveParams(req);
        return this.http
            .put<Resp<T>>(`${path}`, body, { params })
            .pipe(take(1));
    }

    public patch<T>(
        path: string,
        body: any = {},
        req?: any | HttpParams
    ): Observable<Resp<T>> {
        const params = this.resolveParams(req);
        return this.http
            .patch<Resp<T>>(`${path}`, body, { params })
            .pipe(take(1));
    }

    public post<T>(
        path: string,
        body: any = {},
        req?: any | HttpParams
    ): Observable<Resp<T>> {
        const params = this.resolveParams(req);
        return this.http
            .post<Resp<T>>(`${path}`, body, { params })
            .pipe(take(1));
    }

    public delete<T>(
        path: string,
        req?: any | HttpParams
    ): Observable<Resp<T>> {
        const params = this.resolveParams(req);
        return this.http.delete<Resp<T>>(`${path}`, { params }).pipe(take(1));
    }

    public resolveParams(req: any = {}): HttpParams {
        if (req instanceof HttpParams) {
            return req;
        }
        let params = new HttpParams();
        Object.entries(req)
            .map(([key, value]) => ({ key, value: HttpParamsUtils.serialize(value) }))
            .filter(({ key, value }) => value !== undefined)
            .forEach(({ key, value }) => (params = params.append(key, value)));
        return params;
    }

}
