import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DropzoneConfigInterface, DropzoneDirective } from 'ngx-dropzone-wrapper';
import { AppComponent } from 'app/app.component';
import { File } from 'app/models/file.model';
import { Swal } from 'app/utils';

@Component({
    selector: 'gpe-upload-file',
    templateUrl: './upload-file.component.html'
})
export class UploadFileComponent implements OnInit {

    public disabled: boolean = false;
    public params = new EventEmitter()
    public files: File[] = []
    t_translate: any

    @ViewChild(DropzoneDirective) componentRef?: DropzoneDirective;

    @Input() config: DropzoneConfigInterface = {
        clickable: false,
        maxFiles: 1
    }
    @Input() componentReset: EventEmitter<any> = new EventEmitter()
    @Input() deleteFileEvent: any = null
    @Input() link = '';
    @Input() class = '';
    @Output() onStatusChange = new EventEmitter();

    public classIcon: string = 'fa-user'

    constructor(
        private app: AppComponent,
        private translate: TranslateService,
        private swal: Swal
    ) { }

    ngOnInit(): void {
        this.initLang();
        this.config.headers = {
            Accept: 'application/json',
            Authorization: `Bearer ${this.app.storage.token}`
        }
        if (this.class != "boxFotoUsuario") {
            this.classIcon = 'fa-image'
        }
        this.params.subscribe(data => {
            this.config.params = data
        })
    }

    public onUploadError(args: any): void {
        if (!args[1].msg) {
            this.swal.msgAlert(this.t_translate['geral.atencao'], this.t_translate['geral.verifique-arquivo-upload'], 'warning', 'ok')
            this.componentRef.reset()
        } else {
            this.swal.msgAlert(this.t_translate['geral.atencao'], args[1].msg, 'warning', 'Ok')
            this.componentRef.reset()
        }
    }

    public onUploadSuccess(args: any): void {
        if (args[1].ret) {
            this.files.push({
                file: args[1].file,
                title: args[1].title,
                extension: args[1].extension,
                size: args[1].size
            })
            this.onStatusChange.emit({
                success: true,
                files: this.files
            });
            this.componentRef.reset()
        } else {
            if (!args[1].msg) {
                this.swal.msgAlert(this.t_translate['geral.atencao'], this.t_translate['geral.verifique-arquivo-upload'], 'warning', 'ok')
                this.componentRef.reset()
            } else {
                this.swal.msgAlert(this.t_translate['geral.atencao'], args[1].msg, 'warning', 'ok')
                this.componentRef.reset()
            }
        }
    }

    public resetDropzone() {
        this.componentRef.reset()
        this.files = []
        //this.files.splice(0, 1)
    }

    initLang() {
        this.translate.setDefaultLang(
            this.app.storage.country_selected.language
        );
        this.translate
            .get([
                'geral.atencao',
                'geral.verifique-arquivo-upload',
            ])
            .subscribe((text: any) => {
                this.t_translate = text;
            });
    }
}
