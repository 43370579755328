export enum CountryEnum {
    Brasil = 1,
    Argentina = 2,
    Uruguai = 3,
    Paraguai = 4,
    Bolivia = 5,
    Chile = 6,
    Peru = 7,
    Equador = 8,
    Espanha = 9,
    Portugal = 10,
    Venezuela = 11,
    Colombia = 13,
    Ucrania = 14
}
