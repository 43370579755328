import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthGuard } from './auth.guard';
import { LocalStorageService } from './storage.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    constructor(private authGuard: AuthGuard, private storageService: LocalStorageService, private _router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // if (!this.authGuard.isLoggedIn()) {
        //   return next.handle(req)
        // }
        let storage = this.storageService.storageUsuario
        if (!storage.token) {
            this.authGuard.limpaStorage('login')
        }

        let timezone = storage.country_selected != null && storage.country_selected.timezone != null ? storage.country_selected.timezone : 'America/Sao_Paulo'
        let language = storage.country_selected != null && storage.country_selected.language != null ? storage.country_selected.language : 'pt-BR'

        let headers = req.headers;
        if(!(req.body instanceof FormData)){
            headers = req.headers.set('Content-Type', 'application/json');
        }
        const authReq = req.clone({
            headers: headers.append('Authorization', `Bearer ${storage.token}`)
            .append('Timezone', timezone)
            .append('Content-Language', language),
        })

        return next.handle(authReq).pipe(
            catchError((httpErrorResponse: HttpErrorResponse) => {
                const status = httpErrorResponse.status

                if (status == 401) {
                    this.authGuard.limpaStorage('login')
                    //console.error(httpErrorResponse.message)
                }
                // // Status de requisição malfeita:
                // if (status === 400) {
                //     console.error(httpErrorResponse.statusText, httpErrorResponse.error)

                //     return throwError(httpErrorResponse)
                // }

                // qualquer outro tipo de erro
                //console.error(httpErrorResponse.message)

                return throwError(httpErrorResponse)
            })
        )
    }
}
