import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { hubConst } from '@app/core';
import { ApiService, Resp } from './api.service';
import { Chatbot } from '@app/models/chatbot.model';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {
  private api = hubConst.api

  private readonly base_url: string = 'v1/hub-educacional/chatbot'

  constructor(private http: ApiService) { }

  public get(params: Params = {}): Observable<Resp<Chatbot>>{
    return this.http.get(`${this.api.usuario}${this.base_url}`, params)
  }
  
  public create(data): Observable<Resp<any>> {
    return this.http.post(`${this.api.usuario}${this.base_url}`, data)
  }

  public update(data): Observable<Resp<any>> {
      return this.http.put(`${this.api.usuario}${this.base_url}/${data.id}`, data)
  }
}
