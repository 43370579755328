import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { ClipboardModule } from 'ngx-clipboard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AuthAdvisorGuard } from './guards/auth-advisor.guard';
import { AuthAtendimentoPortalGuard } from './guards/auth-atendimento-portal.guard';
import { AuthCoordenadorAvaliacaoGuard } from './guards/auth-coordenador-avaliacao.guard';
import { AuthCoordenadorConteudoOrRepresentanteCPBGuard } from './guards/auth-coordenador-conteudo-or-representante-cpb.guard';
import { AuthCoordenadorConteudoGuard } from './guards/auth-coordenador-conteudo.guard';
import { AuthPermissaoGuard } from './guards/auth-permissao.guard';
import { AuthTecnicoAdmResponsavelTecnicoGuard } from './guards/auth-tecnico-adm-responsavel-tecnico.guard';
import { AuthTecnicoAdmGuard } from './guards/auth-tecnico-adm.guard';
import { AuthGuard } from './guards/auth.guard';
import { HeaderInterceptor } from './guards/auth.interceptor';
import { LocalStorageService } from './guards/storage.service';
import { UsuarioService } from './services/usuario.service';
import { PaginacaoModule } from './shared/paginacao/paginacao.module';
import { Sabado, Swal } from './utils';
import { AuthAdventistSchoolGuard } from './guards/auth-adventist-school.guard';

registerLocaleData(localeEs, 'es')
registerLocaleData(localePt, 'pt-BR')

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        CoreModule,
        FormsModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ClipboardModule,
        SharedModule,
        PaginacaoModule.forRoot(),

    ],
    providers: [
        UsuarioService,
        LocalStorageService,
        AuthPermissaoGuard,
        AuthGuard,
        AuthTecnicoAdmGuard,
        AuthCoordenadorConteudoGuard,
        AuthCoordenadorAvaliacaoGuard,
        AuthAtendimentoPortalGuard,
        AuthTecnicoAdmResponsavelTecnicoGuard,
        AuthAdvisorGuard,
        AuthCoordenadorConteudoOrRepresentanteCPBGuard,
        AuthAdventistSchoolGuard,
        Sabado,
        Swal,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderInterceptor, multi: true
        }
    ],
    exports: [TranslateModule],
    bootstrap: [AppComponent]
})
export class AppModule { }
