import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditInlineComponent } from './edit-inline.component';



@NgModule({
    imports: [CommonModule],
	declarations: [EditInlineComponent],
	exports: [EditInlineComponent],
})
export class EditInlineModule { }
