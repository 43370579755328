import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'svg-ribbon-r',
  templateUrl: './ribbon-r.component.svg',
  styleUrls: ['./ribbon-r.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RibbonRComponent {
  @Input() fill: string
}
