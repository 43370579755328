import swal from 'sweetalert2';

export class Swal {
    msgAlert(titulo, texto, icon, botao?, typeText = 'text', large = false) {

        let options = {
            title: titulo,
            icon: icon
        }
        options[typeText] = texto
        if (large) {
            options['width'] = '60%'
        }
        if (botao == null) {
            options['showConfirmButton'] = false
            options['timer'] = 3000
        } else {
            options['confirmButtonText'] = botao
        }

        return swal.fire(options);
    }

    autoButton(titulo, texto, icon){
        swal.fire({
            title: titulo,
            text: texto,
            icon: icon,
            showConfirmButton: false,
            timer: 1500,
        });
    }

    button(titulo, texto, icon, confBtnText, callbackSuccess){
        swal.fire({
            title: titulo,
            text: texto,
            icon: icon,
            confirmButtonText: confBtnText,
            // showConfirmButton: false,
        }).then((result) => {
            if (result.isConfirmed && callbackSuccess != null) {
                callbackSuccess.callback()
            }
        });
    }

    confirmAlert(titulo, texto, icon, botao, callback?) {
        return swal.fire({
            title: titulo,
            text: texto,
            icon: icon,
            confirmButtonText: botao,
            showCancelButton: true,
            focusCancel: true,
            onClose: () => {
                if (callback) {
                    callback.callback()
                }
            }
        })
    }

    confirmAlertQuestionCustom(titulo, texto, icon, confBtnText, cancelBtnText, callbackSuccess?, callbackCancel?) {
        return swal.fire({
            title: titulo,
            text: texto,
            icon: icon,
            showCancelButton: true,
            confirmButtonText: confBtnText,
            confirmButtonColor: "#3085d6",
            cancelButtonText: cancelBtnText,
            cancelButtonColor: "#d33"
        })
    }

    confirmAlertCustom(titulo, texto, icon, confBtnText, cancelBtnText, callbackSuccess?, callbackCancel?, typeText = 'text', large = false) {
        let data = {
            title: titulo,
            icon: icon,
            showCancelButton: true,
            confirmButtonText: confBtnText,
            confirmButtonColor: "#3085d6",
            cancelButtonText: cancelBtnText,
            cancelButtonColor: "#d33",
        }
        data[typeText] = texto
        if (large) {
            data['width'] = '60%'
        }

        swal.fire(data).then((result) => {
            if (result.isConfirmed && callbackSuccess != null) {
                callbackSuccess.callback()
            } else if (result.isDismissed && callbackCancel != null) {
                callbackCancel.callback()
            }
        })
    }

    editOrCreateValue(title, actualValue, confirmButtonText, cancelButtonText) {
        return swal.fire({
            title: title,
            input: 'text',
            inputValue: actualValue,
            confirmButtonText: confirmButtonText,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: cancelButtonText,
            cancelButtonColor: "#d33",
            inputValidator: (value) => {
                if (!value) {
                    return 'Necessário preencher o campo!'
                }

                if (value.length > 45) {
                    return 'Tamanho de texto excedido!!'
                }
            },
          })
    }

    iframeViewer(title, link) {
        swal.fire({
            title: title,
            html: `<div style="padding:56.25% 0 0 0;position:relative;">
                        <iframe src="${link}"
                            style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen"
                            allowfullscreen>
                        </iframe>
                    </div>`,
            width: 1280,
            heightAuto: true
        })
    }

    audioViewer(title, link) {
        swal.fire({
            title: title,
            html: `<audio controls>
                        <source src="${link}" type="audio/mpeg">
                        Your browser does not support the audio element.
                    </audio>`,
        })
    }
}
