import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocalStorageService } from '@app/guards/storage.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ComponentService } from './component.service';

@Component({
    selector: 'gpe-ng-select-component',
    templateUrl: './ng-select-component.component.html',
    providers: [
        ComponentService,
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => NgSelectComponentComponent),
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgSelectComponentComponent implements OnInit, ControlValueAccessor {
    public form: FormGroup
    public data$ = new BehaviorSubject<any>([])
    private _gradeId$ = new BehaviorSubject<number>(undefined)
    private _stageId$ = new BehaviorSubject<number>(undefined)

    constructor(
        private _formBuilder: FormBuilder,
        private _componentService: ComponentService,
        private _localStorageService: LocalStorageService
    ) {
        this.initForm()
        this.loadData()
    }

    @Input('gradeId') public set setGradeId(gradeId: number) {
        this._gradeId$.next(gradeId)
    }

    @Input('stageId') public set setStageId(stageId: number) {
        this._stageId$.next(stageId)
    }

    ngOnInit(): void {
    }

    private loadData() {
        const publishingId = this._localStorageService.storageUsuario?.country_selected.editoras[0].id
        combineLatest([this._stageId$, this._gradeId$]).pipe(
            tap(() => this.reset()),
            switchMap(([stageId, gradeId]) => this._componentService.index(publishingId, stageId, gradeId)),
            map(res => res.ret === 1 ? res.data : []),
        ).subscribe(value => {
            this.data$.next(value)
            if (value.length == 1) {
                this.form.patchValue({id: value[0].id})
                this.onChange(this.form.value.id)
            }
        })
    }

    private initForm() {
        this.form = this._formBuilder.group({
            id: undefined
        })
    }

    private reset() {
        this.form.reset()
        this.onChange(undefined)
    }

    public writeValue(value: number) {
        this.form.patchValue({ id: value })
    }

    public registerOnChange(fn: (v: any) => void): void {
        this.onChange = fn
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn
    }

    public onSelect(value: any) {
        this.onTouched()
        this.onChange(value?.id)
    }

    private onChange = (_: any) => { }
    private onTouched = () => { }

    public groupByFn = (component: any) => component.grade_name;

    public groupValueFn = (_: string, components: any[]) => ({ name: components[0].grade_name, stage: { name: components[0].stage_name }, total: components.length });
}
