import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  Inject,
  Injector,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, switchMap, tap } from 'rxjs/operators';

import { SideNavContext, SidenavLoader, SideNavRef, SIDENAV_CONFIG, SIDENAV_DATA } from './sidenav-ref';
import { OnDestroy } from '@angular/core';

const DELAY = 100;

enum STATE {
  Open,
  Close
}
@Component({
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavComponent<T, D = any, R = any> implements OnInit, OnDestroy {
  public stateClass$: Observable<string>
  private state = STATE.Open
  private _stateClass$ = new BehaviorSubject<STATE>(STATE.Open)
  @ViewChild('content', { read: ViewContainerRef, static: true }) private template: ViewContainerRef

  constructor(
    private context: SideNavContext<R, T>,
    private resolver: ComponentFactoryResolver,
    @Inject(SIDENAV_CONFIG) public data: SidenavLoader<T, D>,
  ) {
    this.loadListeners()
    this.stateClass$ = this._stateClass$.pipe(
      tap((state) => this.state = state),
      switchMap((state) => {
        if (state === STATE.Open) {
          return of(`${this.sideClass}--open`).pipe(delay(DELAY))
        }
        return of('')
      })
    )
  }

  public get sideClass(): string {
    return `sidenav__element--${this.data.config.side}`;
  }

  public get isClose(): boolean {
    return this.state === STATE.Close
  }

  public ngOnInit(): void {
    this.loadComponent()
  }

  public ngOnDestroy() {
    this.onClose()
  }

  public onClose() {
    this.context.close()
  }

  private loadListeners() {
    this.context.afterClosed$.pipe(
      tap(() => this._stateClass$.next(STATE.Close)),
      delay(DELAY)
    ).subscribe(() => this.context.host.detach())
  }

  private loadComponent() {
    const injector = Injector.create({
      providers: [{ provide: SIDENAV_DATA, useValue: { data: this.data.config.data } },
      { provide: SideNavRef, useValue: new SideNavRef(this.context) }],
    });
    const factory = this.resolver.resolveComponentFactory(this.data.reference)
    const ref = this.template.createComponent(factory, null, injector);
    (this.context as { instance: any }).instance = ref.instance
  }


}
