import { Injectable } from '@angular/core';
import { hubConst } from '@app/core';
import { ApiService, Resp } from '@services/api.service';
import { Matrix } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MatrixService {
    private _baseUrl = hubConst.api.categories

    constructor(private apiService: ApiService) { }
    
    index(publishingId: number, stageId?: number, gradeId?: number, componentId?: number, exceptType?: number): Observable<Resp<Matrix[]>> {
        return this.apiService.get<Matrix[]>(`${this._baseUrl}v1/matrix`, {publishing_id: publishingId, stage_id: stageId, grade_id: gradeId, component_id: componentId, except_type: exceptType})
    }
}
