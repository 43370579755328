import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'gpe-classifications',
  templateUrl: './classifications.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClassificationsComponent {
    @Input() public class: string
}
