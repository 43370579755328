import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { isEmpty, nth, startsWith } from 'lodash';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { LocalStorageService } from './storage.service';

const ROUTES = {
    ['coordenador_avaliacao']: '/gestor-avaliacoes',
    ['coordenador_conteudo']: '/materiais-didaticos',
};
@Injectable()
export class AuthPermissaoGuard implements CanActivateChild {
    constructor(
        private router: Router,
        private localStorageService: LocalStorageService
    ) {}

    public canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> {
        return this.localStorageService.storage$.pipe(
            filter((storage) => !!storage.funcoes),
            switchMap((storage) => {
                const hasFunctions = !isEmpty(storage.funcoes);
                const defaultRoute = this.resolveRoute(storage);
                const allowedRoute = startsWith(state.url, defaultRoute);
                if (
                    hasFunctions ||
                    !!storage.atendimento_portal ||
                    allowedRoute
                ) {
                    return of(true);
                }
                return this.router.navigate([defaultRoute]);
            })
        );
    }

    private resolveRoute(storage: any): string {
        const route = Object.entries(ROUTES).find(([key]) => !!storage[key]);
        return nth(route, 1) || '/';
    }
}
