import { Injectable } from '@angular/core';
import { hubConst } from '@app/core';
import { Editora } from '@app/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, Resp } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class EditoraService {

  constructor(private apiService: ApiService) { }

  private _baseUrl = hubConst.api.usuario
    length: number;


    public index():Observable<Resp<Editora[]>>{
        return this.apiService.get<Editora[]>(`${this._baseUrl}v1/hub-educacional/editoras`)
            .pipe(map(res => res as any))
    }

    public get(editora_id: number):Observable<Resp<Editora>>{
        return this.apiService.get(`${this._baseUrl}v1/hub-educacional/editoras/${editora_id}`)
    }

    public store(value: any = {}):Observable<Resp<any>> {
        return this.apiService.post(`${this._baseUrl}v1/hub-educacional/editoras`, value)
    }

    public update(id: number, value: any):Observable<Resp<any>> {
        return this.apiService.put(`${this._baseUrl}v1/hub-educacional/editoras/${id}`, value)
    }

    public destroy(id: number):Observable<Resp<any>> {
        return this.apiService.delete(`${this._baseUrl}v1/hub-educacional/editoras/${id}`)
    }

}
