import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { LocalStorageService } from '@app/guards/storage.service'
import { TranslateService } from '@ngx-translate/core'
import { isNil } from 'lodash'
import { CKEditorComponent } from 'ngx-ckeditor'
import { BehaviorSubject } from 'rxjs'

@Component({
	selector: 'gpe-editor-conteudo',
	templateUrl: './editor-conteudo.component.html',
	styleUrls: ['./editor-conteudo.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => EditorConteudoComponent),
		},
	],
})
export class EditorConteudoComponent implements OnInit, OnDestroy, ControlValueAccessor {
	@Input() public urlFileSave: string
	@Input() public paramsUrlFileSave = {}
	@Input() public full: boolean = false
	@Input() public fullWithVideo: boolean = true
	@Input() public label: string = 'geral.descricao'
	@Input() public heightDefault: number = null
	@Input() public isInvalid = false
	@Output() public ready = new EventEmitter()
    @Output() public setBlur = new EventEmitter()
	@ViewChild(CKEditorComponent, { static: false }) ckeditor: CKEditorComponent
	public editorConfig
	public editor$ = new BehaviorSubject(undefined)
	public language = ''
	public height: number
	private _userStorage = this.storage.storageUsuario

	constructor(private translate: TranslateService, private storage: LocalStorageService) {}

	ngOnInit() {
		this.initLanguage()
		this.height = this.heightDefault
		this.editorConfig = this.full ? ( this.fullWithVideo ? this.getConfigFull() : this.getConfigFullLessVideo() ) : this.getConfigText()
		this.editorConfig = { ...this.editorConfig, height: this.height }
		this.initEditor()
	}

	public ngOnDestroy() {
		this.editor$.complete()
	}

	initEditor() {
		const params = this.paramsHelper(this.paramsUrlFileSave)
		const urlFileSaveFull = `${this.urlFileSave}?${params}`
		if (this.urlFileSave != null) {
			this.editorConfig['fileTools_requestHeaders'] = { Authorization: `Bearer ${this._userStorage.token}` }
			this.editorConfig['filebrowserImageUploadUrl'] = urlFileSaveFull
			this.editorConfig['uploadUrl'] = urlFileSaveFull
		}
	}

	initLanguage() {
		// busca as palavras do arquivo de tradução
		this.language = this._userStorage?.usuario_ativo?.lingua ? this._userStorage?.usuario_ativo?.lingua : 'pt-BR'
		this.translate.setDefaultLang(this.language) // seta a linguagem padrão do sistema
	}

	public writeValue(value) {
		this.editor$.next(value)
	}

	public registerOnChange(fn: (v: any) => void): void {
		this.change = fn
	}

	public registerOnTouched(fn: () => void): void {
		this.markAsTouched = fn
	}

	public onReady(value): void {
		this.ready.emit()
		if (this.ckeditor && value) {
			this.ckeditor.instance.setData(value)
		}
	}

	public onFocus() {
		this.markAsTouched()
		this.ckeditor?.instance?.focus()
	}

    public onBlur() {
        this.onChange(this.ckeditor.instance.getData())
        this.setBlur.emit(this.ckeditor.instance.getData())
    }

	public onChange(value: any) {
		this.change(value)
	}

	private change = (_: any) => {}
	private markAsTouched = () => {}

	public getConfigFull() {
		if (!this.height) {
			this.height = 500
		}
		return {
			extraPlugins: ['mathjax'],
			uploadUrl: '/uploader/upload.php',
			mathJaxLib: 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.0/MathJax.js?config=TeX-AMS_HTML',
			embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
			toolbar: [
				{
					name: 'basicstyles',
					groups: ['basicstyles', 'cleanup'],
					items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'],
				},
				{
					name: 'paragraph',
					groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
					items: [
						'NumberedList',
						'BulletedList',
						'-',
						'-',
						'Blockquote',
						'-',
						'JustifyLeft',
						'JustifyCenter',
						'JustifyRight',
						'JustifyBlock',
					],
				},
				{ name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
				{ name: 'clipboard', groups: ['clipboard', 'undo'], items: ['PasteFromWord', '-', 'Undo', 'Redo'] },
				'/',
				{ name: 'editing', groups: ['find', 'selection', 'spellchecker'], items: [] },
				{ name: 'insert', items: ['Image', 'oembed', 'audio', 'Table', 'HorizontalRule', 'SpecialChar'] },
				{ name: 'styles', items: ['Styles'] },
				{ name: 'document', groups: ['mode', 'document', 'doctools'], items: ['Source'] },
				{ name: 'others', items: ['-'] },
				{ name: 'tools', items: ['Maximize', 'Preview'] },
			],
		}
	}

    public getConfigFullLessVideo() {
        if (!this.height) {
			this.height = 500
		}
		return {
			extraPlugins: ['mathjax'],
			uploadUrl: '/uploader/upload.php',
			mathJaxLib: 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.0/MathJax.js?config=TeX-AMS_HTML',
			embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
			toolbar: [
				{
					name: 'basicstyles',
					groups: ['basicstyles', 'cleanup'],
					items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'],
				},
				{
					name: 'paragraph',
					groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
					items: [
						'NumberedList',
						'BulletedList',
						'-',
						'-',
						'Blockquote',
						'-',
						'JustifyLeft',
						'JustifyCenter',
						'JustifyRight',
						'JustifyBlock',
					],
				},
				{ name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
				{ name: 'clipboard', groups: ['clipboard', 'undo'], items: ['PasteFromWord', '-', 'Undo', 'Redo'] },
				'/',
				{ name: 'editing', groups: ['find', 'selection', 'spellchecker'], items: [] },
				{ name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar'] },
				{ name: 'styles', items: ['Styles'] },
				{ name: 'document', groups: ['mode', 'document', 'doctools'], items: ['Source'] },
				{ name: 'others', items: ['-'] },
				{ name: 'tools', items: ['Maximize', 'Preview'] },
			],
		}
    }

	public getConfigText() {
		if (!this.height) {
			this.height = 250
		}
		return {
			extraPlugins: ['mathjax'],
			// uploadUrl: '/uploader/upload.php',
			mathJaxLib: 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.0/MathJax.js?config=TeX-AMS_HTML',
			embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
			toolbar: [
				{
					name: 'basicstyles',
					groups: ['basicstyles', 'cleanup'],
					items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'],
				},
				{
					name: 'paragraph',
					groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
					items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
				},
				{
					name: 'clipboard',
					groups: ['clipboard', 'undo'],
					items: ['Undo', 'Redo'],
				},
			],
		}
	}

	private paramsHelper(data: any = {}) {
		return Object.entries(data)
		  .filter(([key, value]) => !isNil(value))
		  .map(([key, value]) => `${key}=${value}`).join('&')
	}

	private sanitize = (html: string): string => {
		if (!!html) {
			const lastBreakLine = html.lastIndexOf('\n')
			return html.slice(0, lastBreakLine)
		}
		return html
	}
}
