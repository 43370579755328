import { Component, OnInit } from '@angular/core';
import { DocViewerParams, DocViewerService } from '@shared/service/doc-viewer.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

interface DocViewerV2Data {
	params: DocViewerParams
	aberto: boolean
}

@Component({
    selector: 'app-doc-viewer',
    templateUrl: './doc-viewer.component.html',
    styleUrls: ['./doc-viewer.component.scss']
})
export class DocViewerComponent {
    public data$: Observable<DocViewerV2Data>
	private destroy$ = new Subject()

	constructor(private docViewerService: DocViewerService) {
		const aberto$ = this.docViewerService.aberto$
		const params$ = this.docViewerService.params$.pipe(filter((params) => !!params))
		this.data$ = combineLatest([aberto$, params$]).pipe(
			takeUntil(this.destroy$),
			map(([aberto, params]) => ({ aberto, params }))
		)
	}

	public ngOnDestroy() {
		this.destroy$.next()
		this.destroy$.complete()
	}

	public onClose() {
		this.docViewerService.onToggle(false)
	}
}
