import { Injectable } from '@angular/core';
import { hubConst } from '@app/core';
import { ApiService, Resp } from '@services/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AplicacaoService {

  constructor(protected readonly api: ApiService) { }

  public painel(data: any): Observable<Resp<any>> {
    return this.api.get(`${hubConst.api.avaliacoes_api}aplicacao/painel`, data)
}
}
