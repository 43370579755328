import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { hubConst } from '@app/core';
import { Swal } from '@app/utils';
import { TranslateService } from '@ngx-translate/core';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

@Component({
    selector: 'gpe-upload-element-audio',
    templateUrl: './upload-element-audio.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => UploadElementAudioComponent),
        }
    ]
})
export class UploadElementAudioComponent implements OnInit, ControlValueAccessor {

    @Input() public isInvalid: boolean = false
    @Input() public height: number

    private _traducao: any
    private _api = hubConst.api
    public form: FormGroup
    public dzComponentReset: EventEmitter<any> = new EventEmitter()
    public dzConfig: DropzoneConfigInterface = {
        clickable: true,
        url: `${this._api.usuario}temp-upload/v1/file`,
        params: {
            pesoMaximo: 500, //Define o peso maximo do arquivo, pasar o valor em kb
            stringKbMb: 'Mb' //Define se é em kb ou Mb o peso máximo do arquivo
        },
        createImageThumbnails: false,
        maxFiles: 1,
        maxFilesize: 500, //Define o peso maximo do arquivo, pasar o valor em mb
        acceptedFiles: '.mp3,.mpga,.wav',
        previewsContainer: false
    }

    constructor(
        private formBuilder: FormBuilder,
        private translate: TranslateService,
        private swal: Swal,
        private _cdref: ChangeDetectorRef,
    ) {
        this.initForm()
    }

    public ngOnInit(): void {
        this.initLanguage()
    }

    private initForm() {
        this.form = this.formBuilder.group({
            id: undefined,
            hash: undefined,
            nome_arquivo: [undefined, [Validators.required]],
            extensao: [undefined, [Validators.required]],
            file: [undefined, [Validators.required]],
            size: undefined,
            mimetype: undefined
        })
    }

    private initLanguage() {
        this.translate
            .get([
                'geral.atencao',
                'geral.sim',
                'geral.nao',
                'dropzone.alerta-delete'
            ])
            .subscribe((text: any) => this._traducao = text)
    }

    public onUpload(event) {
        if (event.success) {
            this.form.patchValue({
                id: undefined,
                hash: event.hash,
                nome_arquivo: event.title,
                extensao: event.extension,
                file: event.file,
                size: event.size,
                mimetype: event.mimetype
            })
            this.onSelect(this.form.value)
            this.resetDz()
        }
    }

    public onDelete() {
        this.swalAlert(this._traducao['geral.atencao'], this._traducao['dropzone.alerta-delete'], 'warning', true).then((result) => {
            if (result.value) {
                this.reset()
                this._cdref.markForCheck()
            }
        })
    }

    private swalAlert(title: string, text: string, type, showCancelButton = false) {
        if (!showCancelButton) {
            this.swal.autoButton(title, text, type)
        } else {
            return this.swal.confirmAlertQuestionCustom(title, text, type, this._traducao['geral.sim'], this._traducao['geral.nao'])
        }
    }

    public onGetUrlFile() {
        return this.form.controls.hash.value ? hubConst.s3_path_temp + this.form.controls.file.value : this.form.controls.file.value
    }

    private resetDz() {
        this.dzComponentReset.emit(true)
    }

    private reset() {
        this.form.reset()
        this.onSelect()
    }

    public registerOnChange(fn: (v: any) => void): void {
        this.onChange = fn
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn
    }

    public writeValue(data) {
        if (!!data) {
            this.form.patchValue(data)
        } else {
            this.form.reset()
        }
    }

    public onSelect(object: any = undefined) {
        this.onTouched()
        if (!!object) {
            this.onChange(object)
        } else {
            this.onChange(undefined)
        }
    }

    private onChange = (_: any) => { }

    private onTouched = () => { }
}
