import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap, startWith } from 'rxjs/operators';


export interface Label {
    id: any;
    name: string
}


@Injectable({
    providedIn: 'root'
})
export class LabelService {

    constructor(private translateService: TranslateService) { }

    public translate(labels: Label[] = []): Observable<Label[]> {
        return this.translateService.onLangChange.pipe(startWith({}), switchMap(() => forkJoin(labels.map(this.translateLabel))))
    }

    private translateLabel = (label: Label): Observable<Label> => {
        return this.translateService.get(label.name).pipe(map(name => ({ ...label, name })))
    }

}
