<div class="dropzone__container">
    <div [class]="class + ' border-1 dropzone p-0'" [ngClass]="{'border-danger': isInvalid}" [dropzone]="config"
        [disabled]="disabled" (sending)="onProgress()" (error)="onUploadError($event)"
        (success)="onUploadSuccess($event)">
        <div class="dz-message">
            <div *ngIf="!link" [ngStyle]="{'padding-top': height + '%'}">
                <div class="dropzone__empty border-0 p-3">
                    <div class="dropzone__text">{{ titulo }}</div>
                    <small *ngIf="!!config?.params?.limiteArquivos" class="dropzone__text">{{ 'dropzone.limite-arquivos'
                        | translate: config?.params }}</small>
                    <small class="dropzone__text">{{ 'dropzone.formato' | translate: config }}</small>
                    <small *ngIf="config?.params?.limiteMaximo && config?.params?.largura" class="dropzone__text">{{
                        'dropzone.largura' | translate: config?.params}}</small>
                    <small *ngIf="config?.params?.limiteMaximo && !config?.params?.limiteArquivos"
                        class="dropzone__text">{{ 'dropzone.tamanhoPeso' | translate: config?.params }}</small>
                    <small *ngIf="!config?.params?.limiteMaximo && !config?.params?.verificaTamanho"
                        class="dropzone__text">{{ 'dropzone.tamanho' | translate: config }}</small>
                    <small *ngIf="config?.params?.verificaTamanho" class="dropzone__text">{{ 'dropzone.tamanhoGb' |
                        translate: config }}</small>
                </div>
            </div>
            <div class="dropzone__preview" *ngIf="link">
                <img class="dropzone__img" [src]="link" />
            </div>
        </div>
    </div>
    <div class="dropzone__inprogress" *ngIf="loading$ | async">
        <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
        </div>
        <span class="dropzone__progress-message" translate>geral.carregando</span>
    </div>
</div>
