import { Injectable } from '@angular/core';
import { hubConst } from '@app/core';
import { Observable } from 'rxjs';
import { ApiService, Resp } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class ProdutosService {

    constructor(private apiService: ApiService) { }

    private _baseUrl = hubConst.api.voucher
    length: number;

    public allVouchers(data: any): Observable<Resp<any>> {
        return this.apiService.get(`${this._baseUrl}v1/student`, data)
    }

    public studentVouchers(student_id: number): Observable<Resp<any>> {
        return this.apiService.post(`${this._baseUrl}v1/student/${student_id}/vouchers`)
    }

    public createVoucher(data): Observable<Resp<any>> {
        return this.apiService.post(`${this._baseUrl}v1/voucher`, data)
    }

    public printEmail(data): Observable<Resp<any>> {
        return this.apiService.get(`${this._baseUrl}v1/voucher/print`, data)
    }

    public sendEmail(data): Observable<Resp<any>> {
        return this.apiService.post(`${this._baseUrl}v1/voucher/send`, data)
    }

    public updateStatus(voucher_id: number): Observable<Resp<any>> {
        return this.apiService.put(`${this._baseUrl}v1/voucher/${voucher_id}`)
    }

    public produtos(data): Observable<Resp<any>> {
        return this.apiService.get(`${this._baseUrl}v1/product`, data)
    }

    public printVoucher(student_ids: string, product_ids: string, entity_id: number, token: string) {
        window.open(`${this._baseUrl}v1/voucher/print?token=${token}&${student_ids}&${product_ids}&entity_id=${entity_id}`, "_blank");
    }

    /**
     *
     * @param {any} [params]
     * @param {number} [params.entity_id]
     * @param {number[]} [params.grade_ids]
     * @returns {Observable<Resp<any>>}
     */
    public getGradesLimit(params): Observable<Resp<any>> {
        return this.apiService.post(`${this._baseUrl}v1/grades-limits`, params)
    }

    /**
     *
     * @param {number} gradeId
     * @param {any} [params]
     * @param {number} [params.voucher_limit]
     * @returns
     */
    public updateGradeLimit(gradeId: number, params): Observable<Resp<any>> {
        return this.apiService.post(`${this._baseUrl}v1/grade-limit/${gradeId}`, params)
    }
}
