<div class="sidenav__element" [ngClass]="[sideClass]">
    <div class="py-5 px-10" [ngClass]="stateClass$ | async">
        <div class="d-flex justify-content-between align-items-center gap-5">
            <h4 class="m-0">
                {{data?.config?.title? (data.config.title | translate) : data?.config?.titleTranslated}}
            </h4>
            <button type="button" class="btn btn-icon btn-text-dark btn-hover-light-dark btn-light" (click)="onClose()">
                <i class="icon text-dark-50 ki ki-close"></i>
            </button>
        </div>
        <div class="sidenav__divider py-5"></div>
        <ng-template #content></ng-template>
    </div>
</div>
<div class="sidenav__background" [ngClass]="{'sidenav__background--close': isClose}" (click)="onClose()">&nbsp;</div>
