import { ChangeDetectionStrategy, Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocalStorageService } from '@app/guards/storage.service';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { StageService } from './stage.service';

@Component({
    selector: 'gpe-ng-select-stage',
    templateUrl: './ng-select-stage.component.html',
    providers: [
        StageService,
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => NgSelectStageComponent),
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgSelectStageComponent implements OnInit, ControlValueAccessor {
    public form: FormGroup
    public data$ = new BehaviorSubject<any>([])

    constructor(
        private _formBuilder: FormBuilder,
        private _stageService: StageService,
        private _localStorageService: LocalStorageService
    ) {
        this.initForm()
        const publishing_id = this._localStorageService.storageUsuario?.country_selected.editoras[0].id
        this._stageService.index(publishing_id).pipe(
                map(res => res?.ret == 1 ? res.data : [])
            ).subscribe(values => {
                this.data$.next(values)
                if (values.length == 1) {
                    this.form.patchValue({id: values[0].id})
                    this.onChange(this.form.value.id)
                }
            })
    }

    ngOnInit(): void {
    }

    private initForm() {
        this.form = this._formBuilder.group({
            id: undefined
        })
    }

    public writeValue(value: number) {
        this.form.patchValue({ id: value })
    }

    public registerOnChange(fn: (v: any) => void): void {
        this.onChange = fn
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn
    }

    public onSelect(value: any) {
        this.onTouched()
        this.onChange(value?.id)
    }

    private onChange = (_: any) => { }
    private onTouched = () => { }
}
