<form [formGroup]="form" class="m-form" novalidate>
    <ng-select class="select2" 
        [items]="data$ | async"
        bindLabel="name" 
        bindValue="id" 
        formControlName="id" 
        (change)="onSelect($event)"
        [groupBy]="groupByFn"
        [groupValue]="groupValueFn"
        [placeholder]="'classificacao.serie' | translate" [notFoundText]="'geral.not-found' | translate">
        <ng-template ng-optgroup-tmp let-item="item">
            <span style="vertical-align: middle;">{{ item.name }}</span>
        </ng-template>
    </ng-select>
</form>