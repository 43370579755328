import { Injectable } from '@angular/core';
import { hubConst } from '@app/core';
import { ApiService, Resp } from '@app/services';
import { Sumario } from '@shared/models/didactic-material';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SumarioService {
    private _baseUrl = hubConst.api.didaticos

    constructor(private apiService: ApiService) { }

    index(params: any): Observable<Resp<Sumario[]>> {
        return this.apiService.get<Sumario[]>(`${this._baseUrl}admin/v1/livros/sumario`, params)
    }

    store(data: Sumario): Observable<Resp<Sumario>> {
        return this.apiService.post(`${this._baseUrl}admin/v1/livros/sumario`, data)
    }

    update(data: Sumario): Observable<any> {
        return this.apiService.put(`${this._baseUrl}admin/v1/livros/sumario/${data.id}`, data)
    }

    delete(sumarioId: number): Observable<any> {
        return this.apiService.delete(`${this._baseUrl}admin/v1/livros/sumario/${sumarioId}`)
    }

    addCategory(summaryId: number, categoryId: number): Observable<Resp<any>> {
        return this.apiService.post(`${this._baseUrl}admin/v1/livros/summaries/${summaryId}/add-category/${categoryId}`)
    }

    removeCategory(summaryId: number, categoryId: number): Observable<Resp<any>> {
        return this.apiService.delete(`${this._baseUrl}admin/v1/livros/summaries/${summaryId}/remove-category/${categoryId}`)
    }

    indexByStep(bookId: number, summaryId?: number): Observable<Resp<Sumario[]>> {
        return this.apiService.get<Sumario[]>(`${this._baseUrl}admin/v1/livros/summaries/step`, { book_id: bookId, summary_id: summaryId })
    }

    getTree(summaryId: number): Observable<Resp<any>> {
        return this.apiService.get<any>(`${this._baseUrl}v1/summaries/${summaryId}/tree`)
    }

    getFullTreeWithLevel(bookId: number): Observable<Resp<any[]>> {
        return this.apiService.get<any[]>(`${this._baseUrl}admin/v1/livros/${bookId}/summaries/full-tree`)
    }

    move(summaryId: number, toSummaryId: number): Observable<Resp<any>> {
        return this.apiService.put<any>(`${this._baseUrl}admin/v1/livros/summaries/${summaryId}/move`, { to_summary_id: toSummaryId })
    }

    setOrder(summaryId: number, order: number): Observable<Resp<any>> {
        return this.apiService.put<any>(`${this._baseUrl}admin/v1/livros/summaries/${summaryId}/order`, { order })
    }
}
