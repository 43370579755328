<div class="row">
    <div class="col">
        <div class="d-flex justify-content-between align-items-center flex-wrap">
            <div class="d-flex flex-wrap py-2 mr-3">
              <!-- Voltar ao início-->
              <a *ngIf="currentPage == 1" title="{{txt1}}" class="btn btn-icon btn-sm btn-light disabled mr-2 my-1" disabled>
                <i class="ki ki-bold-double-arrow-back icon-xs"></i>
              </a>
              <a *ngIf="currentPage != 1" title="{{txt1}}" (click)="goFirst()" class="btn btn-icon btn-sm btn-light mr-2 my-1">
                <i class="ki ki-bold-double-arrow-back icon-xs"></i>
              </a>

              <!-- Voltar uma -->
              <a *ngIf="currentPage == 1" title="{{txt2}}" class="btn btn-icon btn-sm btn-light disabled mr-2 my-1" disabled>
                  <i class="ki ki-bold-arrow-back icon-xs"></i>
              </a>
              <a *ngIf="currentPage != 1" title="{{txt2}}" (click)="prevPage()" class="btn btn-icon btn-sm btn-light mr-2 my-1">
                  <i class="ki ki-bold-arrow-back icon-xs"></i>
              </a>

              <!-- <a *ngIf="retAnterior" title="{{txt8}}" class="btn btn-icon btn-sm border-0 btn-light disabled mr-2 my-1" disabled>...</a> -->
              <div *ngFor="let page of pagesShow">
                  <a title="{{ page }}" *ngIf="page != '...'" (click)="goto(page)" class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1" [ngClass]="{'active' : page == currentPage}">{{ page }}</a>
                  <a *ngIf="page == '...'">{{ page }}</a>
              </div>
              <!-- <a *ngIf="retProximo" title="{{txt8}}" class="btn btn-icon btn-sm border-0 btn-light disabled mr-2 my-1" disabled>...</a> -->

              <!-- Próxima -->
              <a *ngIf="currentPage == pages.length" title="{{txt3}}" class="btn btn-icon btn-sm btn-light disabled mr-2 my-1" disabled>
                  <i class="ki ki-bold-arrow-next icon-xs"></i>
              </a>
              <a *ngIf="currentPage != pages.length" title="{{txt3}}" (click)="nextPage()" class="btn btn-icon btn-sm btn-light mr-2 my-1">
                  <i class="ki ki-bold-arrow-next icon-xs"></i>
              </a>

              <!-- Última -->
              <a *ngIf="currentPage == pages.length" title="{{txt4}}" class="btn btn-icon btn-sm btn-light disabled mr-2 my-1" disabled>
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
              </a>
              <a *ngIf="currentPage != pages.length" title="{{txt4}}" (click)="goLast()" class="btn btn-icon btn-sm btn-light mr-2 my-1">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
              </a>
          </div>
      </div>
    </div>
  </div>
