export enum entityType
{
    entidade = 1,
    campo = 2,
    uniao = 3
}

export enum entityBuild
{
    associacaoGeral = 1,
    divisao = 2,
    uniao = 3,
    associacao = 4,
    missao = 5,
    escola = 6,
    internato = 7,
    instituicao = 8,
    colegio = 9,
    sedeBilingue = 10,
    cpbEditora = 11
}
