import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { hubConst } from '@app/core';
import { EntityType, Identificador } from '@app/models';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { CrudService } from '../crud.service';

@Injectable({
    providedIn: 'root',
})
export class IdentificadorService extends CrudService<Identificador> {

    constructor(
        protected readonly api: ApiService,
        private http: HttpClient
        ) {
        super(api, `${hubConst.api.avaliacoes_api}identificador`);
    }

    public indexPreview(tipo: number, entidade: EntityType): Observable<any> {
        return this.api.get(`${hubConst.api.avaliacoes_api}identificador-preview`, {tipo, entidade}).pipe(take(1))
    }
}
