import { saveAs } from 'file-saver'

export function downloadFile(url: string, fileName: string, extension: string) {
    const ext = ['pdf', 'jpg', 'jpeg', 'xls', 'xlsx', 'ppt', 'pptx', 'doc', 'docx', 'mp4', 'png']

    if (ext.includes(extension)) {
        saveAs(url, fileName)
    } else {
        window.open(url)
    }
}
