import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthAdvisorGuard } from './guards/auth-advisor.guard';
import { AuthAtendimentoPortalGuard } from './guards/auth-atendimento-portal.guard';
import { AuthCoordenadorAvaliacaoGuard } from './guards/auth-coordenador-avaliacao.guard';
import { AuthCoordenadorConteudoOrRepresentanteCPBGuard } from './guards/auth-coordenador-conteudo-or-representante-cpb.guard';
import { AuthPermissaoGuard } from './guards/auth-permissao.guard';
import { AuthTecnicoAdmResponsavelTecnicoGuard } from './guards/auth-tecnico-adm-responsavel-tecnico.guard';
import { AuthTecnicoAdmGuard } from './guards/auth-tecnico-adm.guard';
import { AuthGuard } from './guards/auth.guard';

export const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthPermissaoGuard],
        children: [
            {
                path: 'entidades',
                loadChildren: () => import('./entidades/entidades.module').then(m => m.EntidadesModule),
                canLoad: [AuthTecnicoAdmGuard],
            },
            {
                path: 'usuarios',
                loadChildren: () => import('./usuarios/usuarios.module').then(m => m.UsuariosModule),
                canLoad: [AuthTecnicoAdmResponsavelTecnicoGuard]
            },
            {
                path: 'materiais-didaticos',
                loadChildren: () => import('./materiais-didaticos/materiais-didaticos.module').then(m => m.MateriaisDidaticosModule),
                canLoad: [AuthCoordenadorConteudoOrRepresentanteCPBGuard]
            },
            {
                path: 'gestao',
                loadChildren: () => import('./gestao/gestao.module').then(m => m.GestaoModule),
                canLoad: [AuthTecnicoAdmResponsavelTecnicoGuard]
            },
            {
                path: 'gestor-avaliacoes',
                loadChildren: () => import('./modules/gestor-avaliacoes/gestor-avaliacoes.module').then(m => m.GestorAvaliacoesModule),
                canLoad: [AuthCoordenadorAvaliacaoGuard]
            },
            {
                path: 'gestao-tecnica',
                loadChildren: () => import('./gestao-tecnica/gestao-tecnica.module').then(m => m.GestaoTecnicaModule),
                canLoad: [AuthAtendimentoPortalGuard]
            },
            {
                path: 'permissionamento',
                loadChildren: () => import('./permissionamento/permissionamento.module').then(m => m.PermissionamentoModule),
                canLoad: [AuthAtendimentoPortalGuard]
            },
            {
                path: 'bilingue',
                loadChildren: () => import('./bilingue/bilingue.module').then(m => m.BilingueModule),
                canLoad: [AuthAdvisorGuard]
            },
            {
                path: '',
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
            },
        ]
    },
    {
        path: '**',
        redirectTo: '',
    }

]
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
