import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { LocalStorageService } from '@app/guards/storage.service'
import { TranslateService } from '@ngx-translate/core'
import { DropzoneConfigInterface, DropzoneDirective } from 'ngx-dropzone-wrapper'
import { Observable, of, Subject } from 'rxjs'
import { delay, share, switchMap } from 'rxjs/operators'
import { File } from '../../models/file.model'
import { Swal } from '../../utils'

@Component({
    selector: 'gpe-upload-file-v2',
    templateUrl: './upload-file-v2.component.html',
    styleUrls: ['./upload-file-v2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadFileComponentV2 implements OnInit, OnDestroy {
    private readonly _storage = this.storageService.storageUsuario
    public disabled: boolean = false
    public files: File[] = []
    @Input() public titulo: string = ''
    @Input() public height: number = 100

    @ViewChild(DropzoneDirective) componentRef: DropzoneDirective

    @Input() config: DropzoneConfigInterface = {
        clickable: false,
        maxFiles: 1,
        autoReset: null,
        errorReset: null,
        cancelReset: null,
    }
    @Input() deleteFileEvent: any = null
    @Input() link = ''
    @Input() class = ''
    @Input() isInvalid: boolean = false
    @Input() componentReset: EventEmitter<any> = new EventEmitter()
    @Input() onChangeConfig: EventEmitter<any> = new EventEmitter()
    @Output() onStatusChange = new EventEmitter()

    public classIcon: string = 'fa-user'
    public loading$: Observable<boolean>
    private _loading$ = new Subject<boolean>()
    private _traducao: any
    private _countFiles: number = 0

    constructor(
        private swal: Swal,
        private translate: TranslateService,
        private storageService: LocalStorageService
    ) {

        this.loading$ = this._loading$.asObservable().pipe(
            switchMap((v) => (v ? of(true).pipe(delay(500)) : of(false))),
            share()
        )
    }

    public ngOnInit(): void {
        this.initLanguage()
        this.config.headers = {
            Accept: 'application/json',
            Authorization: `Bearer ${this._storage.token}`
        }

        if (this.class != 'boxFotoUsuario') {
            this.classIcon = 'fa-image'
        }

        this.onChangeConfig.subscribe((data) => {
            this.config = data
        })

        this.componentReset.subscribe(this.resetDropzone)
    }

    private initLanguage() {
        const lingua = this._storage.country_selected.language ? this._storage.country_selected.language : 'pt-br'
        this.translate.setDefaultLang(lingua)
        this.translate
            .get([
                'geral.atencao',
                'dropzone.arquivo-grande'
            ])
            .subscribe((text: any) => this._traducao = text)
    }

    public ngOnDestroy() {
        this._loading$.complete()
    }

    public onUploadError(args: any): void {
        this.swal.msgAlert(this._traducao['geral.atencao'], args[1].msg || this._traducao['dropzone.arquivo-grande'], 'warning', 'Ok')
        this.componentRef.reset()
        this._loading$.next(false)
    }

    public onUploadSuccess(args: any): void {
        let filesNumber = this.componentRef.dropzone().files.length
        if (args[1].ret) {
            let file = this.formatData(args)

            this.files.push(file)

            this.onStatusChange.emit(Object.assign({ success: true }, file))

            if (filesNumber > 1) {
                this._countFiles++

                if (this._countFiles == filesNumber) {
                    this.componentRef.reset()
                }
            } else {
                this.componentRef.reset()
            }
        }
        this._loading$.next(false)
    }

    public onProgress() {
        this._loading$.next(true)
    }

    public resetDropzone = () => {
        this.componentRef.reset()
        this.files = []
    }

    private formatData(args: any) {
        let data = <any>{}

        data.mimetype = !!args[1].mimetype ? args[1].mimetype : null
        data.hash = !!args[1].hash ? args[1].hash : null

        data.file = !!args[1].file ? args[1].file : null
        if (!!args[1].fileName) {
            data.file = args[1].fileName
        }

        data.title = !!args[1].title ? args[1].title : null
        if (!!args[1].originalName) {
            data.title = args[1].originalName
        }

        data.extension = !!args[1].extension ? args[1].extension : null
        if (!!args[1].extensao) {
            data.extension = args[1].extensao
        }

        data.size = !!args[1].size ? args[1].size : 0
        if (!!args[1].tamanho) {
            data.size = args[1].tamanho
        }

        return data
    }
}
