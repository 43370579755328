import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'

@Component({
    selector: 'gpe-paginacao',
    templateUrl: './paginacao.component.html'
})
export class PaginacaoComponent implements OnInit, OnChanges {
    pages: number[] = []
    pagesShow: any[] = []

    @Input() total: number
    @Input() pageSize: number
    @Input() currentPage: number
    @Input() delta: number = 2
    @Input() language: string = "pt-BR"

    txt1: string = "Primeira página"
    txt2: string = "Anterior"
    txt3: string = "Próxima"
    txt4: string = "Última"
    txt5: string = "a"
    txt6: string = "de"
    txt7: string = "itens"

    @Output() pageChanged = new EventEmitter<number>()

    constructor() { }

    ngOnInit(): void {
        if (this.language == "en") {
            this.txt1 = "First page"
            this.txt2 = "Previous"
            this.txt3 = "Next"
            this.txt4 = "Last page"
            this.txt5 = "to"
            this.txt6 = "of"
            this.txt7 = "items"
        } else if (this.language == "es") {
            this.txt1 = "Primera pagina"
            this.txt2 = "Anterior"
            this.txt3 = "Próxima"
            this.txt4 = "Última"
            this.txt5 = "los"
            this.txt6 = "en"
            this.txt7 = "artículos"
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.total || isNaN(this.total)) return
        if (!this.pageSize || isNaN(this.pageSize)) return

        this.pages = Array(Math.ceil(this.total / this.pageSize))
            .fill(0)
            .map((x, i) => i + 1)
        this.generatePages()

        // if (changes.total && changes.total.previousValue != changes.total.currentValue) {
        //   this.currentPage = 0
        //   this.emitPage()
        // }
    }

    // dispara a página atual
    private emitPage() {
        this.pageChanged.emit(this.currentPage)
        this.generatePages()
    }

    goto(index: number) {
        if (this.currentPage !== index) {
            this.currentPage = index
            this.emitPage()
        }
    }

    nextPage() {
        if (this.currentPage < this.pages.length) {
            this.currentPage++
            this.emitPage()
        }
    }

    prevPage() {
        if (this.currentPage > 1) {
            this.currentPage--
            this.emitPage()
        }
    }

    goFirst() {
        if (this.currentPage !== 1) {
            this.currentPage = 1
            this.emitPage()
        }
    }

    goLast() {
        if (this.pages.length !== this.currentPage) {
            this.currentPage = this.pages.length
            this.emitPage()
        }
    }

    generatePages() {
        const c = Number(this.currentPage),
            m = Number(this.pages[this.pages.length - 1])

        var current = c,
            last = m,
            delta = this.delta,
            left = current - delta,
            right = current + delta + 1,
            range = [],
            rangeWithDots = [],
            l

        for (let i = 1; i <= last; i++) {
            if (i == 1 || i == last || (i >= left && i < right)) {
                range.push(i)
            }
        }

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1)
                } else if (i - l !== 1) {
                    rangeWithDots.push('...')
                }
            }
            rangeWithDots.push(i)
            l = i
        }

        this.pagesShow = rangeWithDots
    }
}
