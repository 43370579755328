import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';

export interface HeaderData {
	texto: string
	url?: string
	queryParams?: string
}
@Component({
  selector: 'gpe-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    public header$: Observable<HeaderData[]>

    @Input('itens')
	public set itens(value: HeaderData[] | Observable<HeaderData[]>) {
		if (!value) {
			this.header$ = of([])
		} else if (value instanceof Observable) {
			this.header$ = value
		} else {
			this.header$ = of(value)
		}
	}
}
