import { Component, Input } from '@angular/core';

export interface LoadingBlockData {
    display: boolean,
    text?: string
}

@Component({
    selector: 'app-loading-block',
    templateUrl: './loading-block.component.html',
})
export class LoadingBlockComponent {
    public display: boolean
    public text: string

    @Input() public set data(value: LoadingBlockData) {
        if (value) {
            this.text = value.text
            const time = value.display ? 0 : 500
            setTimeout(() => { this.display = value.display }, time)   
        }
    }
}
