import { Component, OnDestroy } from '@angular/core';
import { LoadingParams, LoadingService } from '@services/loading.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

interface LoadingData {
    params: LoadingParams;
    visible: boolean;
}

@Component({
    selector: 'gpe-loading',
    templateUrl: './loading.component.html',
})
export class LoadingComponent implements OnDestroy {
    public data$: Observable<LoadingData>;
    private destroy$ = new Subject();

    constructor(private _loadingService: LoadingService) {
        const visible$ = this._loadingService.visible$;
        const params$ = this._loadingService.params$;
        this.data$ = combineLatest([visible$, params$]).pipe(
            takeUntil(this.destroy$),
            map(([visible, params]) => ({ visible, params }))
        );
    }

    public ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
