import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit } from '@angular/core'
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms'
import { hubConst } from '@app/core'
import { LocalStorageService } from '@app/guards/storage.service'
import { downloadFile, Swal } from '@app/utils'
import { TranslateService } from '@ngx-translate/core'
import { CountryEnum } from 'enums/country.enum'
import { VimeoAccount } from 'enums/vimeo-account.enum'
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
    selector: 'gpe-upload-element-video-md',
    templateUrl: './upload-element-video.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => UploadElementVideoComponent),
        },
    ],
})
export class UploadElementVideoComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
    @Input() readonly id: number
    @Input() public isInvalid: boolean = false
    @Input() public anexoService: any
    @Input() public vimeoOrigemVideo: string
    @Input() public height: number

    private _api = hubConst.api
    private _vimeoConta: string
    private _destroy$ = new Subject<void>()
    private _traducao: any
    private _storage = this._localStorageService.storageUsuario
    public state$: Observable<boolean>
    public linkThumbnail: string
    public form: FormGroup
    public dzComponentReset: EventEmitter<any> = new EventEmitter()
    public dzChangeConfig: EventEmitter<any> = new EventEmitter()
    public dzConfig: DropzoneConfigInterface = {

        clickable: true,

        url: `${this._api.vimeo_api}upload-video`,
        params: {
            conta: undefined,
            origem: 'trilhas-de-aprendizagem',
            verificaTamanho: true,
            pesoMaximo: 5000,
            // pesoMaximo: 300,
        },
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${this._storage.token}`,
        },
        createImageThumbnails: false,
        maxFiles: 1,
        maxFilesize: 5000, //Define o peso maximo do arquivo, pasar o valor em mb
        // maxFilesize: 300,
        acceptedFiles: '.mp4,.mov,.m4v',
        timeout: 270000000,
    }

    constructor(
        private _formBuilder: FormBuilder,
        private _localStorageService: LocalStorageService,
        private _swal: Swal,
        private _cdref: ChangeDetectorRef,
        private _translate: TranslateService
    ) {
        this.initForm()
    }

    public ngOnInit(): void {
        this.initLanguage()
    }

    ngOnDestroy() {
        this._destroy$.next()
        this._destroy$.complete()
    }

    public ngAfterViewInit() {
        this.initDzConfig()
        if (!!this.form.controls.video.value && !this.form.controls.thumbnail.value) {
            this.getInfoVimeo(false)
        }
    }

    private initLanguage() {
        this._translate
            .get([
                'geral.ok',
                'geral.titulo',
                'geral.sim',
                'geral.nao',
                'dropzone.video-renderizando',
                'dropzone.alerta-delete'
            ])
            .subscribe((text: any) => this._traducao = text)
    }

    private initDzConfig() {
        this._vimeoConta = this._storage.country_selected.id
            ? this._storage.country_selected.id == CountryEnum.Brasil
                ? VimeoAccount.ContaNovaBrasil
                : VimeoAccount.ContaHispanos
            : VimeoAccount.ContaNovaBrasil
        this.dzConfig = {
            clickable: true,

            url: `${this._api.vimeo_api}upload-video`,
            params: {
                conta: this._vimeoConta,
                origem: this.vimeoOrigemVideo,
                verificaTamanho: true,
                pesoMaximo: 5000,
                // pesoMaximo: 300,
            },
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this._storage.token}`,
            },
            createImageThumbnails: false,
            maxFiles: 1,
            maxFilesize: 5000, //Define o peso maximo do arquivo, pasar o valor em mb
            // maxFilesize: 300,
            acceptedFiles: '.mp4,.mov,.m4v',
            timeout: 270000000,
        }
        this.dzChangeConfig.emit(this.dzConfig)
    }

    private initForm() {
        this.form = this._formBuilder.group({
            id: undefined,
            hash: undefined,
            nome_arquivo: [undefined, [Validators.required]],
            file: undefined,
            video: [undefined, [Validators.required]],
            vimeo_conta: [undefined, [Validators.required]],
            vimeo_hash: undefined,
            thumbnail: undefined,
            extensao: [undefined, [Validators.required]],
            mimetype: undefined,
            size: 0,
        })
    }

    public onUpload(event) {
        if (event.success) {
            this.form.patchValue({
                id: undefined,
                hash: 'hash',
                nome_arquivo: event.title,
                video: event.file,
                vimeo_conta: this._vimeoConta,
                vimeo_hash: undefined,
                thumbnail: undefined,
                extensao: event.extension,
                size: event.size,
                mimetype: event.mimetype,
                file: undefined,
            })
            this.onSelect(this.form.value)
            this.resetDz()
        }
    }

    public onDownload() {
        let url = this.form.controls.hash.value ? hubConst.s3_path_temp + this.form.controls.file.value : this.form.controls.file.value
        downloadFile(url, this.form.controls.nome_arquivo.value, this.form.controls.extensao.value)
    }

    public onDelete() {
        this.swalAlert(this._traducao['geral.titulo'], this._traducao['dropzone.alerta-delete'], 'warning', true).then((result) => {
            if (result.value) {
                this.reset()
                this.initDzConfig()
                this._cdref.markForCheck()
            }
        })
    }

    private swalAlert(title: string, text: string, type, showCancelButton = false) {
        if (!showCancelButton) {
            this._swal.autoButton(title, text, type)
        } else {
            return this._swal.confirmAlertQuestionCustom(title, text, type, this._traducao['geral.sim'], this._traducao['geral.nao'])
        }
    }

    public onShowVideo() {
        if (!this.form.value.vimeo_hash) {
            this.getInfoVimeo(true)
        } else {
            this.showVideo()
        }
    }

    private showVideo() {
        let url = this.form.controls.video.value.replace('/videos/', hubConst.vimeo_url)
        if (!url.includes('?h=')) {
            url += '?h=' + this.form.controls.vimeo_hash.value
        }
        this._swal.iframeViewer(this.form.controls.nome_arquivo.value, url)
    }

    private getInfoVimeo(open: boolean = true) {
        this.anexoService
            .checkVideo(this.form.controls.id.value)
            .pipe(takeUntil(this._destroy$))
            .subscribe((video: any) => {

                if (video.ret == 1) {
                    this.form.get('thumbnail').setValue(video.thumbnail)
                    this.form.get('vimeo_hash').setValue(video.vimeo_hash)
                    this.onSelect(this.form.value)
                    if (open) {
                        this.showVideo()
                    }
                } else {
                    if (open) {
                        this._swal.msgAlert(
                            this._traducao['geral.titulo'],
                            this._traducao['dropzone.video-renderizando'],
                            'warning',
                            this._traducao['geral.ok']
                        )
                    }
                }
            })
    }

    private resetDz() {
        this.dzComponentReset.emit(true)
    }

    private reset() {
        this.form.reset()
        this.onSelect()
    }

    public registerOnChange(fn: (v: any) => void): void {
        this.onChange = fn
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn
    }

    public writeValue(data) {
        if (!!data) {
            this.form.patchValue(data)
        } else {
            this.reset()
        }
    }

    public onSelect(object: any = undefined) {
        this.onTouched()
        if (!!object) {
            this.onChange(object)
        } else {
            this.onChange(undefined)
        }
    }

    private onChange = (_: any) => { }

    private onTouched = () => { }
}
