import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Resp } from '@services/api.service';
import { Category } from '@shared/models';
import { Sumario } from '@shared/models/didactic-material';
import { Matrixtype, SumarioService } from '@shared/service';
import { merge } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, finalize, map } from 'rxjs/operators';
import { CategoryService } from '../modal-select-categories/category.service';

interface TreeItem {
    name: string
    description?: string
}

interface Tree {
    color_class: string
    tag: string,
    items: TreeItem[]
}

@Component({
    selector: 'gpe-chip-item-category',
    templateUrl: './chip-item-category.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipItemCategoryComponent {
    @Input() public item: Category | Sumario
    @Input() public canRemove: boolean = true
    @Output() public removeEvent = new EventEmitter()

    public loading$ = new BehaviorSubject<boolean>(false)
    public tree = {} as Tree

    public readonly QUERY = {
        [Matrixtype.Category]: (categoryId: number): Observable<Resp<any>> => this._categoryService.getTree(categoryId),
        [Matrixtype.DidacticMaterial]: (summaryId: number): Observable<Resp<any>> => this._summaryService.getTree(summaryId),
    }

    constructor(
        private _categoryService: CategoryService,
        private _summaryService: SumarioService
    ) { }

    public get isCategory(): boolean {
        return 'matrix_id' in this.item
    }

    public get isSummary(): boolean {
        return 'livro_id' in this.item
    }

    public get categoryDescription(): string {
        const description = (<Category>this.item).description
        return description?.length > 45 ? description.substring(0, 40).trim() + '...' : description
    }

    public onRemove() {
        this.removeEvent.emit(this.item)
    }

    public onOpenPopover(popover: NgbPopover) {
        this.tree.color_class = this.isCategory ? (<Category>this.item).matrix.color_class : 'dark'
        popover.open()
        if (!this.tree.items) {
            this.loading$.next(true)
            const matrixType = (<Category>this.item).matrix?.type == Matrixtype.Category ? Matrixtype.Category : Matrixtype.DidacticMaterial
            this.QUERY[matrixType](this.item.id).pipe(
                finalize(() => setTimeout(() => this.loading$.next(false), 700)),
                map(res => res?.ret == 1 ? res.data : undefined),
                filter(data => !!data),
            ).subscribe(data => this.tree = merge(this.tree, data))
        }
    }

    public onGetColorClass(last: boolean = true) {
        return last ? `label-light-${this.tree.color_class}` : `label-outline-${this.tree.color_class}`
    }
}
