import { Injectable } from '@angular/core';
import { hubConst } from '@app/core';
import { ApiService } from '@services/api.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GradeService {
    private _baseUrl = hubConst.api.categories

    constructor(private apiService: ApiService) { }
    
    index(publishingId: number, stageId?: number): Observable<any> {
        return this.apiService.get(`${this._baseUrl}v1/grade`, {publishing_id: publishingId, stage_id: stageId})
    }
}
