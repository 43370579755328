import { Injectable } from '@angular/core';
import { LocalStorageService } from '@app/guards/storage.service';
import { PaisStorage } from '@app/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PaisActionsService {
    private _changed$ = new Subject<boolean>()
    private _pais$ = new BehaviorSubject<PaisStorage>(this.countrySelected)
    private _canChangeCountry$ = new BehaviorSubject<boolean>(true)

    constructor(private localStorageService: LocalStorageService) { }

    private get countrySelected(): PaisStorage {
        return this.localStorageService.storageUsuario?.country_selected
    }

    public get pais$(): Observable<PaisStorage> {
        return this._pais$.asObservable()
    }

    public get canChangeCountry$(): Observable<boolean> {
        return this._canChangeCountry$.asObservable()
    }

    public get changed$(): Observable<boolean> {
        return this._changed$.asObservable()
    }

    public setPais(pais: PaisStorage): void {
        this._pais$.next(pais)
        this._changed$.next(true)
    }

    public suppressConfirmDialog(canChange: boolean): void {
        this._canChangeCountry$.next(canChange)
    }
}
