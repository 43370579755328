export enum VimeoAccount {
    Conta = 'ecplay_01',
    // ContaNovaBrasil = 'bra_21_1', // conta brasil ficou cheia, salvar na conta dos hispanos
    ContaNovaBrasil = 'his_21_1',
    ContaHispanos = 'his_21_1'
}
export enum VimeoOrigemVideoEnum {
    materialDidaticos = 'materiais-didaticos',
    gestorAvaliacao = 'gestor-avaliacao',
    trilhasAprendizagem = 'trilhas-de-aprendizagem'
}
