import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-select-tipo-livro',
    templateUrl: './select-tipo-livro.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SelectTipoLivroComponent),
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectTipoLivroComponent implements ControlValueAccessor {
    @Input() class: string = ""
    @Input() label: boolean = true
    public tiposLivro = []
    public tipoLivro: number = 0

    constructor(private _translate: TranslateService) {
        this.initLanguage()
    }

    public onSelect(data) {
        this.onTouched()
        this.onChange(data?.id)
    }

    public writeValue(value: number) {
        this.tipoLivro = value
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn
    }

    private initLanguage(): void {
        this._translate
            .get([
                'modulos.materiais-didaticos.livros-didatico',
                'modulos.materiais-didaticos.paradidatico',
                'modulos.materiais-didaticos.projeto-especial'
            ])
            .subscribe((text: any) => {
                this.tiposLivro = [
                    { id: 0, nome: text['modulos.materiais-didaticos.livros-didatico'] },
                    { id: 1, nome: text['modulos.materiais-didaticos.paradidatico'] },
                    { id: 2, nome: text['modulos.materiais-didaticos.projeto-especial'] }
                ]
            })
    }

    private onChange = (_: any) => { }

    private onTouched = () => { }
}
