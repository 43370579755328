import { Injectable } from '@angular/core';
import { hubConst } from '@app/core';
import { ApiService } from '@services/api.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StageService {
    private _baseUrl = hubConst.api.categories

    constructor(private apiService: ApiService) { }

    index(publishing_id: number): Observable<any> {
        return this.apiService.get(`${this._baseUrl}v1/stage`, {publishing_id})
    }
}
