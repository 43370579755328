import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocalStorageService } from '@app/guards/storage.service';
import { Livro } from '@shared/models/didactic-material';
import { LivroService } from '@shared/service';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'gpe-card-items-book',
    templateUrl: './card-items-book.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => CardItemsBookComponent),
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardItemsBookComponent implements OnDestroy {
    public data$ = new BehaviorSubject<any>([])
    private _stageId$ = new BehaviorSubject<number>(undefined)
    private _gradeId$ = new BehaviorSubject<number>(undefined)
    private _componentId$ = new BehaviorSubject<number>(undefined)
    private _destroy$ = new Subject()

    constructor(
        private _bookService: LivroService,
        private _localStorageService: LocalStorageService
    ) {
        this.loadData()
    }

    @Input('stageId') public set setStageId(value: number) {
        this._stageId$.next(value)
    }

    @Input('gradeId') public set setGradeId(value: number) {
        this._gradeId$.next(value)
    }

    @Input('componentId') public set setComponentId(value: number) {
        this._componentId$.next(value)
    }

    ngOnDestroy(): void {
        this._destroy$.next()
        this._destroy$.complete()
        this._stageId$.complete()
        this._gradeId$.complete()
        this._componentId$.complete()
    }

    private loadData() {
        const publishingId = this._localStorageService.storageUsuario?.country_selected.editoras[0].id
        combineLatest([this._stageId$, this._gradeId$, this._componentId$]).pipe(
            takeUntil(this._destroy$),
            map(([stageId, gradeId, componentId]) => ({stage_id: stageId, grade_id: gradeId, component_id: componentId})),
            switchMap(params => this._bookService.index({...params, editora_id: publishingId, page_size: 1000})),
            map(res => res.ret === 1 ? res.data['data'] : []),
            tap(value => !value.length && this.onSelect())
        ).subscribe(value => {
            this.data$.next(value)
            if (value.length == 1) {
                this.onSelect(value[0])
            }
        })
    }

    public writeValue(value: number) {}

    public registerOnChange(fn: (v: any) => void): void {
        this.onChange = fn
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn
    }

    public onSelect(book?: Livro) {
        this.onTouched()
        this.onChange(book)
    }

    private onChange = (_: any) => { }
    private onTouched = () => { }
}
