import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    Output,
    ViewChild
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { trim } from 'lodash'
import { BehaviorSubject } from 'rxjs'
import { filter, tap } from 'rxjs/operators'

enum SubmitMode {
	Enter,
	Blur,
	Escape,
}
@Component({
	selector: 'app-edit-inline',
	templateUrl: './edit-inline.component.html',
	styleUrls: ['./edit-inline.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => EditInlineComponent),
		},
	],
})
export class EditInlineComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
	@Input() public disabled = false
	@Input() public maxLength: number
	@Input() public placeholder: string
	@Input() public placeholderHTML: string = undefined
	@Output() public changed = new EventEmitter<any>()
	@ViewChild('inputEditable', {static: true}) private inputEditable: ElementRef
	private _historicalValue: any
	private submit$ = new BehaviorSubject<SubmitMode>(SubmitMode.Escape)

	public clicked: boolean = false

	@Input()
	public set value(value: any) {
		this._historicalValue = value
		this.element.textContent = value
	}

	public get getPlaceholder(): string {
		let str = this.placeholder
		if (this.placeholderHTML) {
			str = this.clicked ? this.placeholder : this.placeholderHTML
		}
		return str
	}

	public ngAfterViewInit() {
		this.loadListeners()
	}

	public ngOnDestroy() {
		this.submit$.complete()
	}

	public writeValue(value: any) {
		this.value = value
	}

	public registerOnChange(fn: any) {
		this.change = fn
	}

	public registerOnTouched(fn: any) {
		this.touched = fn
	}

	public setDisabledState?(isDisabled: boolean) {
		this.disabled = isDisabled
	}

	public onTouched() {
		this.clicked = true
		if (this.touched) {
			this.touched()
		}
	}

	public onKeyPress(event: KeyboardEvent) {
		if (event.key === 'Enter') {
			this.submit$.next(SubmitMode.Enter)
			event.preventDefault()
		}
		if (this.maxLength && this.maxLength < this.element.innerHTML.length) {
			event.preventDefault()
		}
	}

	public onKeyUp(event: KeyboardEvent) {
		if (event.key === 'Escape') {
			this.element.textContent = this._historicalValue
			this.submit$.next(SubmitMode.Escape)
			event.preventDefault()
			return
		}
	}

	public onSubmit() {
		this.clicked = false
		this.submit$.next(SubmitMode.Blur)
	}

	private loadListeners() {
		this.submit$
			.pipe(
				tap(() => this.element.blur()),
				filter(this.distinctValue),
				filter((submitMode) => SubmitMode.Escape !== submitMode)
			)
			.subscribe(this.submit)
	}

	private distinctValue = (): boolean => {
		const safeElementValue = !!this.element.innerHTML && this.element.innerHTML
		const safeHistoricalValue = !!this._historicalValue && this._historicalValue
		return safeElementValue !== safeHistoricalValue
	}

	private submit = () => {
		const text = this.element.innerHTML
		this.value = trim(text)
		this.change(text)
		this.changed.emit(text)
	}

	private get element() {
		return this.inputEditable.nativeElement
	}

	private change = (_: any) => {}
	private touched = () => {}
}
