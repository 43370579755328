
<div class="row">
    <ng-container *ngIf="data$ | async as data">
        <div *ngFor="let book of data" (click)="onSelect(book)" class="col-4 cursor-pointer">
            <div class="align-items-center border d-flex mt-5 p-3">
                <img *ngIf="book.anexos[0]?.nome_capa" [src]="book.anexos[0].nome_capa" [alt]="book.titulo" class="mr-3 w-80px" style="border-radius: 6px;">
    
                <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                    <span class="font-size-lg mb-0 text-dark">
                        {{ book.titulo }}
                    </span>
                    <span *ngIf="!!book.contextos.length" class="text-muted font-size-sm">
                        <span *ngIf="!!book.contextos[0].nivel">{{ book.contextos[0].nivel.descricao }}</span>
                        <span *ngIf="!!book.contextos[0].padrao_serie"> - {{ book.contextos[0].padrao_serie.descricao }}</span>
                        <span *ngIf="!!book.contextos[0].padrao_disciplina"> - {{ book.contextos[0].padrao_disciplina.descricao }}</span>
                    </span>
                    <span *ngIf="!!book.fasciculo" class="text-muted font-size-sm">
                        <span *ngIf="!!book.contextos.length">-</span>
                        {{ book.fasciculo.descricao }}
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="!data.length" class="col-12">
            <div class="jumbotron mt-10 text-center p-15" translate>
                classificacao.nao-foi-encontrado-material-didatico
            </div>
        </div>
    </ng-container>
</div>