import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestorAvaliacaoModule } from './gestor-avaliacao/gestor-avaliacao.module';
import { LabelService } from './label.service';



@NgModule({
  imports: [CommonModule, GestorAvaliacaoModule],
  providers: [LabelService]
})
export class ServicoModule { }
