import { NgModule } from '@angular/core';
import { ArrowsVComponent } from './arrows-v/arrows-v.component';
import { BinocularComponent } from './binocular/binocular.component';
import { CheckComponent } from './check/check.component';
import { ClassificationsComponent } from './classifications/classifications.component';
import { CloseComponent } from './close/close.component';
import { DuplicateComponent } from './duplicate/duplicate/duplicate.component';
import { EditComponent } from './edit/edit.component';
import { RibbonRComponent } from './ribbon-r/ribbon-r.component';
import { TrashComponent } from './trash/trash.component';

@NgModule({
  declarations: [RibbonRComponent, CloseComponent, TrashComponent, BinocularComponent, ArrowsVComponent, CheckComponent, ClassificationsComponent, EditComponent, DuplicateComponent],
  exports: [RibbonRComponent, CloseComponent, TrashComponent, BinocularComponent, ArrowsVComponent, CheckComponent, ClassificationsComponent, EditComponent, DuplicateComponent],
})
export class SvgModule { }
