import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { HttpParamsUtils } from '@app/utils';
import { isNil } from 'lodash';

@Injectable({
	providedIn: 'root',
})
export class  RouterParamLoaderService {
	constructor(private router: Router) {}

	public updateReq<T>(req?: any, params?: NavigationExtras) {
		const queryParams = Object.entries(req)
			.map(([key, value]) => ({ [key]: HttpParamsUtils.serialize(value) }))
			.filter((value) => !isNil(value))
			.reduce((p, c) => ({ ...p, ...c }), {})
		const extras = { queryParams, replaceUrl: true, ...params }
		this.router.navigate([], extras)
	}
}
