import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@app/services/api.service';
import { hubConst } from '@app/core';

@Injectable({
    providedIn: 'root'
})
export class NivelEnsinoService {
    private _baseUrl = hubConst.api.usuario_api

    constructor(private apiService: ApiService) { }

    index(modelo_id:number): Observable<any> {
        return this.apiService.get(`${this._baseUrl}hub-educacional/niveis-modelo/${modelo_id}`, {entidade_id: null})
    }
}
